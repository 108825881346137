import React from 'react';
import { DropTarget } from 'react-dnd'
import DraggableSticker from './DraggableSticker';
import DraggableItemTypes from 'collections/DraggableItemTypes'

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  }
}

const PossibleAnswersTarget = {  
  canDrop(props, monitor) {
      const {question} = props;
      const item = monitor.getItem();
      return question.isChoiceSelected(item.answerIndex);
  },

  drop(props, monitor, component) {
    const {onDrop} = props;
    return {onDrop: onDrop};
  }
}

class PossibleAnswers extends React.Component {

  render () {
    const {question, connectDropTarget, isOver, canDrop} = this.props;

    const className = "possible-answers-container flexbox";
    const borderStyle = {borderColor: question.colour()};

    return connectDropTarget(
      <div className={className + ((isOver && canDrop) ? " is-dragging-over" : "")}
      data-collectionid={question.index()}
      style={borderStyle}>
        {this._renderItems(borderStyle)}
      </div>);
  }

  _isDragDisabled() {
    const {question} = this.props;
    return question.selectedChoices().length >= question.maxChoices();
  }

  _renderItems(borderStyle) {
    const {question, isTouchDevice} = this.props;
    var items = [];
    const allAnswers = question.allSelectableChoices();
    for (let i = 0; i < allAnswers.length; i++) {
      const answer = allAnswers[i];
      items.push(<DraggableSticker 
        key={i} 
        isDragDisabled={this._isDragDisabled()}
        questionIndex={question.index()}
        answerIndex={answer.index()}
        content={answer} 
        isEmpty={question.isChoiceSelected(answer.index())}
        useCustomPreview={isTouchDevice}
        clickHandler={this._handleContentClick.bind(this)}
        style = {borderStyle}
        background={true} 
        spinnerColour={question.colour()}
        />
        );
    }
    return items;
  }

  _handleContentClick(data) {
    const {onContentClick} = this.props;
    if(!this._isDragDisabled()){
      onContentClick(data);
    }
  }
}
  
export default DropTarget(DraggableItemTypes.Sticker, PossibleAnswersTarget, collect)(PossibleAnswers);
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './components/App'
import * as serviceWorker from './serviceWorker'
import 'react-calendar/dist/Calendar.css'
import './css/index.scss'
import './css/buttons.scss'
import './css/fixit.scss'
import './css/settings.scss'
import './css/payment-methods.scss'
import './css/nav-header.scss'
import './css/homepage.scss'
import './css/title.css'
import './css/signin.scss'
import './css/modal.css'
import './css/options-modal.css'
import './css/error-modal.css'
import './css/file-upload-modal.css'
import './css/profile.scss'
import './css/questions.scss'
import './css/summary.scss'
import './css/faq.css'
import { LocalisationServiceProvider } from 'providers/LocalisationProvider'
import TelemetryServiceWrapper from 'services/TelemetryServiceWrapper'
import FirebaseTelemetryService from 'services/Firebase/FirebaseTelemetryService'
import FirebaseAuthenticationService from 'services/Firebase/FirebaseAuthenticationService'
import AuthenticationServiceWrapper from 'services/AuthenticationServiceWrapper'
import firebase from 'firebase/app'
import { FunctionRegion } from 'fixit-shared/Consts'

const LOCAL_ENV = 'LOCAL'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const functions = firebase.app().functions(FunctionRegion)
const db = firebase.firestore();

if (process.env.REACT_APP_ENVIRONMENT === LOCAL_ENV) {
  console.log('Setting up emulators')
  auth.useEmulator("http://localhost:9099")
  functions.useEmulator("localhost", 5001)
  db.useEmulator("localhost", 8080)
}

const userProperties = {
  environment: process.env.REACT_APP_ENVIRONMENT
}

const _telemetryService = new TelemetryServiceWrapper(
  new FirebaseTelemetryService(userProperties)
)
const _authenticationService = new AuthenticationServiceWrapper(
  new FirebaseAuthenticationService()
)

console.log(`This is a ${process.env.REACT_APP_ENVIRONMENT} build.`)

ReactDOM.render(
  <LocalisationServiceProvider
    children={
      <App
        telemetryService={_telemetryService}
        authenticationService={_authenticationService}
        cloudFunctions={functions}
      />
    }
  />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

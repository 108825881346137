import { PaidTier } from "fixit-shared/PricingTiers";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useString } from "providers/LocalisationProvider";
import { unsubscribe, resume } from "services/payments";
import { ConfirmCancelSubscriptionModal } from "components/Modals/ConfirmCancelSubscriptionModal";
import { PaymentStatus } from "./PaymentStatus";
import { formatUnixTimestampAsDate } from "helpers/format-date";

export const WhileSubscribed = ({ user, paymentMethods }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const periodEnd = formatUnixTimestampAsDate(
    user.subscription.current_period_end
  );

  const onCancel = async () => {
    setShowCancelModal(false);
    setIsCanceling(true);
    await unsubscribe();
    setIsCanceling(false);
  };

  const isCancelingAtPeriodEnd =
    user.subscription && user.subscription.cancel_at_period_end;

  const needsPaymentMethod = paymentMethods.length === 0;
  const paymentIsPrimary = needsPaymentMethod;

  const paymentDetailsButton = (paymentDetailsString) => {
    
    return <Link
      to={{pathname:"/settings/payment-methods", paymentMethods}}
      className={`button ${paymentIsPrimary ? 'primary' : 'accent'} full-width margin-y`}
      disabled={isCanceling}
    >
      { paymentDetailsString }
    </Link>
  }

  const infoString = user.paidProfiles > 0 ? 
    'settings_subscriptions_paid_tier_text':
    'settings_subscriptions_free_tier_text'

  const cancelSubscriptionString = useString("settings_cancel_subscription")

  return (
    <>
      <div className="text-center title">
        {useString(infoString, {
          free_profile_count: user.freeProfiles,
          paid_profile_count: user.paidProfiles,
          cost: PaidTier.Price * user.paidProfiles,
          renewal_date: periodEnd,
        })}
      </div>
      {isCancelingAtPeriodEnd && <CancelAtPeriodEndMessage />}
      <PaymentStatus
        subscription={user.subscription}
        isCanceling={isCanceling}
        paymentMethodsButtonPresent={true}
      />
      <Link
        to={{pathname:"/profiles", paymentMethods}}
        className={`button ${paymentIsPrimary?'accent':'primary'} full-width margin-y`}
      >
        {useString("settings_go_to_profiles")}
      </Link>
      <AmendSubscriptionButton disabled={isCanceling} />
      {paymentDetailsButton(useString(needsPaymentMethod?
        "settings_add_payment_method":
        "settings_edit_payment_details"))
      }
      {isCancelingAtPeriodEnd && <ResumeSubscriptionButton />}
      {user.paidProfiles > 0 && <button
        className="button danger full-width margin-y"
        onClick={() => setShowCancelModal(true)}
        disabled={isCanceling || isCancelingAtPeriodEnd}
      >
        {cancelSubscriptionString}
      </button>
      }
      <ConfirmCancelSubscriptionModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onCancel={onCancel}
      />
    </>
  );
};

const AmendSubscriptionButton = ({ disabled }) => {
  return (
    <Link
      className="button accent full-width margin-y"
      disabled={disabled}
      to="/settings/amend"
    >
      {useString("settings_amend_subscription")}
    </Link>
  );
};

const CancelAtPeriodEndMessage = () => {
  return <div>{useString("settings_cancel_at_period_end_message")}</div>;
};

const ResumeSubscriptionButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    await resume();
  };

  return (
    <button
      className="button primary full-width margin-y"
      onClick={handleClick}
      disabled={isLoading}
    >
      {useString("settings_resume_subscription")}
    </button>
  );
};

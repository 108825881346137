import React, { useState, useEffect } from 'react';
import QuestionHeader from './QuestionHeader';
import ChosenAnswers from './ChosenAnswers';
import PossibleAnswers from './PossibleAnswers';
import CustomDragLayer from './CustomDragLayer';  
import { TextArea } from "components/Forms/TextArea";
import { useString } from "providers/LocalisationProvider";

export const QuestionPageMultipleChoiceWithText = (props) => {

    const {currentQuestion, goToQuestion, isTouchDevice} = props;

    const [selectedChoices, setSelectedChoices] = useState([]);
  
    useEffect(() => {
      setSelectedChoices(currentQuestion.selectedChoices());
    }, [currentQuestion]);
    
    const  _renderCustomDragLayer = () => {
      if (isTouchDevice){
        return <CustomDragLayer/>;
      }
    }
  
    const _selectAnswer = (answerInfo) => {
      if(selectedChoices.length === currentQuestion.maxChoices()) {
        return;
      }
      currentQuestion.selectChoice(answerInfo.answerIndex);
      setSelectedChoices(currentQuestion.selectedChoices());
    }
  
    const _deselectAnswer = (answerInfo) => {
      currentQuestion.deselectChoice(answerInfo.answerIndex);
      setSelectedChoices(currentQuestion.selectedChoices());
    }
    
    return (
      <div className="question-page">
        <QuestionHeader 
            title={currentQuestion.title()}
            icon={currentQuestion.icon()}
            borderColour={currentQuestion.colour()}
        />
        <ChosenAnswers 
            question={currentQuestion}
            goBack={() => goToQuestion(currentQuestion.index()-1)}
            goNext={() => goToQuestion(currentQuestion.index()+1)}
            onDrop={_selectAnswer}
            onContentClick={_deselectAnswer}
            isTouchDevice={isTouchDevice}
        />
        <PossibleAnswers 
            question={currentQuestion}
            onDrop={_deselectAnswer}
            onContentClick={_selectAnswer}
            isTouchDevice={isTouchDevice}
        />
        <div className='flexbox'>
          <TextArea
            name="notes"
            placeholder={useString("text_answers_placeholder")}
            defaultValue={currentQuestion.getTextAnswer()}
            onChange={(v) => currentQuestion.setTextAnswer(v)}
            maxLength={150}
          />
        </div>
        {_renderCustomDragLayer()}
      </div>
    );
};
import React from "react";

export const TextField = (props) => {
  let { name, title, onChange, type, children, placeholder } = props;
  if (!type) type = "text";

  const onTextChanged = (e) => {
    onChange(e.target.value);
  };
  
  const _renderTitle = () => {
    if(title){
      return <label htmlFor={name}>{title}</label>
    }
  }

  return (
    <div className={`text-field text-field-${name}`}>
      {_renderTitle()}
      {children ? (
        children
      ) : (
        <input
          {...props}
          onChange={onTextChanged}
          placeholder={placeholder}
          className={`text-field-input-${name}`}
        />
      )}
    </div>
  );
};

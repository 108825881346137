import React, { useState } from "react";
import { useLocalisationService } from "providers/LocalisationProvider";

export const EditableForm = ({
  title,
  titleCollapsed,
  whileEditing,
  whileViewing,
  onCancel,
  onSave,
  isValid,
  style,
}) => {
  const l = useLocalisationService();
  const [isEditing, setIsEditing] = useState(false);

  titleCollapsed = titleCollapsed || title;

  const _onEdit = () => {
    setIsEditing(true);
  };

  const _onCancel = () => {
    onCancel();
    setIsEditing(false);
  };

  const _onSave = () => {
    onSave();
    setIsEditing(false);
  };

  if (!isEditing) {
    return (
      <div className="editable-form">
        <div className={"editable-section " + style}>
          <span className="title">{titleCollapsed}</span>
          <span className="edit" onClick={_onEdit}>
            {l.getString("settings_edit")}
          </span>
        </div>
        {whileViewing && <div className="display">{whileViewing}</div>}
      </div>
    );
  } else {
    return (
      <div className="editable-form">
        <div className="editable-section">
          <span className="title">{title}</span>
        </div>
        <div className="form">
          {whileEditing}
          <div className="button-group right">
            <button
              onClick={_onCancel}
              className="button primary outlined margin-x"
            >
              {l.getString("cancel")}
            </button>
            <button
              onClick={_onSave}
              className="button primary"
              disabled={!isValid}
            >
              {l.getString("save")}
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export const isAddressValid = (address) => {
  if (!address) return false;

  // TODO: only basic validation for now, this should be expanded further, e.g. check for empty spaces, validate postcode based on country and so forth.
  if (!address.line1) return false;
  if (!address.city) return false;
  if (!address.country) return false;
  if (!address.postal_code) return false;
  return true;
};

import React, { useState } from "react";
import { useString } from "providers/LocalisationProvider";

export const TextArea = (props) => {
  let { name, title, onChange, type, children, defaultValue, placeholder } = props;
  if (!type) type = "text";

  const [text, setText] = useState(defaultValue);

  const onTextChanged = (e) => {
    setText(e.target.value);
    onChange(e.target.value);
  };

  const remainingText = useString("textfield_char_limit", {
    REMAINING: (props.maxLength) ? props.maxLength - text.length : Infinity,
  });
  
  const _renderTitle = () => {
    if(title){
      return <label htmlFor={name}>{title}</label>
    }
  }

  const _renderCharacterLimitInfo = () => {
    if(props.maxLength){
      return <p className='text-area-input-limit'>{remainingText}</p>
    }
  }

  return (
    <div className={`text-area text-area-${name}`}>
      {_renderTitle()}
      {children ? (
        children
      ) : (
        <textarea
          {...props}
          onChange={onTextChanged}
          placeholder={placeholder}
          className={`text-area-input-${name}`}
        />
      )}
      {_renderCharacterLimitInfo()}
    </div>
  );
};

import React from 'react';
import { useString } from "providers/LocalisationProvider";

const StartIcon = '/icons/coa/start_icon.svg'

export const COATitlePage = props => {
    const {profileName, startAppCallback} = props;
    const startIcon = StartIcon + "#start_icon";

    const _renderProfileName = () => {
        return (                
            <g transform="matrix(0.274176,0,0,0.274176,18.0023,111.064)">
                <text className="coa-name-text" x="403" y="233">{profileName}</text>
            </g>
        );  
    }

    const _renderBlurb = (blurbText) => {
        return (                
            <g transform="matrix(0.274176,0,0,0.274176,18.0023,111.064)">
                <text className="coa-blurb-text" x="403" y="430">{blurbText}</text>
            </g>
        );  
    }

    const _onClick = () => {
        startAppCallback();
    }

    const profileNameSvg = _renderProfileName();
    const blurbSvg = _renderBlurb(useString("coa_title_blurb"));

    return (
        <div className="title-screen">
            <svg className="title-call-to-action noselect" onClick={_onClick}>
                <use xlinkHref={startIcon}/>    
                <svg viewBox="0 0 256 256">
                    {profileNameSvg}
                    {blurbSvg}
                </svg>                
            </svg>
        </div>
        );
}
import React from 'react'
import Carousel from 'react-elastic-carousel'
import HtmlParse from 'html-react-parser'
import { useString } from 'providers/LocalisationProvider'
import 'css/carousel.css'

function Item({ children }) {
  return <div className='testimonial-card'>{children}</div>
}

export default function CarouselComponent() {
  return (
    <Carousel itemsToShow={1}>
      <Item>{HtmlParse(useString('homepage_testimonial_1'))}</Item>
      <Item>{HtmlParse(useString('homepage_testimonial_2'))}</Item>
      <Item>{HtmlParse(useString('homepage_testimonial_3'))}</Item>
      <Item>{HtmlParse(useString('homepage_testimonial_4'))}</Item>
      <Item>{HtmlParse(useString('homepage_testimonial_5'))}</Item>
      <Item>{HtmlParse(useString('homepage_testimonial_6'))}</Item>
    </Carousel>
  )
}

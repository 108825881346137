import React from 'react';

export class LocalisationService {
    constructor(languageName, textMap) {
        this._languages = {[languageName]: textMap};
        this._defaultLocale = languageName;
        this._currentLanguage = this._defaultLocale;
    }

    getLocales(){
        return [this._currentLanguage, this._defaultLocale];
    }

    setLanguage(languageName){
        this._currentLanguage = languageName;
    }

    addLanguage(languageName, textMap) {
        this._languages[languageName] = textMap;
    }

    getCurrentLanguage() {
        return this._currentLanguage;
    }

    _hasKeyInCurrentLanguage(key) {
        const hasLanguage = this._currentLanguage in this._languages;
        console.assert(hasLanguage, "ERROR: Missing language " + this._currentLanguage);

        if(!hasLanguage) return false;

        const languageHasKey = key in this._languages[this._currentLanguage];
        console.assert(languageHasKey, "ERROR: Missing text for " + key + " in " + this._currentLanguage);

        return languageHasKey;
    }

    getString(key, substitutions){

        if(!this._hasKeyInCurrentLanguage(key)) return "";

        const localisedText = this._languages[this._currentLanguage];
        
        let string = localisedText[key];

        for(const key in substitutions){
            string = string.replace(`{${key}}`, substitutions[key]);
        }

        return string;
    }

    getStringOrNull(key, substitutions) {
        try {
            return this.getString(key, substitutions)
        } catch (_) {
            return null
        }
    }

    _splitLinkText(key){
        if(!this._hasKeyInCurrentLanguage(key)) return "";

        const parts = this._languages[this._currentLanguage][key].split("{LINK}");
        return {
            prefix: parts[0],
            suffix: parts[1]
        };
    }

    getLink(key, link, text=link){
        const splitLinkText = this._splitLinkText(key);
        return <span>{splitLinkText.prefix}<a href={link}>{text}</a>{splitLinkText.suffix}</span>;
    }

    getNewTabLink(key, link, text=link){
        const splitLinkText = this._splitLinkText(key);
        return <span>{splitLinkText.prefix}<a href={link} target="_blank" rel="noopener noreferrer">{text}</a>{splitLinkText.suffix}</span>;
    }
}
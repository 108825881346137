import { FixitGreen } from "fixit-shared/Consts";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { useLocalisationService } from "providers/LocalisationProvider";
import { subscribe } from "services/payments";
import { Checkout } from "./Checkout";
import { IntegerField } from "components/Forms/IntegerField";
import { Plans } from "fixit-shared/Plans";

export const AmendSubscription = ({ user, profiles }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [checkout, setCheckout] = useState(null);
  const [numProfiles, setNumProfiles] = useState(user.paidProfiles);
  const history = useHistory();
  const l = useLocalisationService();

  const usedProfilesCount = isNaN(profiles.length)
    ? 0
    : profiles.length;

  const onAmend = async () => {
    setIsLoading(true);
    setCheckout(null);
    await subscribe({
      subscribe_to: Plans.Standard,
      is_update: true,
      profiles: numProfiles,
    });
    history.replace("/settings/");
  };

  const onCheckout = async () => {
    setIsLoading(true);
    const result = await subscribe({
      subscribe_to: Plans.Standard,
      is_update: true,
      profiles: numProfiles,
      is_preview: true,
    });
    setCheckout({ invoice: result.preview });
    setIsLoading(false);
  };

  if (checkout)
    return (
      <Checkout
        checkout={checkout}
        onCancel={() => setCheckout(null)}
        onConfirm={onAmend}
      />
    );

  return (
    <>
      <div className="text-bigger text-center title">
        {l.getString("settings_amend_subscription")}
      </div>
      <p>{l.getString("settings_amend_message")}</p>
      {profiles < usedProfilesCount && (
        <p className="text-danger">
          {l.getString("settings_amend_message_not_enough_profiles", {
            profiles: usedProfilesCount,
          })}
        </p>
      )}
      <IntegerField
        disabled={isLoading}
        defaultValue={user.paidProfiles}
        title="Profiles"
        onChange={setNumProfiles}
      />
      {isLoading && <FadeLoader color={FixitGreen} css="margin: 10px auto" />}
      <button
        className="button primary full-width"
        disabled={
          !numProfiles ||
          numProfiles.toString() === user.paidProfiles.toString() ||
          numProfiles < 1 ||
          numProfiles < usedProfilesCount ||
          isLoading
        }
        onClick={onCheckout}
      >
        {l.getString("settings_amend_subscription")}
      </button>
      <Link
        className="button secondary full-width margin-y"
        to="/settings/"
        disabled={isLoading}
      >
        {l.getString("cancel")}
      </Link>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { TextField } from "./TextField";
import { EditableForm } from "./EditableForm";
import { useString } from "providers/LocalisationProvider";
import { isEmailValid } from "helpers/is-account-valid";

export const EmailForm = ({ onChange, defaultEmail }) => {
  const [email, setEmail] = useState(defaultEmail);
  const [isValid, setIsValid] = useState(true);
  const title = useString("sign_in_email_hint");

  const _onChange = (value) => {
    setEmail(value);
    setIsValid(isEmailValid(value));
  };

  const onCancel = () => {
    setEmail(defaultEmail);
  };

  const onSave = () => {
    onChange && onChange(email);
  };

  useEffect(() => {
    setEmail(defaultEmail);
  }, [defaultEmail]);

  return (
    <EditableForm
      titleCollapsed={email || title}
      onSave={onSave}
      onCancel={onCancel}
      isValid={isValid}
      whileEditing={
        <TextField
          name="email"
          type="email"
          title={title}
          placeholder={title}
          defaultValue={email}
          onChange={_onChange}
        />
      }
    />
  );
};

import React from 'react';
import { DragSource } from 'react-dnd'
import Sticker from './Sticker';
import DraggableItemTypes from 'collections/DraggableItemTypes'
import { getEmptyImage } from 'react-dnd-html5-backend'

const DraggableStickerTarget = {
  beginDrag(props, monitor, component){
    const {questionIndex, answerIndex, content} = props;
    return {
      questionIndex: questionIndex,
      answerIndex: answerIndex,
      content: content
    };
  },
  
  canDrag(props){
    return !props.isDragDisabled;
  },

  endDrag(props, monitor, component) {

    if (!monitor.didDrop()) {
      return;
    }

    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
    dropResult.onDrop(item);
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
}

class DraggableSticker extends Sticker {

  componentDidMount() {
    const { useCustomPreview, connectDragPreview } = this.props
    if (useCustomPreview && connectDragPreview) {
      // Use empty image as a drag preview so browsers don't draw it
      // and we can draw whatever we want on the custom drag layer instead.
      connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true,
      })
    }
  }

  render () {
    const {isDragging, isEmpty, connectDragSource, background} = this.props

    const className = background ? "sticker-background sticker-with-background" : "sticker-background";

    return connectDragSource(
    <div className={className} onClick={this._handleClick.bind(this)}>
      {super.render(isEmpty ||isDragging)}
    </div>);
  }

  _handleClick() {
    const {clickHandler, questionIndex, answerIndex, content} = this.props;
    clickHandler({
      questionIndex: questionIndex,
      answerIndex: answerIndex,
      content: content
    });
  }
}

export default DragSource(DraggableItemTypes.Sticker, DraggableStickerTarget, collect)(DraggableSticker);
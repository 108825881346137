import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print';
import Incident from 'models/SessionReport'
import { SummaryPresentation } from './SummaryPresentation'
import { useLocalisationService, useString } from 'providers/LocalisationProvider';
import { useCachedDoc } from 'hooks/useCache';
import { ProfileKeys, ReportKeys, ReportTypes } from 'fixit-shared/FirestoreKeys';
import FadeLoader from "react-spinners/FadeLoader";
import { FixitGreen } from "fixit-shared/Consts";
import { CertificateOfAchievement } from './CertificateOfAchievement';

export const HistoricalAwardSummary = props => {

    const ModeEnum = {Summary: 0, Cert: 1};
    const {telemetryService} = props;
    const localisationService = useLocalisationService();
    const printComponentRef = useRef();
  
    const [pupilProfile, setPupilProfile] = useState(undefined); 
    const [incident, setIncident] = useState(undefined);
    const [incidentSummary, setIncidentSummary] = useState(undefined);
    const [editNotes, setEditNotes] = useState(false);
    const [mode, setMode] = useState(ModeEnum.Summary);

    useEffect(() => {

      const defaultTeacherProfile = {
        raceIcons: "NotSpecified",
        genderIcons: "NonGendered",
      }

      if(!pupilProfile || !incidentSummary) return;
  
      const historicalIncident = Incident.createHistorical(pupilProfile, defaultTeacherProfile, incidentSummary, localisationService);
      setIncident(historicalIncident);
      const openedTime = Date.now();

      const telemetryProps = {
        profile_uuid: historicalIncident.profile().id,
        incident_id: historicalIncident.id(),
        // school_id,
      };
      telemetryService.reportEvent('history.opened_coa', telemetryProps);

      return () => {
        const telemetryProps = {
          profile_uuid: historicalIncident.profile().id,
          incident_id: historicalIncident.id(),
          time_on_page: Date.now() - openedTime,
          // school_id,
        };
        telemetryService.reportEvent('history.closed_coa', telemetryProps);
      }
    }, [pupilProfile, incidentSummary, telemetryService, localisationService]);
  
    const _handlePrint = useReactToPrint({
      onBeforeGetContent: () => {setEditNotes(false); return Promise.resolve();},
      onBeforePrint: () => _sendPrintTelemetry,
      content: () => printComponentRef.current,
    });

    useCachedDoc(
      `${ProfileKeys.tableName}/${props.match.params.profileId}`, 
      setPupilProfile, 
      ProfileKeys.decompress
    );
  
    useCachedDoc(
      `${ProfileKeys.tableName}/${props.match.params.profileId}/${ReportTypes.CertificateOfAchievement}/${props.match.params.incidentId}`,
      setIncidentSummary, 
      ReportKeys.decompress
    );

    const _switchDisplay = () => {
      if(mode === ModeEnum.Summary){
        setMode(ModeEnum.Cert);
      }
      else {
        setMode(ModeEnum.Summary);
      }
    }

    const _finishButton = () => {
      const userHistoryUrl=`/profile/${incident.profile().id}`
      return ( 
        <Link to={userHistoryUrl} className='summary-done'>
          <button className='primary-button summary-button summary-text rounded flexbox'>{summaryDoneString}</button>
        </Link>
      )
    }
  
    const loadingSpinner = () => {
      return <div className='spinner flexbox'>
          <FadeLoader
              color={FixitGreen}
              loading={!pupilProfile || !incidentSummary}
          />
      </div>
    }

    const _sendPrintTelemetry = () => {
      if(incident.isHistorical()){
        const telemetryProps = {
          profile_uuid: incident.profile().id,
          incident_id: incident.id(),
          language: localisationService.getCurrentLanguage(),
          // school_id,
        };
        telemetryService.reportEvent('history.print_coa', telemetryProps);
      }
    }

    const _toggleNotesMode = () => {
      if(editNotes){
        incident.saveUpdatedNotes(ReportTypes.CertificateOfAchievement);
      }
      setEditNotes(!editNotes);      
    }

    const printString = useString('print');
    const summaryDoneString = useString('summary_done')
    const editNotesString = useString(editNotes ? 'summary_incident_notes_save': 'summary_incident_notes_edit');
    const switchDisplayString = useString(mode === ModeEnum.Summary ? 'summary_switch_to_cert': 'summary_switch_to_summary');

    if(!incident) return loadingSpinner();

    var display = null;
    if(mode === ModeEnum.Summary) {
      display = <SummaryPresentation
        type={ReportTypes.CertificateOfAchievement}
        ref={printComponentRef}
        incident = {incident}
        telemetryService = {telemetryService}
        editNotes = {editNotes}
      />
    }
    else{
      display = <CertificateOfAchievement
        ref={printComponentRef}
        certificate = {incident}
        editNotes = {editNotes}
      />
    }

    return <div className = 'fixit'> 
    <div className='noselect centered'>    
        {display}
        <div className='summary-options centered flexbox'> 
          {_finishButton()}
          <button onClick={_handlePrint} className='secondary-button summary-button summary-text rounded flexbox'>{printString}</button>
          <button onClick={_toggleNotesMode} className='secondary-button summary-button summary-text rounded flexbox'>{editNotesString}</button>
          <button onClick={_switchDisplay} className='secondary-button summary-button summary-text rounded flexbox'>{switchDisplayString}</button>
        </div>
      </div>
    </div>
  }
  
export default class AuthenticationServiceWrapper {

    constructor(concrete) {
        this._concrete = concrete;
    }

    addNewUserCallback(callback) {
        this._concrete.addNewUserCallback(callback);
    }

    removeNewUserCallback(callback) {
        this._concrete.removeNewUserCallback(callback);
    }

    addAuthCallbacks(onSignIn, onSignOut) {
        this._concrete.addAuthCallbacks(onSignIn, onSignOut);
    }

    removeAuthCallbacks(onSignIn, onSignOut) {
        this._concrete.removeAuthCallbacks(onSignIn, onSignOut);
    }

    createAccount(email, password, passwordRepeat, onError) {
        return this._concrete.createAccount(email, password, passwordRepeat, onError);
    }

    signInTime(){
        return this._concrete.signInTime();
    }

    signInWithEmailAndPassword(email, password, onError) {
        return this._concrete.signInWithEmailAndPassword(email, password, onError);
    }

    signOut() {
        this._concrete.signOut();
    }

    async resetPassword(email) {
        return this._concrete.resetPassword(email);
    }

    isAuthenticating(){
        return this._concrete.isAuthenticating();
    }

    onAuthenticated(callback) {
        return this._concrete.onAuthenticated(callback);
    }

    isAuthenticated() {
        return this._concrete.isAuthenticated();
    }
}
const path = "fixit/answers/how_do_i_think_i_made_others_feel_answers/";

export const HowDoIThinkIMadeOthersFeelAnswers = [
  {titleKey: "fixit_answers_others_feel_upset"        , icon: path+"upset_{r}x.svg"          , isSelectable: true   },
  {titleKey: "fixit_answers_others_feel_angry"        , icon: path+"angry_{r}x.svg"          , isSelectable: true   },
  {titleKey: "fixit_answers_others_feel_i_dont_know"  , icon: path+"i_dont_know_{r}{g}.svg"  , isSelectable: true   },
  {titleKey: "fixit_answers_others_feel_scared"       , icon: path+"scared_{r}x.svg"         , isSelectable: true   },
  {titleKey: "fixit_answers_others_feel_sad"          , icon: path+"sad_{r}x.svg"            , isSelectable: true   },
  {titleKey: "fixit_answers_others_feel_frustrated"   , icon: path+"frustrated_{r}x.svg"     , isSelectable: true   },
  {titleKey: "fixit_answers_others_feel_bored"        , icon: path+"bored_{r}x.svg"          , isSelectable: true   },
  {titleKey: "fixit_answers_others_feel_disappointed" , icon: path+"disappointed_{r}x.svg"   , isSelectable: true   }
];

export default HowDoIThinkIMadeOthersFeelAnswers;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useString } from "providers/LocalisationProvider";
import { subscribe, planData } from "services/payments";

import { Plans } from "fixit-shared/Plans";
import { IntegerField } from "components/Forms/IntegerField";
import { FreeTier, PaidTier } from "fixit-shared/PricingTiers";
import { Checkout } from "./Checkout";
import HtmlParse from 'html-react-parser';

export const WhileUnsubscribed = ({ isAccountValid }) => {
  const [checkout, setCheckout] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);

  const onCheckout = async (plan, profiles) => {
    setIsLoading(true);
    const planObj = planData(plan, profiles);
    const promise = subscribe({ ...planObj, is_preview: true });
    const result = await promise;
    setCheckout({
      invoice: result.preview,
      plan: planObj,
    });
    setIsLoading(false);
  };

  const onSubscribe = async (planObj) => {
    setIsLoading(true);
    const oldCheckout = checkout;
    setCheckout(null);
    try {
      await subscribe(planObj);
    } catch (error) {
      setCheckout({ ...oldCheckout, error: "settings_checkout_unknown_error" });
    }
  };

  const LinkToProfiles = () => {
    return <Link
      to={{pathname:"/profiles"}}
      className={`button primary full-width margin-y`}
    >
      {useString("settings_go_to_profiles")}
    </Link>
  }

  const PricingInfo = () => {

    const infoMessage = "settings_checkout_message";

    const infoHTML = HtmlParse(useString(infoMessage, {
        FREE_TIER_AMOUNT: FreeTier.Limit,
        PAID_TIER_PRICE: PaidTier.Price,
      }))
    return <p>
      {infoHTML}
    </p>
  }

  if (checkout) {
    return (
      <Checkout
        checkout={checkout}
        onCancel={() => setCheckout(null)}
        onConfirm={() => onSubscribe(checkout.plan)}
      />
    );
  }

  const props = { isLoading, onCheckout };

  return (
    <>
      <Header />
      {PricingInfo()}
      {LinkToProfiles()}
      {!isPurchasing && <PurchaseButton onClick={()=>setIsPurchasing(true)} isAccountValid={isAccountValid} isLoading={isLoading}/>}
      {isPurchasing && <PurchaseSubscriptions {...props} />}
    </>
  );
};

const Header = () => (
  <div className="text-center title">{useString("settings_subscription")}</div>
);

const PurchaseSubscriptions = ({ onCheckout }) => {
  const [profiles, setProfiles] = useState(0);

  return (
    <>
      <p>{useString("settings_checkout_profiles_label")}</p>
      <div className="text-center">
        <IntegerField
          name="profiles"
          onChange={setProfiles}
          defaultValue={profiles}
        />
      <button
        onClick={() => onCheckout(Plans.Standard, profiles)}
        disabled={!profiles}
        className="button accent full-width"
      >
        {useString('settings_checkout_title')}
      </button>
      </div>
    </>
  );
};

const PurchaseButton = ({ onClick, isAccountValid, isLoading }) => {
  let message = "settings_purchase_subscriptions";

  if (isLoading) {
    message = "please_wait";
  }

  if(!isAccountValid){
    message = "settings_fill_in_account_details"
  }

  return (
    <button
      onClick={onClick}
      disabled={isLoading || !isAccountValid}
      className="button accent full-width"
    >
      {useString(message)}
    </button>
  );
};

import React, { useState, useMemo } from 'react';
import { Link, Redirect } from 'react-router-dom'
import FileSaver from 'file-saver'
import Elements from './Elements'
import {ProfilePicker} from './ProfilePicker'
import {NavSurround} from './NavSurround'
import {CreateProfileModal} from './Modals/CreateProfileModal';
import {EditProfileModal} from './Modals/EditProfileModal';
import {ErrorModal} from './Modals/ErrorModal';
import {OptionsModal} from './Modals/OptionsModal';
import {FileUploadModal} from './Modals/FileUploadModal';
import SessionReport from 'models/SessionReport'
import { useLocalisationService } from "providers/LocalisationProvider";
import FadeLoader from "react-spinners/FadeLoader";
import {FixitGreen} from "fixit-shared/Consts"
import { useCurrentUserSnapshot } from "hooks/useAccount";
import { getProfilesRef, deleteProfile, updateSubjectProfile, createProfile } from "helpers/profiles";
import { ProfileErrors } from 'fixit-shared/ErrorCodes';
import { downloadReports } from 'helpers/reports';
import { isSubscriptionValid } from 'services/payments'
import { useCachedCollection } from "hooks/useCache";
import { ProfileKeys, ReportTypes } from 'fixit-shared/FirestoreKeys';
import FixitQuestions from 'collections/fixit/Questions';
import CertificateQuestions from 'collections/certOfAchievement/Questions';

export const Profiles = (props) => {

    const cloudFunctions = props.cloudFunctions;
    const localisationService = useLocalisationService();
    const l = localisationService;
    const authService = props.authService;
    const telemetryService = props.telemetryService;
    const userData = useCurrentUserSnapshot();
    const [profiles, setProfiles] = useState([]);
    const userId = userData ? userData.id : undefined;
    
    const getProfilesQueryMemo = useMemo(() => (userId ? getProfilesRef(userId) : undefined), [userId]);
    useCachedCollection(userId ? `profiles/${userId}` : undefined, getProfilesQueryMemo, setProfiles, ProfileKeys.decompress);

    const [profileCreatorIsOpen, setProfileCreatorIsOpen] = useState(false); 
    const [profileEditorIsOpen, setProfileEditorIsOpen] = useState(false); 
    const [errorModalIsOpen, setErrorModalIsOpen] = useState(false); 
    const [profilesExceededModalIsOpen, setProfilesExceededModalIsOpen] = useState(false);
    const [error, setError] = useState(undefined); 
    const [profileToEdit, setProfileToEdit] = useState(false); 
    const [profileToDelete, setProfileToDelete] = useState(undefined); 
    const [filePickerIsOpen, setFilePickerIsOpen] = useState(false); 
    const [consentModalProfile, setConsentModalProfile] = useState(undefined); 
    const [hasObtainedConsent, setHasObtainedConsent] = useState(false);
    const totalProfileSlots = userData ? (userData.paidProfiles + userData.freeProfiles) : 0;
    let remainingProfileSlots = profiles ? totalProfileSlots - profiles.length : 0;
    if (remainingProfileSlots < 0) remainingProfileSlots = 0;

    const validSubscription = isSubscriptionValid(userData);

    const defaultTeacherProfile = {
        raceIcons: "NotSpecified",
        genderIcons: "NonGendered",
    }

    const _renderInfo = () => {
        return (
            <div className='profile-info'>
                <span className="profile-settings rounded centered">{l.getString("profile_settings")}</span>
                <span className="green-font centered">{l.getString("profile_remaining_seats", { seats: remainingProfileSlots })}</span>
                {totalProfileSlots === 0 && <span className="green-font centered">{l.getString("profile_amend_to_continue")}</span>}
            </div>
        )
    }

    const _renderProfileList = () => {
        const profileList = [];
        profiles.sort((a, b) => a.displayName.localeCompare(b.displayName))
        for (const profile of profiles){
            profileList.push(<ProfilePicker key={profile.id}
                profile={profile}
                renderFixitButton={() => _renderFixitButton(profile)}
                renderAchievementButton={() => _renderAchievementButton(profile)}
                onEditCallback={() => _onEditProfileClicked(profile)}
                onDeleteCallback={() => setProfileToDelete(profile)}
                onDownloadCallback={() => _onDownloadProfileClicked(profile)}/>);
        }
        return profileList;
    }

    const _renderAddProfiles = () => {
        if(!userData || profiles.length === 0) {
           return (
            <div className="centered flexbox">
                   <button className="create-profile-button primary-button rounded flexbox"
                       disabled={totalProfileSlots === 0}
                        onClick={() => setProfileCreatorIsOpen(true)}>
                            {l.getString("profile_create")}
                </button>
            </div>
           )
        }
        else {
            if (remainingProfileSlots < 1) { return null };
            return (
            <div className="add-profiles-container flexbox left">
                {Elements.renderImageButton(() => setProfileCreatorIsOpen(true), '/icons/add.svg', 'add-button rounded', l.getString('add'))}
                {Elements.renderImageButton(() => setFilePickerIsOpen(true), '/icons/upload.svg', 'upload-button rounded', l.getString('upload'))}
            </div>)
        }
    }

    const _renderModals = () => {
        return <div>
            {_renderProfileModal()}
            {_renderEditProfileModal()}
            {_renderErrorModal()}
            {_renderDeleteModal()}
            {_renderFilePickerModal()}
            {_renderGetConsentModal()}
            <ProfilesExceededModal />
        </div>
    }

    const _renderProfileModal = () => {
        return <CreateProfileModal 
            cloudFunctions={cloudFunctions}
            isOpen={profileCreatorIsOpen}
            onClose={() => setProfileCreatorIsOpen(false)}
            onCreatedProfile={_addedProfile}
        />
    }

    const _renderEditProfileModal = () => {
        return <EditProfileModal 
            cloudFunctions={cloudFunctions}
            isOpen={profileEditorIsOpen}
            onClose={() => setProfileEditorIsOpen(false)}
            profile={profileToEdit}
            onEditedProfile={_editedProfile}
        />
    }

    const _renderErrorModal = () => {
        return <ErrorModal
            content={_renderErrorContent(error)}
            isOpen={errorModalIsOpen}
            renderOk={true}
            onClose={()=> setErrorModalIsOpen(false)}
        />
    }

    const ProfilesExceededModal = () => (
        <OptionsModal
        isOpen={profilesExceededModalIsOpen}
        content={<>
            <p>{l.getString("EXCEED_MAX_PROFILE_LIMIT")}</p>
            <p>{l.getString("amend_in_settings")}</p>
        </>}
        options={[
          {
            text: l.getString("close"),
            onClick: () => setProfilesExceededModalIsOpen(false),
            classNames: ["secondary"],
          },
          {
            text: l.getString("settings_go_to_settings"),
            linkTo: "/settings/",
            classNames: ["primary"],
          },
        ]}
        useAltButtons={true}
        onClose={() => setProfilesExceededModalIsOpen(false)}
      />
    )
    
    const _renderDeleteModal = () => {
        return <OptionsModal
            content={_deleteProfileContent(profileToDelete)}
            options={[
                {text: l.getString('cancel'), onClick:() => setProfileToDelete(undefined)},
                {text: l.getString('download'), onClick:() => _downloadProfile(profileToDelete)},
                {text: l.getString('delete'), onClick:_deleteProfile, primary:true}
            ]}
            isOpen={profileToDelete !== undefined}
            renderOk={true}
            onClose={ () => setProfileToDelete(undefined) }
            defaultStyleOverride='delete-modal-button rounded'
            primaryStyle='delete-button'
        />
    }

    const _renderFilePickerModal = () => {
        return <FileUploadModal
            messageKey={'profile_upload_message'}
            isOpen={filePickerIsOpen}
            onClose={()=> setFilePickerIsOpen(false)}
            onSelectedFiles={_readProfilesFromFiles}
        />
    }

    const _renderGetConsentModal = () => {
        return <OptionsModal
            content={_getConsentModalContent(consentModalProfile)}
            options={[
                {text: l.getString('yes'),  onClick:() => _updateProfileConsent(consentModalProfile, true)},
                {text: l.getString('no'),   onClick:() => _updateProfileConsent(consentModalProfile, false)}
            ]}
            isOpen={consentModalProfile !== undefined}
            onClose={() => setConsentModalProfile(undefined)}
        />
    }

    const _renderFixitButton = (profile) => {
        const incident = SessionReport.createNew(profile, defaultTeacherProfile, FixitQuestions, localisationService);

        if(profile.consentObtained){
            return Elements.renderLink('primary-button rounded profile-picker-button', 'fixit', {incident, isNew:true}, l.getString('profile_fixit'));
        }
        return <button className='primary-button rounded profile-picker-button'
                onClick={() => setConsentModalProfile(profile)}>
                    {l.getString("profile_fixit")}
        </button>
    }

    const _renderAchievementButton = (profile) => {
        const certificate = SessionReport.createNew(profile, defaultTeacherProfile, CertificateQuestions, localisationService);

        const data = {certificate, isNew:true};
        if(profile.consentObtained){
            return <Link to={{pathname:'achievement', data}}>
                <button className='blue-button rounded flexbox coa-button'>
                    <img className='coa-icon' src='/icons/coa/star.svg' alt={l.getString('profile_star_img_alt_text')}/>
                    <p>{profile.certificatesOfAchievement == null ? 0 : profile.certificatesOfAchievement.length}</p>
                </button>
            </Link>
        }
        return <button className='blue-button rounded flexbox coa-button'
                onClick={() => setConsentModalProfile(profile)}>
                    {l.getString('profile_achievement')}
        </button>
    }

    const _onEditProfileClicked = (profile) => {
        setProfileToEdit(profile);
        setProfileEditorIsOpen(true);
    }

    const _deleteProfile = () => {
        return deleteProfile(cloudFunctions, profileToDelete.id, userData)
        .then(() => setProfileToDelete(undefined));
    }

    const _onDownloadProfileClicked = (profile) => {
        Promise.resolve(_downloadProfile(profile));
    }

    const _downloadProfile = (profile) => {
        return downloadReports(ReportTypes.Incident, profile.id)
        .then(incidents => {
            const completeProfile = [{
                profile,
                incidents
            }];

            const blob = new Blob([JSON.stringify(completeProfile)], {type:'text/plain;charset=utf-8'});
            FileSaver.saveAs(blob, `${profile.displayName}.json`);
            const telemetryParams = {
                profile_uuid: profile.id,
                max_profiles: totalProfileSlots,
                // school_id,
                // language
            };
            telemetryService.reportEvent('account.downloaded_profile', telemetryParams);
        });
    }

    const _addedProfile = (outcome) => {
        if (outcome.error) {
            if (outcome.error.message === ProfileErrors.exceedMaxProfileLimit) {
                setProfilesExceededModalIsOpen(true);
            } else {
                setError(outcome.error.message);
                setErrorModalIsOpen(true);
            }
        }
        setProfileCreatorIsOpen(false);
    }

    const _editedProfile = (outcome) => {
        if (outcome.error) {
            setError(outcome.error.message);
            setErrorModalIsOpen(true);
        }
        setProfileEditorIsOpen(false);
    }

    const _readProfilesFromFiles = (files) => {
        const promiseArray = files.map(file => _readFile(file));
        return Promise.all(promiseArray)
        .then(_uploadProfilesFromFiles)
        .then((outcome) => {
            _addedProfile(outcome);
        });
    }

    const _uploadProfilesFromFiles = (profileCollections) => {
        const combinedProfiles = [];

        profileCollections.forEach(collection => {
            collection.forEach(profile => combinedProfiles.push(profile));
        });

        const remainingProfileSlots = totalProfileSlots - profiles.length;
        const hasEnoughFreeProfiles = combinedProfiles.length <= remainingProfileSlots;

        const telemetryProps = {
            succeeded: hasEnoughFreeProfiles,
            profiles_remaining: remainingProfileSlots - combinedProfiles.length,
            profiles_uploaded: combinedProfiles.length,
            max_profiles: totalProfileSlots,
        };
        telemetryService.reportEvent('account.uploaded_profiles', telemetryProps);

        if(hasEnoughFreeProfiles){
            const createProfilePromises = combinedProfiles.map(newProfile => createProfile(cloudFunctions, userData, newProfile.profile, newProfile.incidents));
            return Promise.all(createProfilePromises);
        }
        else{
            return Promise.resolve({error: {message:"EXCEED_MAX_PROFILE_LIMIT"}});
        }
    }

    const _readFile = (file) => {
        const reader = new FileReader();
        const promise = new Promise((resolve) => {
            reader.onload = (event => {
                resolve(JSON.parse(event.target.result));
            });
        });
        reader.readAsText(file);
        return promise;
    }

    const _renderErrorContent = (key) => {
        if(!key){
            return <div/>
        }
        return <div className='flexbox centered'>
            {l.getString(key)}
        </div>
    }

    const _deleteProfileContent = (profile) => {
        const displayName = profile ? profile.displayName : '';
        return l.getString('profile_delete_confirm', {'PROFILE_NAME': displayName});
    }

    const _getConsentModalContent = (profile) => {
        const displayName = profile ? profile.displayName : '';
        return l.getString('profile_get_consent', {'PROFILE_NAME': displayName});
    }

    const _updateProfileConsent = (profile, consentObtained) => {
        profile.consentObtained = consentObtained;

        updateSubjectProfile(profile, userData);
        if(consentObtained){
            setHasObtainedConsent(true);
        }
        else {
            setConsentModalProfile(undefined);
        }
    }
    
    if(hasObtainedConsent) {
        return <Redirect to={{pathname:'fixit', profile:consentModalProfile}}/>
    }

    if(!userData || !profiles) {
        return (
        <NavSurround
            authService={authService}
            telemetryService={telemetryService}
            currentPage='profiles'>
                <div className='spinner flexbox'>
                <FadeLoader
                    color={FixitGreen}
                    loading={!userData}
                />
            </div>
        </NavSurround>)
    }

    const hasEnoughFreeProfiles = userData.freeProfiles > 0 && profiles.length <= userData.freeProfiles;

    if (userData && !validSubscription && !hasEnoughFreeProfiles) {
        return <Redirect to="settings"/>
    }

    var profileList = _renderProfileList();
    return (
        <NavSurround
        authService={authService}
        telemetryService={telemetryService}
        currentPage='profiles'>
            <div>
                {_renderInfo()}
                <div>
                    {profileList}
                </div>
                {_renderAddProfiles()}
                {_renderModals()}
            </div>
        </NavSurround>
    )
}
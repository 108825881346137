const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const formatUnixTimestamp = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleString(undefined, options);
};

export const formatUnixTimestampAsDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString(undefined, options);
};

export const formatDate = (date) => {
  return date.toLocaleDateString(undefined, options);
}
import { loadStripe } from "@stripe/stripe-js";
import firebase from "firebase/app";
import "firebase/functions";
import {FunctionRegion} from "fixit-shared/Consts"

const getStripeKey = () => {
  const value = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  if (!value) {
    throw new Error("REACT_APP_STRIPE_PUBLISHABLE_KEY env was not set");
  }

  return value;
};

export const stripePromise = loadStripe(getStripeKey());
const cloudFunction = (name) => firebase.app().functions(FunctionRegion).httpsCallable(name);

export const createCheckoutSession = async (metadata) => {
  const result = await cloudFunction("stripe-createCheckoutSession")(metadata);
  const stripe = await stripePromise;
  const sessionId = result.data;
  stripe.redirectToCheckout({ sessionId });
};

export const subscribe = async (metadata) => {
  const result = await cloudFunction("stripe-subscribe")(metadata);
  const data = result.data;
  if (typeof data !== "string") return data;
  const stripe = await stripePromise;
  stripe.redirectToCheckout({ sessionId: data });
};

export const unsubscribe = async (metadata) => {
  const result = await cloudFunction("stripe-unsubscribe")(metadata);
  return result.data;
};

export const resume = async (metadata) => {
  const result = await cloudFunction("stripe-resume")(metadata);
  return result.data;
};

export const getPaymentMethods = async () => {
  const result = await cloudFunction("stripe-paymentMethods")({
    action: "get",
  });
  return result.data;
};

export const deletePaymentMethod = async (paymentMethod) => {
  const result = await cloudFunction("stripe-paymentMethods")({
    action: "delete",
    paymentMethod,
  });
  return result.data;
};

export const setDefaultPaymentMethod = async (paymentMethod) => {
  const result = await cloudFunction("stripe-paymentMethods")({
    action: "set_default",
    paymentMethod,
  });
  return result.data;
};

const ONGOING = 'ongoing';
const SUCCEEDED = 'succeeded';

export const isSubscriptionValid = (user) => {
  if (!(user && user.subscription)) return false;

  const subscriptionStatus = user.subscription ? user.subscription.status : undefined;

  switch (subscriptionStatus) {
    case ONGOING: {
      if(!user.subscription.payment) return false;
      const paymentIntentStatus = user.subscription.payment.status;
      return paymentIntentStatus === SUCCEEDED;
    }
    default: return false;
  }
}

export const planData = (plan, profiles) => ({ subscribe_to: plan, profiles });

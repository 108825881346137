const translation_prefix = "coa_answers_";
const path = "coa/answers/";

export const LetMeTellYouHowIFeelAnswers = [
  {titleKey: translation_prefix+"happy"          , icon: path+"happy_{r}.svg"              , isSelectable: true   },
  {titleKey: translation_prefix+"proud"          , icon: path+"proud_{r}.svg"              , isSelectable: true   },
  {titleKey: translation_prefix+"excited"        , icon: path+"excited_{r}.svg"            , isSelectable: true   },
  {titleKey: translation_prefix+"something_else" , icon: path+"something_else_{r}{g}.svg"  , isSelectable: false  },
  {titleKey: translation_prefix+"impressed"      , icon: path+"impressed_{r}.svg"          , isSelectable: true   }
];

export default LetMeTellYouHowIFeelAnswers;
import React from 'react';
import { useString } from "providers/LocalisationProvider";

const StartIcon = '/icons/fixit/start_icon.svg'

export const FixitTitlePage = props => {
    const startIcon = StartIcon + "#start_icon";
    const callToActionText = useString("title_cta");

    const {profileName, startAppCallback} = props;

    const _renderStartText = () => {
        const titleTextLine1 = useString("title_line_1");
        let text = <text className="title-text" x="403" y="225">{titleTextLine1}</text>;
        if(profileName){
            text = <><text className="title-text" x="403" y="185">{titleTextLine1}</text>
                        <text className="title-text" x="403" y="245">{profileName}</text></>;
        }    
        return (                
            <g transform="matrix(0.274176,0,0,0.274176,18.0023,111.064)">
                {text}
            </g>
        );  
    }

    const _onClick = () => {
        startAppCallback();
    }

    const startText = _renderStartText();

    return (
        <div className="title-screen">
            <svg className="title-call-to-action noselect" onClick={_onClick}>
                <use xlinkHref={startIcon}/>    
                <svg viewBox="0 0 256 256">
                    {startText}
                    <g transform="matrix(0.274176,0,0,0.274176,18.0023,111.064)">
                        <text className="launch-text" id="launch_text_1" x="403" y="425">{callToActionText}</text>
                    </g>
                </svg>                
            </svg>
        </div>
        );
}
// FROM https://bootsnipp.com/snippets/DOXy4

import React, {useState} from 'react';
import Modal from 'react-modal';
import { useString, useStringOrNull } from "providers/LocalisationProvider";
import { useModalScrollLock } from 'hooks/useModalScrollLock';

export const FileUploadModal = ({onSelectedFiles, isOpen, onClose, messageKey}) => {

    useModalScrollLock(isOpen);
    const [requestInFlight, setRequestInFlight] = useState(false);

    const _renderUploadDialogue = () => {
        return (
            <Modal
                className="modal file-picker-modal"
                overlayClassName="modal-overlay file-picker-modal-overlay"
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel="File Picker Modal"
            >
            {_renderUploadMessage()}
            {_renderFilePicker()}
            </Modal>
        );
    }

    const _renderInFlightDialogue = () => {
        return <Modal
        className="modal create-profile-modal"
        overlayClassName="modal-overlay create-profile-modal-overlay"
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Create Profile">
            <div className="flexbox centered full-height">
                {useString('profile_create_in_flight_multiple')}
            </div>
        </Modal>;
    }

    const _renderFilePicker = () => {
        return (
        <div className="row">
            <div className="col-md-6">
                <form method="post" action="#" id="#" onSubmit={(e)=>e.preventDefault()}>
                    <div className="form-group files flexbox centered">
                        <label>Upload Your File </label>
                        <input type="file" name="file" onChange={_onFileChange}/>  
                    </div>  
                </form>        
            </div>
        </div>
        );
    }

    const _renderUploadMessage = () => {
        const messageString = useStringOrNull(messageKey);
        if(!messageKey){
            return <div/>;
        }
        return <p className='centered'>{messageString}</p>
    }

    const _onFileChange = (event) => {
        setRequestInFlight(true);
        onSelectedFiles(Array.from(event.target.files))
        .then(() => {
            setRequestInFlight(false);
            onClose();
        })
    }

    if(requestInFlight){
        return _renderInFlightDialogue();
    }
    else{
        return _renderUploadDialogue();
    }
}
import React from 'react'
import { DragLayer } from 'react-dnd'
import Sticker from './Sticker';
import DraggableItemTypes from 'collections/DraggableItemTypes'

function getItemStyles(props) {
    const { initialOffset, currentOffset } = props
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      }
    }
    let { x, y } = currentOffset
    const transform = `translate(${x}px, ${y}px)`
    return {
      transform,
      WebkitTransform: transform
    }
  }

const CustomDragLayer = props => {
  const { item, itemType, isDragging } = props
  if (!isDragging) {
    return null
  }

  function renderItem(type, item) {
    switch (type) {
      case DraggableItemTypes.Sticker:
        return <Sticker content={item.content} style={getItemStyles(props)}/>
      default:
        return null;
    }
  }

  return (
    <div className="layer-styles">
       {renderItem(itemType, item)}
    </div>
  )
}
export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
}))(CustomDragLayer)
const path = "fixit/answers/what_can_i_do_to_help_make_it_better_answers/";

export const WhatCanIDoToHelpMakeItBetterAnswers = [
  {titleKey: "fixit_answers_better_again_say_sorry_01"    , icon: path+"i_can_say_sorry_{r}{g}.svg"            , isSelectable: true   },
  {titleKey: "fixit_answers_better_again_make_card"       , icon: path+"make_them_a_card.svg"                  , isSelectable: true   },
  {titleKey: "fixit_answers_better_again_write_sorry"     , icon: path+"write_a_letter_to_say_sorry_{r}x.svg"  , isSelectable: true   },
  {titleKey: "fixit_answers_better_again_help_tidy_up"    , icon: path+"i_can_help_tidy_up_{r}{g}.svg"         , isSelectable: true   },
  {titleKey: "fixit_answers_better_again_something_else"  , icon: path+"something_else_{r}{g}.svg"             , isSelectable: true   }
];

export default WhatCanIDoToHelpMakeItBetterAnswers;
import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import Incident from 'models/SessionReport'
import {NavSurround} from './NavSurround'
import Calendar from 'react-calendar';
import { useString } from "providers/LocalisationProvider";
import { useLocalisationService } from 'providers/LocalisationProvider';
import FadeLoader from "react-spinners/FadeLoader";
import { FixitGreen, ProfileIcons } from "fixit-shared/Consts";
import { useCachedDoc } from 'hooks/useCache';
import { ProfileKeys } from 'fixit-shared/FirestoreKeys';

export const ProfileHistory = (props) => {

    const {authService, telemetryService} = props;
    const localisationService = useLocalisationService();
    const [profile, setProfile] = useState(undefined); 

    const certOfAchievementIcon = '/icons/coa/star.svg';

    useCachedDoc(`${ProfileKeys.tableName}/${props.match.params.profileId}`, setProfile, ProfileKeys.decompress);

    const iconAltTexts = {
        [ProfileIcons.BlackMan]: useString('black_man_option'),
        [ProfileIcons.BlackWoman]: useString('black_woman_option'),
        [ProfileIcons.WhiteMan]: useString('white_man_option'),
        [ProfileIcons.WhiteWoman]: useString('white_woman_option'),
        'coa': useString('profile_achievement'),
    }
    
    const _incidentsOnDay = (date, array) => {

        const selectedDay = new Date(date);
        const nextDay = new Date(date);
        nextDay.setDate(selectedDay.getDate() + 1);  

        if(!array) return [];

        return array.filter(id => {
            const date = Incident.getDate(id)
            return date >= selectedDay && date < nextDay;
        });
    }

    const _noIncidentsOnDate = ({date, view}) => {
        if(view !== "month") {
            return false;
        }
        
        const incidents = _incidentsOnDay(date, profile.incidents);
        const awards = _incidentsOnDay(date, profile.certificatesOfAchievement);

        return incidents.length === 0 && awards.length === 0;
    };

    const _renderTile = ({date, view}) => {
        if(view !== "month") {
            return null;
        }

        const incidents = _incidentsOnDay(date, profile.incidents);
        const awards = _incidentsOnDay(date, profile.certificatesOfAchievement);

        const iconKey = `${profile.raceIcons}${profile.genderIcons}`
        return (
        <div className='incidents-container'>
            {incidents.map(incidentId => _createButton(incidentId, 'incident', ProfileIcons[iconKey], iconAltTexts[iconKey]))}
            {awards.map(incidentId => _createButton(incidentId, 'award', certOfAchievementIcon, iconAltTexts['coa']))}
        </div>
        );
    };

    const _renderDone = (summaryDoneString) => {
        return ( 
            <div className="flexbox centered">
                <Link to='/profiles' className="summary-done">
                    <button className="secondary-button profile-back-button flexbox centered rounded">{summaryDoneString}</button>
                </Link>
            </div>
        )
    };

    const _createButton = (incidentId, type, icon, altText) => {
        const incidentSummaryUrl = `/profile/${profile.id}/${type}/${incidentId}`
        const incidentDate = Incident.getDate(incidentId);
        const dateString = incidentDate.toLocaleTimeString(localisationService.getLocales(), { hour: '2-digit', minute: '2-digit' });
        const style = `flexbox profile-history-${type}-button rounded`

        return <Link key={incidentId} className={style} to={incidentSummaryUrl}>
                <img src={icon} alt={altText}/>
                <div>{dateString}</div>
            </Link>
    };

    const historyDoneString = useString("history_done");
    const title = useString('profile_history_title', {PROFILE_NAME: profile?profile.displayName:''});
    const intro = useString('history_intro', {PROFILE_NAME: profile?profile.displayName:''});

    if(profile === undefined){
        return <div className='spinner flexbox'>
            <FadeLoader
                color={FixitGreen}
                loading={!profile}
            />
        </div>
    }
    
    return (
        <NavSurround
            authService={authService}
            telemetryService={telemetryService}
            currentPage='profileHistory'>
            <div className="history-page">
                <h1>{title}</h1>
                <p>{intro}</p>
                <div className="flexbox">
                <Calendar className="fixit-calendar"
                    tileDisabled={_noIncidentsOnDate}
                    tileContent={_renderTile}
                />
                </div>
                {_renderDone(historyDoneString)}
            </div>
        </NavSurround>
    )
}
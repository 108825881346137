import { useContext } from 'react'
import { LocalisationContext } from 'providers/LocalisationProvider'

export default function Mission() {
  const _localisationService = useContext(LocalisationContext)

  function _renderSubheadedPair(key) {
    const splitContent = _localisationService
      .getString(`${key}_content`)
      .split('\n')
    const splitSpans = splitContent.map((text, i) => <p key={i}>{text}</p>)

    return (
      <>
        <div className='about-section-img-container'>
          <img src={`/about/${key}.svg`} alt={`${key}`} />
        </div>
        <div className='about-section-text-container'>
          <h3 className='about-sub-heading'>
            {_localisationService.getString(`${key}_title`)}
          </h3>
          {splitSpans}
        </div>
      </>
    )
  }

  return (
    <div className='about'>
      <div className='about-section'>
        {_renderSubheadedPair('about_visual_learning')}
      </div>

      <div className='about-section'>
        {_renderSubheadedPair('about_better_teacher_pupil_relationships')}
      </div>

      <div className='about-section'>
        {_renderSubheadedPair('about_better_communication')}
      </div>

      <div className='about-section'>
        {_renderSubheadedPair(
          'about_allow_learners_with_additional_needs_have_say'
        )}
      </div>

      <div className='about-section'>
        {_renderSubheadedPair('about_better_behaviour')}
      </div>


      <div className='about-section'>
        {_renderSubheadedPair('about_three_focus_areas')}
      </div>

      <div className='about-section'>
        {_renderSubheadedPair('about_support_for_teachers_and_families')}
      </div>

      <div className='about-section'>
        {_renderSubheadedPair('about_track_and_monitor_progress')}
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { FixitTitlePage } from './FixitTitlePage';
import QuestionPage from './Questions/QuestionPage';
import { useLocalisationService } from "providers/LocalisationProvider";
import { useString } from "providers/LocalisationProvider";
import { MobileWidth } from 'data/consts';

const FixitStagesEnum = {
  TitlePage:    1, 
  Answering:    2, 
  Summary:      3, 
  Error:        4,
}; 

export const FixitApp = props => {
  const {location, telemetryService, profile} = props;

  const localisationService = useLocalisationService();

  const [incident] = useState(location.data ? location.data.incident : undefined);
  const currStage = location.data ? (location.data.isNew ? FixitStagesEnum.TitlePage : FixitStagesEnum.Answering) : FixitStagesEnum.Error;
  const [stage, setStage] = useState(currStage);
  const [currQuestion, setCurrQuestion] = useState(0);

  const [isMobile] = useState(() => {
    let currentWidth = window.innerWidth;
    return currentWidth < MobileWidth;
  })

  useEffect(() => {
    if(incident && !incident.isComplete()){
      const telemetryProps = {
        profile_uuid: incident.profile().id,
        from_page: incident.currentQuestion().id()
      };
      telemetryService.reportEvent('app.close', telemetryProps);
    }
  }, [incident, telemetryService]);

  const _getContent = () => {

    if(isMobile){
      return _renderMobileError();
    }

    switch (stage){
      case FixitStagesEnum.TitlePage: 
        return _renderTitleScreen();
      case FixitStagesEnum.Answering:
          return _renderQuestionPage();
      case FixitStagesEnum.Summary:
        return _goToSummary();
      default:
        return _renderTitleScreen();
    }
  }

  const _renderTitleScreen = () => {
    return (<FixitTitlePage
      profileName={incident.profile().displayName}
      startAppCallback={_startAppCallback}
    />);
  }

  const _goToSummary = () => {
    return <Redirect to={ {pathname: '/summary', incident} } />;
  }

  const _renderMobileError = () => {
    const pcIcon = "/icons/fixit/devices/pc.svg";
    const laptopIcon = "/icons/fixit/devices/laptops.svg";
    const tabletIcon = "/icons/fixit/devices/tablets.svg";
    const iconSize = 90;
    return <div className='mobile-error'>
      <p>{useString('mobiles_not_supported')}</p>
      <p>{useString('mobile_access_guide')}</p>
      <div className='flexbox centered'>
        <svg width={iconSize} height={iconSize}>       
          <image xlinkHref={pcIcon} width={iconSize} height={iconSize}/>    
        </svg>
        <svg width={iconSize} height={iconSize}>       
          <image xlinkHref={laptopIcon} width={iconSize} height={iconSize}/>    
        </svg>
        <svg width={iconSize} height={iconSize}>       
          <image xlinkHref={tabletIcon} width={iconSize} height={iconSize}/>    
        </svg>
      </div>
      <div className="flexbox centered">
          <Link to='/profiles' className="summary-done">
              <button className="primary-button profile-back-button flexbox centered rounded">{useString('homepage_profiles')}</button>
          </Link>
      </div>
    </div>
  }

  const _renderQuestionPage = () => {
    return (<QuestionPage 
        numQuestions={incident.numQuestions()}
        currentQuestion={incident.currentQuestion()}
        profile={profile}
        goToQuestion={_goToQuestionIndex}
      />);
  }

  const _goToQuestionIndex = (index) => {
    if(index === currQuestion) return;
    const toQuestion = incident.question(index);

    const telemetryProperties = {
      profile_uuid: incident.profile().id,
      from_question: incident.currentQuestion().id(),
      to_question: toQuestion ? toQuestion.id() : "summary",
      initial_answers: incident.currentQuestion().savedChoices().map(answer => answer.id()),
      chosen_answers: incident.currentQuestion().selectedChoices().map(answer => answer.id()),
      // schoool_id,
    }

    telemetryService.reportEvent('app.change_question', telemetryProperties);
    incident.goToQuestion(index);
    const nextStage = incident.isComplete() ? FixitStagesEnum.Summary : FixitStagesEnum.Answering;
    setStage(nextStage);
    setCurrQuestion(index);
  }

  const _startAppCallback = () => {
    setStage(FixitStagesEnum.Answering);
    const telemetryProps = {
      profile_uuid: incident.profile().id,
      language: localisationService.getCurrentLanguage(),
      // schoool_id,
      // class_level,
    };
    telemetryService.reportEvent('app.started_incident', telemetryProps)
  }

  const css = (stage === FixitStagesEnum.TitlePage && !isMobile) ? "fixit fixit-title" : "fixit";

  if(!incident){
    return <Redirect to="profiles"/>
  }

  return (
    <div className={css}>
      {_getContent()}
    </div>
  );
};

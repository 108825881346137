import React, { useState, useMemo } from "react";
import { NavSurround } from "./NavSurround";
import { AddressForm } from "./Forms/AddressForm";
import { Subscription } from "./Subscription/Subscription";
import { getUserRef, useCurrentUserSnapshot, deleteAccount } from "hooks/useAccount";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "services/payments";
import { Header } from "./Forms/Header";
import { NameForm } from "./Forms/NameForm";
import { EmailForm } from "./Forms/EmailForm";
import { useString } from "providers/LocalisationProvider";
import { getProfilesRef } from "helpers/profiles";
import { useCachedCollection } from "hooks/useCache";
import { ConfirmDeleteAccountModal } from "./Modals/ConfirmDeleteAccountModal";
import { UserKeys, ProfileKeys } from 'fixit-shared/FirestoreKeys';

export const UserSettings = (props) => {
  const user = useCurrentUserSnapshot();
  const [profiles, setProfiles] = useState([]);
  const [state, setState] = useState();
  const [passwordResetRequested, setPasswordResetRequested] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState("");
  const [showDeleteAccountConfirmation, setShowDeleteAccountConfirmation] = useState(false);
  const authService = props.authService;
      
  const userId = user ? user.id : undefined;  
  const getProfilesQueryMemo = useMemo(() => (userId ? getProfilesRef(userId) : undefined), [userId]);
  useCachedCollection(userId ? `profiles/${userId}` : undefined, getProfilesQueryMemo, setProfiles, ProfileKeys.decompress);

  const onAddressChange = (address) => {
    getUserRef(user.id).update({ [UserKeys.address]: address });
  };

  const onNameChange = (displayName) => {
    getUserRef(user.id).update({ [UserKeys.displayName]: displayName });
  };

  const onEmailChange = (email) => {
    getUserRef(user.id).update({ [UserKeys.email]: email });
  };

  const resetPassword = async () => {
    try {
      if(passwordResetRequested) return;
      setPasswordResetError("");
      setPasswordResetRequested(true);
      await authService.resetPassword(user.email);
    } catch (error) {
      setPasswordResetError(error.message);
      setPasswordResetRequested(false);
    }
  };
  
  const resetPasswordString = useString("sign_in_reset");
  const resetPasswordRequestedString = useString("sign_in_reset_instructions", { email: user?.email });
  const deleteAccountString = useString("delete_account");

  if (!user) return null;
  if (!profiles) return null;

  return (
    <NavSurround
      authService={props.authService}
      telemetryService={props.telemetryService}
      currentPage="settings"
    >
      <Header user={user} />
      <div className="settings columns">
        <div className="column details">
          <NameForm
            onChange={onNameChange}
            defaultName={user.displayName}
          />
          <EmailForm onChange={onEmailChange} defaultEmail={user.email} />
          <AddressForm
            onChange={onAddressChange}
            defaultAddress={user.address || {}}
          />
          <div className="separator" />
          <button
            className="button accent full-width margin-y"
            onClick={resetPassword}
            disabled={passwordResetRequested}
          >
            {resetPasswordString}
          </button>
          <button
            className="button danger full-width margin-y"
            onClick={() => setShowDeleteAccountConfirmation(true)}
          >
            {deleteAccountString}
          </button>
          <p className="text-danger">
            {passwordResetRequested && resetPasswordRequestedString}
          </p>
          <p className="text-danger">
            {passwordResetError}
          </p>
        </div>
        <div className="separator" />
        <div className="column subscription">
          <Elements stripe={stripePromise}>
            <Subscription
              state={state}
              setState={setState}
              user={user}
              profiles={profiles}
            />
          </Elements>
        </div>
      </div>
      <ConfirmDeleteAccountModal
        isOpen={showDeleteAccountConfirmation}
        onClose={() => setShowDeleteAccountConfirmation(false)}
        onDeleteAccount={deleteAccount}
      />
    </NavSurround>
  );
};

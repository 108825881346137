/**
 * Formats a number as currency.
 *
 * @param currency {string} three-letters iso code for currency
 * @param amount {number} the amount in decimals
 * @returns {string} a string of the formatted number
 */
export const formatCurrency = (currency, amount) => {
  switch (currency) {
    case "gbp":
      return `£${(amount / 100).toFixed(2)}`;
    case "usd":
      return `$${(amount / 100).toFixed(2)}`;
    case "eur":
      return `${(amount / 100).toFixed(2)} €`;
    default:
      throw new Error(`Unhandled currency ${currency}.`);
  }
};

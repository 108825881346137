import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { FixitGreen } from "fixit-shared/Consts";
import { formatSortCode } from "helpers/format-sort-code";
import {
  useLocalisationService,
  useString,
} from "providers/LocalisationProvider";
import {
  createCheckoutSession,
  deletePaymentMethod,
  setDefaultPaymentMethod,
} from "services/payments";

export const PaymentMethods = (props) => {
  const {paymentMethods, refreshPaymentMethods} = props;
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);

  const onAddPaymentMethod = async () => {
    refreshPaymentMethods();
    setIsLoadingCheckout(true);
    createCheckoutSession({
      retry_payments: true,
      return_url: "/settings/payment-methods",
    });
  };

  return (
    <>
      <div className="title">{useString("settings_payment_methods_title")}</div>
      <Content paymentMethods={paymentMethods} onUpdate={refreshPaymentMethods} />
      <button
        className="button primary full-width margin-y"
        onClick={onAddPaymentMethod}
        disabled={isLoadingCheckout}
      >
        {useString("settings_add_payment_method")}
      </button>
      <Link
        to="/settings/"
        className="button accent full-width margin-y"
        disabled={isLoadingCheckout}
      >
        {useString("settings_payment_methods_done")}
      </Link>
    </>
  );
};

const Content = ({ paymentMethods, onUpdate }) => {
  const noPaymentMethodsText = useString("settings_no_payment_methods_info");

  if (paymentMethods === "loading") {
    return <FadeLoader color={FixitGreen} css="margin: 10px auto" />;
  }

  if (!Array.isArray(paymentMethods)) return null;

  if (!paymentMethods.length) {
    return noPaymentMethodsText;
  }

  return (
    <div className="payment-methods">
      {paymentMethods.map((paymentMethod) => (
        <PaymentMethod
          paymentMethod={paymentMethod}
          key={paymentMethod.id}
          onUpdate={onUpdate}
        />
      ))}
    </div>
  );
};

const PaymentMethod = ({ paymentMethod, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const unknownPaymentMethod = useString("settings_unknown_payment_method");

  const onDelete = async () => {
    setIsLoading(true);
    await deletePaymentMethod(paymentMethod.id);
    onUpdate();
  };

  const onSetDefault = async () => {
    setIsLoading(true);
    await setDefaultPaymentMethod(paymentMethod.id);
    onUpdate();
  };

  const props = { paymentMethod, onDelete, onSetDefault, isLoading };

  switch (paymentMethod.type) {
    case "card":
      return <Card {...props} />;
    case "bacs_debit":
      return <Bacs {...props} />;
    default:
      return unknownPaymentMethod;
  }
};

const Buttons = ({ paymentMethod, isLoading, onSetDefault, onDelete }) => {
  const l = useLocalisationService();
  return (
    <span className="actions" disabled={isLoading}>
      {!paymentMethod.isDefault && (
        <span onClick={onSetDefault} className="action">
          {l.getString("set_default")}
        </span>
      )}
      <span onClick={onDelete} className="action">
        {l.getString("delete")}
      </span>
    </span>
  );
};

const Card = (props) => {
  const paymentMethod = props.paymentMethod;
  const cardNumber = `**** **** **** ${paymentMethod.last4}`;
  return (
    <div className="payment-method" data-default={paymentMethod.isDefault}>
      <span className="brand">{paymentMethod.brand}</span>
      <span className="details">{cardNumber}</span>
      <Buttons {...props} />
    </div>
  );
};

const Bacs = (props) => {
  const paymentMethod = props.paymentMethod;
  const formattedSortCode = formatSortCode(paymentMethod.sortCode);
  const accountNumber = `****${paymentMethod.last4}`;
  return (
    <div className="payment-method" data-default={paymentMethod.isDefault}>
      <span className="brand">{paymentMethod.brand}</span>
      <span className="details">
        {formattedSortCode} {accountNumber}
      </span>
      <Buttons {...props} />
    </div>
  );
};

import QuestionMultipleChoice from "./QuestionMultipleChoice";
import MultipleChoiceAnswer from "./MultipleChoiceAnswer";

export default class QuestionMultipleChoiceWithText extends QuestionMultipleChoice {

    constructor(askingProfile, answeringProfile, index, questionInfo, localisationService, maxChoices, isHistorical){ 
        super(askingProfile, answeringProfile, index, questionInfo, localisationService, maxChoices, isHistorical);
        this._textAnswer = isHistorical ? questionInfo.textAnswer: "";
    }

    _buildChoices(answeringProfile) {
        const choices = [];
        for(let i = 0; i < this._questionInfo.multipleChoiceAnswers.length; ++i) {
            choices.push(new MultipleChoiceAnswer(answeringProfile, i, this._questionInfo.multipleChoiceAnswers[i], this._localisationService));
        }
        return choices;
    }

    setTextAnswer(textAnswer){
        this._textAnswer = textAnswer;
    }

    getTextAnswer() {
        return this._textAnswer;
    }

    toJson(){
        const selected = this._selectedChoices.map(choiceIndex => this._allChoices[choiceIndex]);

        return {
            titleKey: this._questionInfo.titleKey,
            icon: this._questionInfo.icon,
            colour: this._questionInfo.colour,
            multipleChoiceAnswers: selected.map(choice => choice.toJson()),
            textAnswer: this._textAnswer,
            type: this._questionInfo.type
        }
    }
}
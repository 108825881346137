const path = "fixit/answers/emotion_name_answers/";

export const EmotionNameAnswers = [
  {titleKey: "fixit_answers_emotion_name_too_excited"  , icon: path+"too_excited_{r}x.svg"   , isSelectable: true   },
  {titleKey: "fixit_answers_emotion_name_disappointed" , icon: path+"disappointed_{r}x.svg"  , isSelectable: true   },
  {titleKey: "fixit_answers_emotion_name_sad"          , icon: path+"sad_{r}x.svg"           , isSelectable: true   },
  {titleKey: "fixit_answers_emotion_name_frustrated"   , icon: path+"frustrated_{r}x.svg"    , isSelectable: true   },
  {titleKey: "fixit_answers_emotion_name_scared"       , icon: path+"scared_{r}x.svg"        , isSelectable: true   },
  {titleKey: "fixit_answers_emotion_name_upset"        , icon: path+"upset_{r}x.svg"         , isSelectable: true   },
  {titleKey: "fixit_answers_emotion_name_tired"        , icon: path+"tired_{r}x.svg"         , isSelectable: true   },
  {titleKey: "fixit_answers_emotion_name_bored"        , icon: path+"bored_{r}x.svg"         , isSelectable: true   },
  {titleKey: "fixit_answers_emotion_name_angry"        , icon: path+"angry_{r}x.svg"         , isSelectable: true   },
  {titleKey: "fixit_answers_emotion_name_dont_know"    , icon: path+"i_dont_know_{r}{g}.svg" , isSelectable: true   }
];

export default EmotionNameAnswers;
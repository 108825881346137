import QuestionTypes from 'models/QuestionTypes';
import ICanSeeYouAreDoingThisAnswers from './01.ICanSeeYouAreDoingThis';
import LetMeTellYouHowIFeelAnswers from './02.LetMeTellYouHowIFeel';
import HowDoYouFeelAnswers from './03.HowDoYouFeel';

const path = "coa/questions";

export const Questions = [
  {
    titleKey:"questions_i_can_see_you_are_doing_this", 
    icon: path+"/you_did_this_{r}{g}.svg", 
    colour:"#9DB4ED", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: ICanSeeYouAreDoingThisAnswers, 
  },
  {
    titleKey:"questions_let_me_tell_you_how_i_feel", 
    icon: path+"/emotion_name_{r}.svg", 
    colour:"#5C7CCD", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: LetMeTellYouHowIFeelAnswers, 
  },
  {
    titleKey:"questions_how_do_you_feel", 
    icon: path+"/how_i_feel_{r}{g}.svg", 
    colour:"#E25F4E", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: HowDoYouFeelAnswers,
  }
];

export default Questions;
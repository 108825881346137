import React, { useContext } from 'react'
import { Link, Switch, Route, useLocation } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'
import { NavSurround } from './NavSurround'
import Mission from './Mission'
import Approach from './Approach'
import Footer from './Footer'
import { LocalisationContext } from 'providers/LocalisationProvider'
import 'css/information-pages.css'
import 'css/about.css'

export default function About(props) {
  const { pathname } = useLocation()

  const _localisationService = useContext(LocalisationContext)

  const { authService, telemetryService } = props

  function _renderHeader() {
    return (
      <div>
        <Link className='info-home-link' to='/'>
          <BsArrowLeft />
          <span>Home</span>
        </Link>
        <h2 className='info-main-heading'>
          {_localisationService.getString('about_title')}
        </h2>
      </div>
    )
  }

  function _renderMissionContent() {
    return (
      <div>
        <Switch>
          <Route exact path='/about' component={Mission} />
          <Route path='/about/approach' component={Approach} />
        </Switch>
      </div>
    )
  }

  function _renderMenu() {
    return (
      <div className='about-menu'>
        <Link
          className={
            pathname === '/about' ? 'about-menu-link active' : 'about-menu-link'
          }
          to='/about'
        >
          {_localisationService.getString('about_mission_title')}
        </Link>{' '}
        <Link
          className={
            pathname === '/about/approach'
              ? 'about-menu-link active'
              : 'about-menu-link'
          }
          to='/about/approach'
        >
          {_localisationService.getString('about_approach_title')}
        </Link>
      </div>
    )
  }

  return (
    <NavSurround
      authService={authService}
      telemetryService={telemetryService}
      currentPage='about'
    >
      <div className='full-width horizontally-centered'>
        <div className='info-content'>
          {_renderHeader()}
          {_renderMenu()}
          {_renderMissionContent()}
        </div>
      </div>
      <Footer />
    </NavSurround>
  )
}

import React, {useRef, useState} from 'react';
import { Redirect } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print';
import { useString } from 'providers/LocalisationProvider';
import { SummaryPresentation } from './SummaryPresentation';
import { ReportTypes } from 'fixit-shared/FirestoreKeys';

export const PostIncidentSummary = props => {
    const {location, telemetryService} = props;
    const [editNotes, setEditNotes] = useState(true);
    const [hasFinished, setHasFinished] = useState(false);
    const incident = location.incident;
    const printComponentRef = useRef();

    const _renderFixedIt = () => {
      return (
      <div className='flexbox'>          
        <button className='primary-button summary-button summary-text rounded flexbox' onClick={_onFixedIt}>
          {useString('summary_fixed_it')}
        </button>
      </div>
      )
    }
  
    const _onFixedIt = () => {
  
      incident.save(ReportTypes.Incident)
      .then(success => setHasFinished(success));
  
      const telemetryProps = {
        profile_uuid: incident.profile().id,
        incident_id: incident.id(),
        summary: incident.toJson().questions,
        duration: Date.now() - incident.date,
        // schoool_id,
        // class_level,
      };
      telemetryService.reportEvent('app.completed_incident', telemetryProps);
    }

    const _handlePrint = useReactToPrint({
      content: () => printComponentRef.current,
      onBeforeGetContent: () => {setEditNotes(false); return Promise.resolve();},
      onAfterPrint: () => {setEditNotes(true); return Promise.resolve();},
    });

    const printString = useString('print');
  
    if(!incident){
      return <Redirect to="profiles"/>
    }

    if(hasFinished){
      return <Redirect to='/profiles' />;
    }
  
    return <div className = 'fixit'> 
    <div className='noselect'>    
        <SummaryPresentation
          type={ReportTypes.Incident}
          ref={printComponentRef}
          incident = {incident}
          telemetryService = {telemetryService}
          editNotes = {editNotes}
        />
        <div className='summary-options centered flexbox'> 
          {_renderFixedIt()}
          <button onClick={_handlePrint} className='secondary-button summary-button summary-text rounded flexbox'>{printString}</button>
        </div>
      </div>
    </div>
  }
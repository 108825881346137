exports.FixitBlue = "#608ed0";
exports.FixitGreen = "#66A161";
exports.FunctionRegion = process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_REGION;

exports.ProfileIcons = {  
    BlackMan:'/icons/fixit/options/option_bm.svg',
    BlackWoman:'/icons/fixit/options/option_bw.svg',
    WhiteMan:'/icons/fixit/options/option_wm.svg',
    WhiteWoman:'/icons/fixit/options/option_ww.svg',
};

exports.Races = {
    NotSpecified: "n",
    Black: "b",
    White: "w",
};

exports.Genders = {
    NonGendered: "n",
    Man: "m",
    Woman: "w",
};
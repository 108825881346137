import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { isAccountValid } from "helpers/is-account-valid";
import { AmendSubscription } from "./AmendSubscription";

import { PaymentMethods } from "./PaymentMethods";
import { InvalidUser } from "./WhileInvalidAddress";
import { useString } from "providers/LocalisationProvider";
import { WhilePending } from "./WhilePending";
import { WhileSubscribed } from "./WhileSubscribed";
import { WhileUnsubscribed } from "./WhileUnsubscribed";
import { getPaymentMethods } from "services/payments";
import { BeatLoader } from "react-spinners";
import { FixitGreen } from "fixit-shared/Consts";
import HtmlParse from 'html-react-parser';
import { FreeTier, PaidTier } from "fixit-shared/PricingTiers";

export const Subscription = (props) => {
  const { user } = props;
  const { valid: isUserValid, error } = isAccountValid(user);
  const showPaymentMethods = useRouteMatch("/settings/payment-methods");
  const showAmend = useRouteMatch("/settings/amend");

  const [paymentMethods, setPaymentMethods] = useState(undefined);

  const fetchPaymentMethods = async () => {
    setPaymentMethods(undefined);
    const _paymentMethods = await getPaymentMethods();
    setPaymentMethods(_paymentMethods);
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, [setPaymentMethods]);


  const Header = () => (
    <>
      <div className="text-center title">{useString("settings_subscription")}</div>  
      {HtmlParse(useString('settings_checkout_message', {
        FREE_TIER_AMOUNT: FreeTier.Limit,
        PAID_TIER_PRICE: PaidTier.Price,
      }))}
    </>
  );

  const LoadingSpinner = (loading) => {
    return <div className='button flexbox'>
    <BeatLoader 
      color={FixitGreen} 
      size={10}
      loading={loading} />
    </div>;
  }

  if (!isUserValid) return <InvalidUser error={error} />;

  if (!user.subscription) return <WhileUnsubscribed isAccountValid={isUserValid} {...props} />;

  if (showPaymentMethods) return <PaymentMethods paymentMethods={paymentMethods} refreshPaymentMethods={fetchPaymentMethods} {...props} />;
  if (showAmend) return <AmendSubscription {...props} />;

  if(paymentMethods === undefined)
  {
    return <>
      {Header()}
      {LoadingSpinner(paymentMethods === undefined)}
    </>
  }

  switch (user.subscription.status) {
    case "pending":
      return <WhilePending {...props} />;
    case "trialing":
    case "ongoing":
      return <WhileSubscribed paymentMethods={paymentMethods} {...props} />;
    default:
      return <WhileUnsubscribed isAccountValid={isUserValid} {...props} />;
  }
};

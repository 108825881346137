
import { TextArea } from "components/Forms/TextArea";
import { useString } from 'providers/LocalisationProvider';

export const SummaryNotes = props => {
    const {incident, editingEnabled} = props;
    
    const _renderEditableNotes = () => {
        return <div className='flexbox summary-incident-notes'>
        <TextArea
            name="incident-notes"
            placeholder={useString("summary_incident_notes_placeholder")}
            defaultValue={incident.getNotes()}
            onChange={(v) => incident.setNotes(v)}
            maxLength={300}
          />
        </div>
    }

    const _renderNotes = () => {
        return <div className="summary-incident-notes"><p>{incident.getNotes()}</p></div>
    }

    if(editingEnabled){
        return _renderEditableNotes();
    }
    return  _renderNotes();
}
import QuestionTypes from "./QuestionTypes";

export default class Question {

    constructor(index, questionInfo, localisationService, iconUrl)
    {
        this._index = index;
        this._questionInfo = questionInfo;
        this._localisationService = localisationService;
        this._iconUrl = iconUrl;
    }

    id() {
        return this._questionInfo.titleKey;
    }

    title() {
        return this._localisationService.getString(this._questionInfo.titleKey);
    }

    icon() {
        return this._iconUrl;
    }

    colour() {
        return this._questionInfo.colour;
    }

    type() {
        return this._questionInfo.type;
    }

    index() {
        return this._index;
    }

    isFirstQuestion() {
        return this._index === 0;
    }

    isCompleted() {
        return true;
    }

    toJson(){
        return {
            titleKey: this._questionInfo.titleKey,
            icon: this._questionInfo.icon,
            colour: this._questionInfo.colour,
            type: this._questionInfo.type | QuestionTypes.Pick3
        }
    }
}
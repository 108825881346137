import QuestionTypes from 'models/QuestionTypes';
import React from 'react';
import { QuestionSummaryMultipleChoice } from './QuestionSummaryMultipleChoice';
import { QuestionSummaryMultipleChoiceWithText } from './QuestionSummaryMultipleChoiceWithText';

export const QuestionSummary = (props) => {

  const {question, onClickCallback} = props;

  const _handleClick = () => {
    onClickCallback(question.index());
  }

  switch (question.type()){
    case QuestionTypes.MultipleChoice:
      return <QuestionSummaryMultipleChoice
        question={question}
        onClick={_handleClick}
      />;
    case QuestionTypes.MultipleChoiceWithText:
      return <QuestionSummaryMultipleChoiceWithText
        question={question}
        onClick={_handleClick}
      />;
    default:
      return <QuestionSummaryMultipleChoice
        question={question}
        onClick={_handleClick}
      />;
  }
}
  
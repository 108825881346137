import React, { useEffect, useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import VideoModal from './Modals/VideoModal'
import HtmlParse from 'html-react-parser'
import { NavSurround } from './NavSurround'
import Footer from './Footer'
import { SignInModal } from './Modals/SignInModal'
import { useString } from 'providers/LocalisationProvider'
import CarouselComponent from './Carousel'
import { useLoggedInState } from 'hooks/useAccount'
import { MobileWidth, TabletWidth } from 'data/consts'

export const Homepage = props => {
  const { authService, telemetryService } = props

  const [screenWidth, setScreenWidth] = useState(() => {
    let currentWidth = window.innerWidth

    if(currentWidth > TabletWidth ) {
      return '_desktop';
    } else if(currentWidth > MobileWidth) {
      return '_tablet';
    } else {
      return '_mobile';
    }
  })

  useEffect(() => {
    function handleWidth() {
      let currentWidth = window.innerWidth

      if(currentWidth > TabletWidth ) {
        setScreenWidth('_desktop')
      } else if(currentWidth > MobileWidth) {
        setScreenWidth('_tablet')
      } else {
        setScreenWidth('_mobile')
      }
    }

    window.addEventListener('resize', handleWidth)

    return function() {
      window.removeEventListener('resize', handleWidth)
    }
  }, [])

  const history = useHistory()

  useLoggedInState(authService, () => {
    telemetryService.reportEvent(`navigation.sign_in`)
  })

  const isSignedIn = authService.isAuthenticated()

  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false)
  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState(false)

  const _isSignInComplete = () => {
    return isSignInModalOpen && isSignedIn
  }

  const goToAbout = () => {
    history.push('/about', { fromFooter: true })
  }

  if (_isSignInComplete()) {
    return <Redirect to='/profiles' />
  }

  const _renderIntro = () => {
    return (
      <div className='ifixit-gradient homepage-intro'>
        <div className='homepage-intro-container'>
          <h2 className='homepage-intro-title'>
            {useString('homepage_intro_title')}
          </h2>
          <p className='homepage-intro-text'>
            {useString('homepage_intro_text')}
          </p>
          <div className='homepage-intro-buttons'>
            {_renderTryAppButton()}
            {_renderVideoButton()}
          </div>
          <p className='homepage-small-text no-margin'>{useString("homepage_compatibility")}</p>
          <div className='title-icon-container'>
            <img
              className='title-icon'
              src={'/homepage/hero-img.svg'}
              alt={useString('homepage_intro_icon_alt_text')}
            />
          </div>
        </div>
      </div>
    )
  }

  const _renderProcess = () => {
    return (
      <div className='homepage-process'>
        <div className='homepage-process-container'>
          <h2 className='homepage-section-head-text'>
            {HtmlParse(useString('homepage_process_header'))}
          </h2>
          <img className='homepage-process-img' src={useString(`homepage_process_img${screenWidth}`)} alt={`ifixit process`} />
        </div>
      </div>
    )
  }

  const _renderTryAppButton = () => {
    const homepageProfilesString = useString('homepage_profiles')
    const homePageTryString = useString('homepage_try')

    if (isSignedIn) {
      return (
        <div className='flexbox'>
          <Link
            style={{ textDecoration: 'none' }}
            to={{ pathname: 'profiles' }}
          >
            <button className='homepage-btn green-button'>
              {homepageProfilesString}
            </button>
          </Link>
        </div>
      )
    }
    return (
      <button
        className='homepage-btn green-button'
        onClick={() => setIsSignInModalOpen(true)}
      >
        {homePageTryString}
      </button>
    )
  }

  const _renderVideoButton = () => {
    const videoString = useString('homepage_see_how_it_works')
    return (
      <button
        className='homepage-btn blue-btn'
        onClick={() => setIsVideoModalOpen(true)}
      >
        <img src='/homepage/play_btn.svg' alt='play video' />
        {videoString}
      </button>
    )
  }

  const _renderDetail = () => {
    return (
      <div className='homepage-details ifixit-gradient'>
        <div className='homepage-details-container'>
          <h2 className='homepage-section-head-text light'>
            {useString('homepage_details_header')}
          </h2>
          <div className='homepage-details-columns'>
            {_renderTeacherColumn()}
            {_renderChildrenColumn()}
          </div>
          <div className='homepage-learn'>
            <button
              className='homepage-btn green-button'
              onClick={() => goToAbout()}
            >
              Learn more
            </button>
          </div>
        </div>
      </div>
    )
  }

  const _renderTeacherColumn = () => {
    return (
      <div className='homepage-column'>
        <h3 className='light'>{useString('homepage_teachers_and_families')}</h3>
        <img
          className='homepage-column-img'
          src='/homepage/teacher_with_pupil.svg'
          alt='teacher_with_pupil'
        />
        <div className='homepage-column-benefits'>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_teachers_and_families_one')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_teachers_and_families_two')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_teachers_and_families_three')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_teachers_and_families_four')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_teachers_and_families_five')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_teachers_and_families_six')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_teachers_and_families_seven')}</span>
          </div>
        </div>
      </div>
    )
  }

  const _renderChildrenColumn = () => {
    return (
      <div className='homepage-column'>
        <h3 className='light'>
          {useString('homepage_children_and_young_people')}
        </h3>
        <img
          className='homepage-column-img'
          src='/homepage/better_relationships.svg'
          alt='better_relationships'
        />
        <div className='homepage-column-benefits'>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_children_and_young_people_one')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_children_and_young_people_two')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_children_and_young_people_three')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_children_and_young_people_four')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_children_and_young_people_five')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_children_and_young_people_six')}</span>
          </div>
          <div className='homepage-column-item'>
            <img
              className='homepage-column-item-img'
              src='/homepage/check_mark.svg'
              alt=''
            />
            <span>{useString('homepage_children_and_young_people_seven')}</span>
          </div>
        </div>
      </div>
    )
  }

  const _renderTestimonials = () => {
    return (
      <div className='homepage-testimonials'>
        <div className='homepage-testimonial-container'>
          <h2 className='homepage-section-head-text'>
            {useString('homepage_testimonials_header')}
          </h2>
          <CarouselComponent />
        </div>
      </div>
    )
  }

  const _toggleAuthentication = signingIn => {
    if (signingIn) {
      setIsSignInModalOpen(true)
    }
  }

  return (
    <NavSurround
      toggleAuthAction={_toggleAuthentication}
      telemetryService={telemetryService}
      authService={authService}
      currentPage='homepage'
    >
      <div className='homepage'>
        {_renderIntro()}
        {_renderProcess()}
        {_renderDetail()}
        {_renderTestimonials()}
        <SignInModal
          authService={authService}
          isOpen={isSignInModalOpen}
          onClose={() => setIsSignInModalOpen(false)}
        />
        <VideoModal
          isOpen={isVideoModalOpen}
          onClose={() => setIsVideoModalOpen(false)}
        />
      </div>
      <Footer />
    </NavSurround>
  )
}

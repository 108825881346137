import React from 'react';

import { ImageContainer } from 'components/ImageContainer'

export default class Sticker extends React.Component {

  render (backgroundOnly) {
    let {rootClassName, titleClassName, iconClassName, style, spinnerColour} = this.props;
    const {content} = this.props;

    const title = content.title();

    rootClassName = rootClassName || "sticker noselect";
    titleClassName = titleClassName || "sticker-title flexbox";
    iconClassName = iconClassName || "answer-icon";

    if(backgroundOnly) {
      titleClassName += " hidden"
      iconClassName += " hidden"
    }
    
    return (<div className={rootClassName}
      style={style}
    >
      <ImageContainer className={iconClassName} src={content.icon()} alt={title} spinnerColour={spinnerColour} size={15}/>
      <div className={titleClassName}>
        <span>{title}</span>
      </div>
    </div>);
  }
}
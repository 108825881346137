
class ProfileErrors {
    constructor(){
        this.exceedMaxProfileLimit = 'EXCEED_MAX_PROFILE_LIMIT';
        this.notProfileOwner = 'NOT_PROFILE_OWNER';
        this.unauthenticatedRequest = 'UNAUTHENTICATED_REQUEST';
        this.missingRequiredParams = 'MISSING_REQUIRED_PARAMS';
    }
}
class DataServiceErrors {
    constructor() {
        this.notLoggedIn = 'NOT_LOGGED_IN';
    }
}

module.exports = {
    ProfileErrors: new ProfileErrors(),
    DataServiceErrors: new DataServiceErrors()
}
import React from "react";
import { formatCurrency } from "helpers/format-currency";
import {
  useString,
  useStringOrNull,
} from "providers/LocalisationProvider";
import { InvoiceTable } from "./InvoiceTable";

export const Checkout = ({ checkout, onCancel, onConfirm }) => {
  // invoice is instance of functions/src/services/models/CustomerFacingInvoice.ts
  const invoice = checkout.invoice;
  const error = checkout.error;

  return (
    <>
      <p className="text-bigger text-center">
        {useString("settings_checkout_title")}
      </p>

      <InvoiceTable invoice={invoice}>
        <InvoiceAmount invoice={invoice} />
      </InvoiceTable>

      {invoice.ending_balance < 0 && <BalanceMessage invoice={invoice} />}

      <p className="text-danger">{useStringOrNull(error)}</p>

      <button
        className="button primary full-width margin-y"
        onClick={onConfirm}
      >
        {useString("confirm")}
      </button>
      <button
        className="button secondary full-width margin-y"
        onClick={onCancel}
      >
        {useString("cancel")}
      </button>
    </>
  );
};

const BalanceMessage = ({ invoice }) => (
  <p>
    {useString("settings_checkout_balance_message", {
      amount: formatCurrency(invoice.currency, invoice.ending_balance * -1),
    })}
  </p>
);

const InvoiceAmount = ({ invoice }) => (
  <>
    <tr>
      <td colSpan="3">
        <div className="fancy-separator margin" />
      </td>
    </tr>
    <tr>
      <td style={{ textAlign: "left" }}>
        {useString("settings_checkout_cost")}
      </td>
      <td>{invoice.lines[0].quantity}</td>
      <td>{formatCurrency(invoice.currency, invoice.total)}</td>
    </tr>
  </>
);

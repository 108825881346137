import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { useLocalisationService } from 'providers/LocalisationProvider'

import 'css/information-pages.css'
import { MobileWidth, TabletWidth } from 'data/consts'

const NavHeader = props => {
  const _localisationService = useLocalisationService()

  const [screenWidth, setScreenWidth] = useState(() => {
    let currentWidth = window.innerWidth

    if(currentWidth > TabletWidth ) {
      return '_desktop';
    } else if(currentWidth > MobileWidth) {
      return '_tablet';
    } else {
      return '_mobile';
    }
  })

  const { authService, currentPage, toggleAuthAction } = props
  const isSignedIn = authService.isAuthenticated()

  useEffect(() => {
    function handleWidth() {
      let currentWidth = window.innerWidth

      if(currentWidth > TabletWidth ) {
        setScreenWidth('_desktop')
      } else if(currentWidth > MobileWidth) {
        setScreenWidth('_tablet')
      } else {
        setScreenWidth('_mobile')
      }
    }

    window.addEventListener('resize', handleWidth)

    return function() {
      window.removeEventListener('resize', handleWidth)
    }
  }, [])
  
  const _renderLoggedInStates = currentPage => {
    return (
      <div className='nav-header-optionals'>
        <Link to={{ pathname: '/profiles' }} disabled={!isSignedIn}>
          {_styledButton('profiles', currentPage, '/icons/profiles_disabled.svg')}
        </Link>
        <Link to={{ pathname: '/settings' }}>
          {_styledButton('settings', currentPage, '/icons/account_settings_disabled.svg')}
        </Link>
      </div>
    )
  }

  const _renderAuthToggle = () => {
    const authButtonText = isSignedIn
      ? _localisationService.getString('title_sign_out')
      : _localisationService.getString('title_sign_in')
    if (isSignedIn) {
      return (
        <Link to='/'>
          <button
            className='nav-button primary-button'
            onClick={_performSignOut}
          >
            {authButtonText}
          </button>
        </Link>
      )
    }
    return (
      <Link to={{ pathname: '/', state: { isSignInModalOpen: true } }}>
        <button className='nav-button primary-button' onClick={_performSignIn}>
          {authButtonText}
        </button>
      </Link>
    )
  }

  const _performSignIn = () => {
    if (toggleAuthAction) {
      const signingIn = true
      toggleAuthAction(signingIn)
    }
  }

  const _performSignOut = () => {
    const { authService, toggleAuthAction, telemetryService, currentPage } =
      props

    const telemetryProps = {
      from_page: currentPage,
      session_duration: _msToSeconds(Date.now() - authService.signInTime())
    }
    telemetryService.reportEvent('navigation.sign_out', telemetryProps)

    authService.signOut()
    if (toggleAuthAction) {
      const signingIn = false
      toggleAuthAction(signingIn)
    }
  }

  const _msToSeconds = milliseconds => {
    return Math.round(milliseconds / 1000)
  }

  const _styledButton = (page, currentPage, icon = null) => {
    let style = 'nav-button secondary-button'
    if (page === currentPage) {
      style += ' underline'
      icon = icon.replace('_disabled.svg' ,'.svg');
    }
    return (
      <button className={style}>
        {screenWidth === '_mobile' ? 
      (<img style={{width: '16px', transform: 'translateY(2px)'}} src={icon} alt={icon}/> ) : (_localisationService.getString(page)) } 
      </button>
    )
  }

  const _styledLink = (page, currentPage) => {
    let style = ''
    if (page === currentPage) {
      style += 'bold'
    }

      return (
        <Link to={`/${page}`} className={style}>
          {_localisationService.getString(page)}
        </Link>
      );
  }

  const loggedInOptions = isSignedIn ? (
    _renderLoggedInStates(currentPage)
  ) : (
    <div />
  )

  const _renderTwitterLink = () => {
    const text = _localisationService.getString('twitter');
    const iconUrl = "/icons/twitter_logo_blue.svg";
    return <a href="https://twitter.com/ifixitapps?ref_src=twsrc%5Etfw">
      <button className={'nav-button secondary-button'}>
        {screenWidth === '_mobile' ? 
        (<img style={{width: '16px', transform: 'translateY(2px)'}} src={iconUrl} alt={text}/> ) : 
        (<><img style={{width: '16px', transform: 'translateY(2px)', marginRight: '5px'}} src={iconUrl} alt={text}/><span className='twitter-link'>{text}</span></>)} 
      </button>
    </a>
  }

  const _renderFacebookLink = () => {
    const altText = _localisationService.getString('facebook');
    const findUsIconUrl = "/icons/find_us_fb_rbg.svg";
    const iconUrl = "/icons/fb_rbg.svg";
    return <>
      <a href="https://www.facebook.com/ifixitapps">
        <button className={'nav-button secondary-button'}>
        {screenWidth === '_mobile' ? 
          <img style={{width: '16px', transform: 'translateY(3px)'}} src={iconUrl} alt={altText}/> :
          <img style={{width: '96px', transform: 'translateY(3px)'}} src={findUsIconUrl} alt={altText}/>
        }
        </button>
      </a>
    </>
  }

  return (
    <div className='nav-header flexbox'>
      <div className='nav-title flexbox'>
        <Link to='/'>
          <img
            src='/homepage/web_home_icon01.svg'
            alt='I Fix It Company logo'
          />
          <span>I Fix It Apps</span>
        </Link>
        {_styledLink('about', currentPage)}
        {_styledLink('faq', currentPage)}
      </div>
      <div className='nav-links'>
        {_renderTwitterLink()}
        {_renderFacebookLink()}
        {loggedInOptions}
        {_renderAuthToggle(isSignedIn)}
      </div>
    </div>
  )
}

export default NavHeader

import { Link } from 'react-router-dom'
import { useString } from 'providers/LocalisationProvider'
import 'css/footer.css'

export default function Footer() {
  return (
    <div className='footer ifixit-gradient'>
      <div className='footer-container'>
        <div className='footer-content'>
          <div className='footer-about'>
            <h4>{useString('footer_about_us')}</h4>
            <div className='footer-links'>
              <Link to={{ pathname: '/about', state: { fromFooter: true } }}>
                {useString('footer_mission')}
              </Link>
              <Link
                to={{
                  pathname: '/about/approach',
                  state: { fromFooter: true }
                }}
              >
                {useString('footer_approach')}
              </Link>
              <a href='mailto:support@ifixitapps.com'>
                {useString('contact_us')}
              </a>
            </div>
          </div>
          <div className='footer-about'>
            <h4>{useString('footer_faq_and_technical')}</h4>
            <div className='footer-links'>
              <Link to='/faq'>{useString('faq_title')}</Link>
              <Link to='/technical-assistance'>
                {useString('faq_technical_assistance_title')}
              </Link>
            </div>
          </div>
          <div className='footer-about'>
            <h4>{useString('footer_privacy_and_terms')}</h4>
            <div className='footer-links'>
              <Link to='/tos'>{useString('footer_terms')}</Link>
              <Link to='/privacy'>{useString('footer_privacy')}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

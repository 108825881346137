import React from "react";
import { formatCurrency } from "helpers/format-currency";
import { useString } from "providers/LocalisationProvider";

export const InvoiceTable = ({ invoice, children }) => {
  return (
    <table className="checkout-table">
      <thead>
        <tr>
          <th>{useString("settings_checkout_item")}</th>
          <th>{useString("settings_checkout_quantity")}</th>
          <th>{useString("settings_checkout_amount")}</th>
        </tr>
      </thead>
      <tbody>
        {invoice.lines.map((l) => (
          <LineItem item={l} key={l.hash} currency={invoice.currency} />
        ))}
        <Balance invoice={invoice} />
        {/* When needed, a component like Balance can be added for VAT */}
      </tbody>
      <tfoot>
        <tr className="total">
          <td colSpan="2">{useString("settings_checkout_total")}</td>
          <td>{formatCurrency(invoice.currency, invoice.total)}</td>
        </tr>
        {children}
      </tfoot>
    </table>
  );
};

const LineItem = ({ item, currency }) => {
  return (
    <tr>
      <td>{item.description}</td>
      <td>{item.quantity}</td>
      <td>{formatCurrency(currency, item.amount)}</td>
    </tr>
  );
};

const Balance = ({ invoice }) => {
  const label = useString(
    invoice.starting_balance < 0
      ? "settings_checkout_credit"
      : "settings_checkout_debit"
  );
  if (!invoice.starting_balance) return null;
  return (
    <tr>
      <td colSpan="2">{label}</td>
      <td>{formatCurrency(invoice.currency, invoice.starting_balance)}</td>
    </tr>
  );
};

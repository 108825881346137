import React from 'react';
import { DropTarget } from 'react-dnd'
import DraggableSticker from './DraggableSticker';
import DraggableItemTypes from 'collections/DraggableItemTypes'

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  }
}

const ChosenAnswersTarget = {
  canDrop(props, monitor) {
      const {question} = props;
      const item = monitor.getItem();
      return !question.isChoiceSelected(item.answerIndex);
  },

  drop(props, monitor, component) {
    const {onDrop} = props;
    return {onDrop: onDrop};
  }
}

export const ChosenAnswers = (props) => {

    const {question, goBack, goNext, isTouchDevice, isOver, connectDropTarget, onContentClick} = props;

    const backArrowRotation = true;
    const nextArrowRotation = false;

    const _renderButton = (visible, onClick, rotated) => {

    const rotationClass = rotated ? "rotated " : "";

    const arrow = "/icons/fixit/arrows/arrow.svg#arrow";

    return (<button
      onClick={onClick}
      className={"chosen-answer " + (visible ? "" : " hidden")}>
      <svg className={rotationClass} viewBox="0 0 136 136">
        <use xlinkHref={arrow} fill={question.colour()}/>
      </svg>
      </button>)
  }

  const _renderDropTarget = () => {

    const borderStyle = {borderColor: question.colour()};

    return connectDropTarget(
      <div className={"chosen-answers-container flexbox" + (isOver ? " is-dragging-over" : "")} 
      style={borderStyle}>
        {_renderItems()}
      </div>);
  }

  const _renderItems = () => {

    const answers = question.selectedChoices();
    const borderStyle = {borderColor: question.colour()};
    
    var items = [];
    
    for(let i = 0; i < question.maxChoices(); ++i ) {
      if(answers.length > i)
      {
        const answer = answers[i];
        items.push(<DraggableSticker 
          key={i}
          questionIndex={question.index()}
          answerIndex={answer.index()}
          content={answer} 
          isEmpty={false}
          useCustomPreview={isTouchDevice}
          clickHandler={_handleContentClick}
          style = {borderStyle}
          background={false}
          spinnerColour={question.colour()}
          />);
      }
      else{
        items.push(<div className="placeholder-sticker" key={i} style = {borderStyle}/>)
      }
    }
    return items;
  }

  const _handleContentClick = (data) => {
    onContentClick(data);
  }

  return (
      <div className="section flexbox">
        {_renderButton(!question.isFirstQuestion(), goBack, backArrowRotation)}
        {_renderDropTarget()}
        {_renderButton(question.selectedChoices().length > 0, goNext, nextArrowRotation)}
      </div>
  );
}
  
export default DropTarget(DraggableItemTypes.Sticker, ChosenAnswersTarget, collect)(ChosenAnswers);
import Question from "./Question";
import MultipleChoiceAnswer from "./MultipleChoiceAnswer";
import IconService from "services/IconService";

export default class QuestionMultipleChoice extends Question {

    constructor(askingProfile, answeringProfile, index, questionInfo, localisationService, maxChoices, isHistorical){ 
        super(index, questionInfo, localisationService, IconService.getIconPath(askingProfile, questionInfo.icon));
        this._maxChoices = maxChoices;
        this._savedChoices = [];
        this._selectedChoices = [];
        this._allChoices = this._buildChoices(answeringProfile);

        if(isHistorical){
            this._allChoices.forEach((_value, index) => {
                this._savedChoices.push(index);
            });
            this._selectedChoices = this._savedChoices;
        }
    }

    maxChoices() {
        return this._maxChoices;
    }

    savedChoices() {
        const choices = [];
        for(let index of this._savedChoices) {
            choices.push(this._allChoices[index]);
        }
        return choices;
    }

    save() {
        this._savedChoices = Array.from(this._selectedChoices);
    }

    selectedChoices() {
        const choices = [];
        for(let index of this._selectedChoices) {
            choices.push(this._allChoices[index]);
        }
        return choices;
    }

    allChoices() {
        return this._allChoices;
    }

    allSelectableChoices() {
        return this._allChoices.filter(answer => (answer.isSelectable()));
    }

    isCompleted() {
        return this._selectedChoices.length > 0;
    }

    selectChoice(choiceIndex) {
        if (this._selectedChoices.length === this._maxChoices){
            return;
        }
        if(!(this.isChoiceSelected(choiceIndex))) {
            this._selectedChoices.push(choiceIndex);
        }
    }

    deselectChoice(choiceIndex) {
        var selectedChoiceIndex = this._selectedChoices.indexOf(choiceIndex);
        if (selectedChoiceIndex > -1) {
            this._selectedChoices.splice(selectedChoiceIndex, 1);
        }
    }

    isChoiceSelected(choiceIndex){
        return this._selectedChoices.includes(choiceIndex);
    }

    _buildChoices(profile) {
        const choices = [];
        for(let i = 0; i < this._questionInfo.answers.length; ++i) {
            choices.push(new MultipleChoiceAnswer(profile, i, this._questionInfo.answers[i], this._localisationService));
        }
        return choices;
    }

    toJson(){
        const selected = this._selectedChoices.map(choiceIndex => this._allChoices[choiceIndex]);

        return {
            titleKey: this._questionInfo.titleKey,
            icon: this._questionInfo.icon,
            colour: this._questionInfo.colour,
            answers: selected.map(choice => choice.toJson()),
            type: this._questionInfo.type
        }
    }
}
import TelemetryService from "./TelemetryService";

export default class TelemetryServiceWrapper extends TelemetryService {

    constructor(concrete) {    
        super();
        this._concrete = concrete;
    }

    reportEvent(event, properties, debug=false) {
        this._concrete.reportEvent(event, properties, debug);
    }
}
import React, {useState} from 'react';
import { Redirect } from 'react-router-dom'
import { QuestionSummary } from './QuestionSummary';
import { useLocalisationService, useString } from 'providers/LocalisationProvider';
import { SummaryNotes } from "./SummaryNotes";

export const SummaryPresentation = React.forwardRef((props, ref) => {

  const {incident, telemetryService, editNotes, type} = props;
  const localisationService = useLocalisationService();
  const [returningToQuestions, setReturningToQuestions] = useState(false);

  const _renderTitle = () => {
    const date = new Intl.DateTimeFormat(localisationService.getLocales(), { weekday: 'long', day: 'numeric', month:'long', year:'numeric', hour:'numeric', minute:'numeric' }).format(incident.date);
  
    const title = useString('summary_title', {NAME: incident.profile().displayName, DATE: date})
    return (  
    <div className='summary-text summary-background-colour'>
      <span>{title}</span>
    </div>
     );
  }

  const _renderQuestionSummaries = () => {
    const summaries = [];
    for (const question of incident.questions()){
      summaries.push(<QuestionSummary key={question.index()} question={question} onClickCallback={_goToQuestion}/>);
    }
    
    return (
    <div className='summary-items summary-background-colour'>
      {summaries}
    </div>
    );
  }

  const _goToQuestion = (index) => {

    if(incident.isHistorical()){
      return;
    }

    const toQuestion = incident.question(index);

    const telemetryProperties = {
      profile_uuid: incident.profile().id,
      to_question: toQuestion.id(),
      // schoool_id,
    }

    telemetryService.reportEvent('app.back_to_questions', telemetryProperties);
    incident.goToQuestion(index);

    setReturningToQuestions(true);
  }

  if(returningToQuestions){
    return <Redirect to={{pathname:'/fixit', data: {incident, isNew:false}}} />;
  }

  return (    
    <div className={`summary summary-${type}`} ref={ref}>
      {_renderTitle()}
      {_renderQuestionSummaries()} 
      <SummaryNotes incident = {incident} editingEnabled = {editNotes}/>
    </div>
  );
});

import React from 'react';
import { LocalisationContext } from "providers/LocalisationProvider";
import HtmlParse from 'html-react-parser';

export default class InDevNotification extends React.Component {
    static contextType = LocalisationContext;

    render() {
        if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") return null;
        return (
            <div className="notification-container full-width flexbox">
                <p>{HtmlParse(this._localisationService().getString("dev_notification"))}</p>
            </div>
        )
    }  
    
    _localisationService() {
        return this.context
    }
}
export const LocalisationEnGb = {
  dev_notification:
    'This is the development environment. Visit <a href=https://ifixitapps.com/>the live site</a>',
  
  app_name                                            : "I Fix It Apps"                                                     ,

  cancel                                              : "Cancel"                                                            ,
  confirm                                             : "Confirm"                                                           ,
  ok                                                  : "Okay"                                                              ,
  yes                                                 : "Yes"                                                               ,
  no                                                  : "No"                                                                ,
  close                                               : "Close"                                                             ,
  save                                                : "Save",
  delete                                              : "Delete"                                                            ,
  set_default                                         : "Set Default"                                                       ,
  download                                            : "Download"                                                          ,
  upload                                              : "Upload"                                                            ,
  edit                                                : "Edit"                                                              ,
  add                                                 : "Add"                                                               ,
  back                                                : "Back",
  contact_us                                          : "Contact Us"                                                        ,
  please_wait                                         : "⏳ Please wait…"                                                   ,

  footer_about_us                                     : "About us"                                                          ,
  footer_mission                                      : "Mission"                                                           ,
  footer_approach                                     : "Our Approach"                                                      ,
  footer_faq_and_technical                            : "FAQ & Technical"                                                   ,
  footer_privacy_and_terms                            : "Privacy & Terms"                                                   ,
  footer_terms                                        : "Terms & Conditions"                                                ,
  footer_privacy                                      : "Privacy"                                                           ,

  homepage_try                                        : "Sign up for free!"                                                 ,
  homepage_terms                                      : "Terms & Conditions"                                                ,
  homepage_profiles                                   : "Go to your profiles"                                               ,
  homepage_see_how_it_works                           : "See how it works"                                                  ,
  homepage_intro_title                                : "Helping Children and Young People Thrive"                          ,
  homepage_intro_subtitle                             : "RESTORATIVE APPROACH “I FIX IT”"                                   ,
  homepage_intro_text                                 : "A restorative approach designed by an additional support needs teacher to help with self-regulation, communication skills, self-awareness, and emotional understanding.",
  homepage_testimonial_1                              : '<div class="testimonial"><div class="testimonial-top"><div class="testimonial-profile"><p class="testimonial-name">Hayley Kelly</p><p        class="testimonial-job">Senior Early Years Practitioner</p></div></div><p>"I Fix It Apps is an invaluable resource that I believe should be used in all Early Years settings with all children. With I Fix It Apps the children are learning how to react appropriately to situations of conflict within their everyday experiences as they develop relationships with peers.</p> <p>The app has helped my setting to develop a consistent approach. Staff feels supported and capable in responding when an incident requires a child to understand their behaviour, how it affects others, and what they should do next time. Children are empowered to have their voice heard, to explore their feelings and make the right choices.</p><p>I cannot recommend this resource enough to those working with our youngest learners."</p> </div>' ,
  homepage_testimonial_2                              : '<div class="testimonial"><div class="testimonial-top"><div class="testimonial-profile"><p class="testimonial-name">Sharon Lesley Barron</p><p class="testimonial-job">Early Years Practitioner Nurture Team</p></div></div><p>"I Fix it Apps supports our Nurture documentation Supporting our Youngest Learners. The process of the App supports the nurturing ethos of knowing the children as individuals, and giving children a sense of security through consistency, predictability, and clear outcomes.  This approach, I feel, helps the child feel safe and calm. <p>The App promotes the quiet time, after the incident, to gently explain why such behaviour should be avoided and helps the child find appropriate ways of reaching a resolve through giving ideas and support.  Using I Fix it Apps empowers children to become leaders of their own learning and supports the building of positive relationships with peers."</p></div>' ,
  homepage_testimonial_3                              : '<div class="testimonial"><div class="testimonial-top"><div class="testimonial-profile"><p class="testimonial-name">Chloe Anderson</p><p class="testimonial-job">Parent</p></div></div><p>"Thank you for introducing Freddie to ‘I Fix It Apps’. The logical steps, clear visuals and element of choice have all helped Freddie to manage his emotions and ‘repair and rebuild’ negative interactions. Since it has been introduced to him, the staff have noticed an improvement in his behaviour with his peers and he is now making the right choices. He is using his words and talking to his practitioners when he is feeling cross rather than hurting the other children."</p></div>' ,
  homepage_testimonial_4                              : '<div class="testimonial"><div class="testimonial-top"><div class="testimonial-profile"><p class="testimonial-name">Freddy Anderson</p><p class="testimonial-job">Child</p></div></div><p>"I like it as it tells me to make a card when I am sorry"</p></div>' ,
  homepage_testimonial_5                              : '<div class="testimonial"><div class="testimonial-top"><div class="testimonial-profile"><p class="testimonial-name">Kirsty Marshall</p><p class="testimonial-job">Social Worker</p></div></div><p>"An easy way to notice patterns/triggers and areas of need. Restorative conversations are hard for some schools and teachers because it is still relatively new. This will make it quicker and easier to implement them."</p></div>' ,
  homepage_testimonial_6                              : '<div class="testimonial"><div class="testimonial-top"><div class="testimonial-profile"><p class="testimonial-name">Lynne Jacobs</p><p class="testimonial-job">Retired Head Teacher</p></div></div><div id="mega-testimonial"><p>"I Fix It Apps encourages learners to become more independent and self-aware; to understand why they find some situations difficult, how the choices they make impact on themselves and others and how they can better respond to difficult situations and make better choices about their behaviour.  It is simple to use, and the graphics are clear and obvious in their meaning.  For children who find it difficult to cope with change or ambiguity, the presentation and predictability of the app mean it is very accessible to all. It’s also a time saving advantage for teachers.</p><p>I can see many advantages to using I Fix It Apps to promote restorative conversations with learners:</p><ul><li>the questions are always the same, with no subtle variances in tone or emphasis given by different adults</li><li>as it keeps a history of the conversations, patterns may emerge which will help pinpoint areas to work on with the child, and progress made can be shared and celebrated</li><li>where an incident happens out with the normal classroom, the class teacher has easy access to the resulting conversation on the app</li><li>the app can also be used at home, which allows for consistency of approach for the child</li></ul></div></div>' ,
  homepage_process_header: "A Learner-centred Approach That Focuses On the Development of Life Skills Through Restorative Conversations.",
  homepage_process_img_desktop: "/homepage/homepage_process_desktop.svg",
  homepage_process_img_tablet: "/homepage/homepage_process_tablet.svg",
  homepage_process_img_mobile: "/homepage/homepage_process_tablet.svg",
  homepage_compatibility: "Currently compatible with browsers and tablets",


  homepage_intro_icon_alt_text                        : "I Fix It Apps Icon"                        ,  
  homepage_intro_icon_sad_text                        : "Incident"                                  ,
  homepage_intro_icon_thinking_text                   : "Explore"                                   ,
  homepage_intro_icon_happy_text                      : "Awareness"                                 ,
  homepage_intro_icon_fixed_text                      : "Fixed It!"                                 ,
  homepage_intro_icon_document_text                   : "Captured"                                  ,

  homepage_details_header                             : "What I Fix It Apps Can Do for Children and Young People with Special Needs, for Their Families and Teachers" ,
  homepage_teachers_and_families                      : "For teachers and families to..." ,
  homepage_teachers_and_families_one                  : "Foster positive, supporting relationships in the classroom and at home.",
  homepage_teachers_and_families_two                  : "Facilitate restorative conversations." ,
  homepage_teachers_and_families_three                : "Help determine the triggers of the behaviours." ,
  homepage_teachers_and_families_four                 : "Support with the development of life skills." ,
  homepage_teachers_and_families_five                 : "Help with Individual Education Plan (IEP) targets." ,
  homepage_teachers_and_families_six                  : "Monitor the history and progress." ,
  homepage_teachers_and_families_seven                : "Celebrate achievements." ,
  homepage_children_and_young_people                  : "For children and young people to..." ,
  homepage_children_and_young_people_one              : "Participate actively in their learning and be part of the decisions that affect them." ,
  homepage_children_and_young_people_two              : "Have a conversation and be heard about their barriers, views, and feelings." ,
  homepage_children_and_young_people_three            : "Further develop understanding, language, and communication skills." ,
  homepage_children_and_young_people_four             : "Find alternative ways to act in challenging situations." ,
  homepage_children_and_young_people_five             : "Become more reflective and self-aware." ,
  homepage_children_and_young_people_six              : "Develop self-regulation skills." ,
  homepage_children_and_young_people_seven            : "Enhance confidence and independence." ,

  homepage_testimonials_header                        : "Testimonials from Learners, Parents, and Professionals." ,

  about_mission_title                                 : "OUR MISSION",
  about_approach_title                                : "OUR APPROACH",
  about_title                                         : "About Us",

  about_supporting_relationships_title                : "Supporting relationships" ,
  about_supporting_relationships_content              : "The goal of I Fix It Apps is to help establish open, positive, supporting relationships by giving learners with additional support needs the opportunity to express their thoughts and feelings. That is how I Fix it Apps can set the right course for inclusion and can help with the challenges of the attainment gap. ",

  about_supporting_wellbeing_title                    : "Supporting emotional wellbeing",
  about_supporting_wellbeing_content                  : "Scottish Government and Education Scotland have highlighted the positive impact where there is a focus on social and emotional wellbeing and an ethos of mutual respect and trust. The focus of I Fix It Apps is ’better relationships, better learning, better behaviour’." ,

  about_supporting_learners_with_additional_needs_title   : "Supporting learners with additional needs" ,
  about_supporting_learners_with_additional_needs_content : "Research shows that children with Additional Support Needs (ASN) are five times more likely to be excluded from school than those without ASN. The latest figures from 2018-2019 show that the total number of exclusions in Primary and Secondary in Scotland was 14,987. Around 70% of those were pupils with ASN in Primary and 63% in Secondary." ,

  about_supporting_every_learner_title                : "Supporting every learner" ,
  about_supporting_every_learner_content              : "The support and strategies which contribute to an inclusive classroom benefit not only those with identified Additional Support Needs, but also enhance the learning and participation of all learners in the class in line with the principles of Universal Design for Learning." ,

  about_visual_learning_title                         : "Visual learning" ,
  about_visual_learning_content                       : "I Fix It Apps is a visual tool that helps with learning and communication. Visual supports are an effective evidence-based strategy, they        increase comprehension by 95% and learners can follow directions 3x better than following just verbal instructions. This is because 70% of their sensory receptors are in their eyes and they get the sense of a picture in 1/10th of a second. 83% of people learn by seeing." ,

  about_better_communication_title                    : "Better communication" ,
  about_better_communication_content                  : "We need to understand what learners are trying to communicate through their behaviour so we can respond better. I Fix It Apps facilitates interactions between learners and school staff, enabling restorative conversations to take place and fostering stronger relationships which are essential to the teaching practice." ,

  about_support_for_teachers_and_families_title       : "Support for teachers and families" ,
  about_support_for_teachers_and_families_content     : "By taking care of learners’ social and emotional wellbeing we are putting in place both the foundations of inclusive education and the wellbeing of peers and staff." ,

  about_better_behaviour_title                        : "Better behaviour" ,
  about_better_behaviour_content                      : "I Fix It Apps complies with the Equality Act (2010). In order to help pupils thrive as part of their class, their school, and their community, I Fix It Apps provides them with alternative ways to act in difficult situations. Knowing that disabling environments and sensory issues can be the cause of high levels of stress and anxiety, I Fix It Apps presents teachers with different approaches to make those necessary adjustments to the social and physical environment." ,

  about_better_teacher_pupil_relationships_title      : "Better teacher / pupil relationships" ,
  about_better_teacher_pupil_relationships_content    : "I Fix It Apps complies with the Children and Young People Act (2014). It enables pupils to participate actively in their learning expressing their views, their feelings, which ensures their emotional health and wellbeing are taken care of. It gives the teachers the opportunity to get to know the learners, to get closer to them. As a result, they can figure out the triggers of the behaviours, they can build on strengths and help learners respond to difficulties." ,

  about_three_focus_areas_title                       : "Three focus areas" ,
  about_three_focus_areas_content                     : "I Fix It Apps is a learner-centred approach that focuses on the three areas highlighted in the Education Act (2002): \n The App facilitates the access to the curriculum with an active participation in the learning. \n It helps learners develop self-regulation skills which enables them to access the physical environment of the schools. \n I Fix It Apps improves communication with learners with disabilities." ,

  about_allow_learners_with_additional_needs_have_say_title  : "Allow learners with additional support needs to have a say" ,
  about_allow_learners_with_additional_needs_have_say_content: "The Code of Practice (2017) states that children with additional support needs should be involved in their personal learning planning. I Fix It Apps is devised to help children and young people to express their needs and to be part of the decisions that affect them. The App ensures that these conversations can take place, as well as reviewing progress and planning next steps of personalised learning." ,

  about_track_and_monitor_progress_title              : "Track and monitor progress" ,
  about_track_and_monitor_progress_content            : "I Fix It Apps includes the possibility to monitor the history, the progress and achievements made by a pupil in an easy way that can also help teachers with Education Planning." ,


  sign_in_sign_up                                     : "Sign Up"                                   ,
  sign_in_have_account                                : "Already Have An Account?"                  ,
  sign_in_divider_text                                : "OR"                                        ,
  sign_in_email_hint                                  : "E-mail"                                    ,
  sign_in_password_hint                               : "Password"                                  ,
  sign_in_repeat_password_hint                        : "Retype Password"                           ,
  sign_in_confirm                                     : "SIGN ME UP"                                ,
  sign_in                                             : "LOG IN"                                    ,
  sign_in_accept_terms                                : "Do you agree to our {LINK}?"               ,
  sign_in_reset_instructions                          : "An email has been sent to {email} with the instructions.",
  sign_in_reset                                       : "Reset Password"                            ,
  delete_account                                      : "Delete your account"                       ,
  sign_in_forgot                                      : "Forgot password?"                          ,
  sign_up_password_mismatch_warning                   : "The passwords do not match"                ,
  sign_up_account_exists_warning                      : "This email address has already signed up." ,
  sign_up_support_warning                             : "Unexpected sign up error '{error}'"        ,

  contact_support                                     : "Please <a href=mailto:support@ifixitapps.com>contact support</a>",

  post_registration_title                             : "What type of account would you like to create?",
  post_registration_text                              : "You get {FREE_PROFILES_COUNT} free profiles. Additional profiles can be purchased at a cost of £{PAID_PROFILE_PRICE} per annum.",

  homepage                                            : "I Fix It Apps"             ,
  faq                                                 : "FAQ"                       ,
  about                                               : "About"                     , 
  profiles                                            : "Profiles"                  ,
  settings                                            : "Account Settings"          ,

  cert_of_achievement                                 : "Certificate of Achievement",
  cert_coming_soon                                    : "Certificates of Achievement coming soon",

  profile_fixit                                       : "FIX IT!"                                                                                       ,
  profile_incidents                                   : "History"                                                                                       ,
  profile_settings                                    : "Profile Settings"                                                                              ,
  profile_remaining_seats                             : "You have {seats} seats remaining"                                                              ,
  profile_amend_to_continue                           : "Purchase a subscription in Account Settings to start adding profiles"                          ,
  profile_create                                      : "Create a profile"                                                                              ,
  profile_create_name_hint                            : "Please enter your name"                                                                        ,
  profile_create_icon_title                           : "Select a Profile Icon"                                                                         ,
  profile_create_name_title                           : "Name"                                                                                          ,
  profile_obtained_consent                            : "Parental Consent? Yes / No"                                                                    ,
  profile_get_consent                                 : "Consent from a parent or guardian is required. Has this been obtained for {PROFILE_NAME}?"     ,
  profile_delete_confirm                              : "Are you sure you want to delete the profile '{PROFILE_NAME}'?"                                 ,
  profile_create_in_flight                            : "Please wait while we create the profile..."                                                    ,
  profile_edit_in_flight                            : "Please wait while we edit the profile..."                                                    ,
  profile_create_in_flight_multiple                   : "Please wait while we upload the profiles..."                                                   ,
  profile_upload_message                              : "You can upload previously downloaded profiles here."                                           ,
  profile_history_title                               : "History for {PROFILE_NAME}"                                                                    ,
  profile_achievement                                 : "Certificate of Achievement!"                                                                   ,
  profile_star_img_alt_text                           : "Star"                                                                                          ,

  black_man_option                                    : "Black Man"                                 ,
  black_woman_option                                  : "Black Woman"                               ,
  white_man_option                                    : "White Man"                                 ,
  white_woman_option                                  : "White Woman"                               ,
  tos                                                 : "terms & conditions?"                       ,

  title_line_1                                        : "OK"                                        ,
  title_cta                                           : "LET'S FIX IT!"                             ,
  title_sign_in                                       : "Register/Login"                            ,
  title_sign_out                                      : "Sign Out"                                  ,
  title_starting_auth                                 : "Please sign-in: "                           ,
  title_completing_auth                               : "Completing sign in..."                     ,
    
  questions_what_happened                             : "What happened?"                            ,
  questions_how_did_it_feel                           : "How did it feel in my body?"               ,
  questions_emotion_name                              : "What emotion was I feeling?"               ,
  questions_what_i_did                                : "What did I do to show it?"                 ,
  questions_who_i_hurt                                : "Who did I hurt?"                           ,
  questions_how_others_feel                           : "How do I think I made others feel?"        ,
  questions_my_day                                    : "How has my day been so far?       "        ,
  questions_good_or_bad_choice                        : "Did I make a good choice or a bad choice?" ,
  questions_make_it_better                            : "What can I do to help make it better?"     ,
  questions_happens_again                             : "What can I do if it happens again?"        ,
  questions_now_i_feel                                : "Now I feel…"                               ,
  questions_i_can_see_you_are_doing_this              : "I can see you are doing this..."           ,
  questions_let_me_tell_you_how_i_feel                : "Let me tell you how I feel..."             ,
  questions_how_do_you_feel                           : "How do you feel?"                          ,

  answers_what_happened_it_was_noisy                  : "It was noisy"                              ,
  answers_what_happened_i_needed_help                 : "I needed help"                             ,
  answers_what_happened_i_wasnt_feeling_well          : "I wasn't feeling well"                     ,
  answers_what_happened_i_wanted_to_choose            : "I wanted to choose"                        ,
  answers_what_happened_i_wanted_to_be_alone          : "I wanted to be alone"                      ,
  answers_what_happened_nobody_was_playing_with_me    : "Someone was mean"                          ,
  answers_what_happened_i_lost_a_game                 : "I lost a game"                             ,
  answers_what_happened_i_wanted_to_go_outside        : "I wanted to go outside"                    ,
  answers_what_happened_i_wanted_to_go_inside         : "I wanted to go inside"                     ,
  answers_what_happened_i_was_hot                     : "I was hot"                                 ,
  answers_what_happened_not_fair                      : "Something wasn't fair"                     ,
  answers_what_happened_i_didnt_want_to_work          : "I didn't want to work"                     ,
  answers_what_happened_someone_else_kicked_me        : "Someone kicked me"                         ,
  answers_what_happened_i_wanted_a_break              : "I wanted a break"                          ,
  answers_what_happened_someone_else_hurt_me          : "Someone hurt me"                           ,
  answers_what_happened_someone_else_bit_me           : "Someone bit me"                            ,
  answers_what_happened_someone_else_hit_me           : "Someone hit me"                            ,
  answers_what_happened_i_cant_remember               : "I can't remember"                          ,
  answers_what_happened_space_invaded                 : "My space was invaded"                      ,
  answers_what_happened_broke_rules                   : "Someone broke the rules"                   ,
  answers_what_happened_something_unsafe              : "Something Unsafe"                          ,
  answers_what_happened_something_else                : "Something Else"                            ,

  answers_how_did_it_feel_sore_tummy                  : "Sore tummy"                                ,
  answers_how_did_it_feel_sore_head                   : "Sore head"                                 ,
  answers_how_did_it_feel_it_hurt                     : "It hurt"                                   ,
  answers_how_did_it_feel_sore_ear                    : "Sore ear"                                  ,
  answers_how_did_it_feel_shaky                       : "Shaky"                                     ,
  answers_how_did_it_feel_sore_tooth                  : "Sore tooth"                                ,
  answers_how_did_it_feel_sore_body                   : "Sore body"                                 ,
  answers_how_did_it_feel_sore_eye                    : "Sore eye"                                  ,
  answers_how_did_it_feel_sore_throat                 : "Sore throat"                               ,
  answers_how_did_it_feel_i_dont_know                 : "I don't know"                              ,
  answers_how_did_it_feel_sore_leg                    : "Sore leg"                                  ,
  answers_how_did_it_feel_hungry                      : "Hungry"                                    ,
  answers_how_did_it_feel_thirsty                     : "Thirsty"                                   ,
  answers_how_did_it_something_else                   : "Something Else"                            ,
  answers_how_did_it_feel_fine                        : "It felt fine"                              ,

  answers_emotion_name_too_excited                    : "Too excited"                               ,
  answers_emotion_name_disappointed                   : "Disappointed"                              ,
  answers_emotion_name_sad                            : "Sad"                                       ,   
  answers_emotion_name_frustrated                     : "Frustrated"                                ,
  answers_emotion_name_scared                         : "Scared"                                    ,
  answers_emotion_name_upset                          : "Upset"                                     ,
  answers_emotion_name_tired                          : "Tired"                                     ,
  answers_emotion_name_bored                          : "Bored"                                     ,
  answers_emotion_name_angry                          : "Angry"                                     ,
  answers_emotion_name_dont_know                      : "I don't know"                              ,

  answers_show_it_i_didnt_listen                      : "I didn't listen"                           ,  
  answers_show_it_i_broke_something                   : "I broke something"                         ,
  answers_show_it_i_threw_things                      : "I threw things"                            ,
  answers_show_it_i_hit_someone                       : "I hit someone"                             ,
  answers_show_it_i_hurt_someone                      : "I hurt someone"                            ,
  answers_show_it_i_slammed_the_door                  : "I slammed the door"                        ,
  answers_show_it_i_scratched_someone                 : "I scratched someone"                       ,
  answers_show_it_i_bit_someone                       : "I bit someone"                             ,
  answers_show_it_i_spat_on_someone                   : "I spat on someone"                         ,
  answers_show_it_i_climbed_furniture                 : "I climbed furniture"                       ,
  answers_show_it_i_yelled                            : "I yelled"                                  ,
  answers_show_it_i_ran_away                          : "I ran away"                                ,
  answers_show_it_i_hit_myself                        : "I hit myself"                              ,
  answers_show_it_i_kicked_someone                    : "I kicked someone"                          ,
  answers_show_it_i_said_bad_words                    : "I said bad words"                          ,
  answers_show_it_i_cant_remember                     : "I can't remember"                          ,
  answers_show_it_i_stomped_feet                      : "I stomped my feet"                         ,
  answers_show_it_broke_rules                         : "I broke the rules"                         ,
  answers_show_it_nothing                             : "Nothing"                                   ,
  answers_show_it_i_cried                             : "I cried"                                   ,

  answers_who_i_hurt_my_teacher                       : "My teacher"                                ,
  answers_who_i_hurt_my_teacher_2                     : "My teacher"                                ,
  answers_who_i_hurt_a_teacher                        : "A teacher"                                 ,
  answers_who_i_hurt_my_brother                       : "My brother"                                ,
  answers_who_i_hurt_my_sister                        : "My sister"                                 ,
  answers_who_i_hurt_a_friend                         : "A friend"                                  ,
  answers_who_i_hurt_my_dad                           : "My dad"                                    ,
  answers_who_i_hurt_my_mum                           : "My mum"                                    ,
  answers_who_i_hurt_i_cant_remember                  : "I can't remember"                          ,
  answers_who_i_hurt_nobody                           : "Nobody"                                    ,
  answers_who_i_hurt_myself                           : "Myself"                                    ,
  answers_who_i_hurt_a_classmate                      : "Another child"                             ,
  answers_who_i_hurt_i_didnt_know_their_name          : "I don't know"                              ,
  answers_who_i_hurt_some_children                    : "Some children"                             ,

  answers_others_feel_upset                           : "Upset"                                     ,
  answers_others_feel_angry                           : "Angry"                                     ,
  answers_others_feel_i_dont_know                     : "I don't know"                              ,
  answers_others_feel_scared                          : "Scared"                                    ,
  answers_others_feel_sad                             : "Sad"                                       ,
  answers_others_feel_frustrated                      : "Frustrated"                                ,
  answers_others_feel_bored                           : "Bored"                                     ,
  answers_others_feel_disappointed                    : "Disappointed"                              ,
      
  answers_good_or_bad_choice_good                     : "Good choice"                               ,
  answers_good_or_bad_choice_bad                      : "Bad choice"                                ,
  answers_good_or_bad_choice_dont_know                : "I don't know"                              ,

  answers_better_again_say_sorry_01                   : "I can say sorry"                           ,
  answers_better_again_make_card                      : "Make them a card"                          ,
  answers_better_again_write_sorry                    : "Write them a letter"                       ,
  answers_better_again_help_tidy_up                   : "I can help tidy up"                        ,
  answers_better_again_something_else                 : "Something else"                            ,
    answers_happens_again_ask_an_adult                  : "Ask for help"                              ,
  answers_happens_again_5_point_scale                 : "Look at my five point scale"               ,
  answers_happens_again_theraputty                    : "Squeeze my theraputty"                     ,
  answers_happens_again_sensory_room                  : "Use the sensory room card"                 ,
  answers_happens_again_timeout_card                  : "Use the timeout card"                      ,
  answers_happens_again_breathing_card                : "Look at the breathing card"                ,
  answers_happens_again_deep_breath                   : "Take a deep breath"                        ,
  answers_happens_again_ear_defenders                 : "Use my ear defenders"                      ,
  answers_happens_again_quiet_desk                    : "Go to my quiet desk"                       ,
  answers_happens_again_zones_of_regulation           : "Look at zones of regulation"               ,
  answers_happens_again_quiet_corner                  : "Go to the quiet corner"                    ,
  answers_happens_again_move_back                     : "Ask someone to move back"                  ,
  answers_happens_again_safe_space                    : "Go to the safe space"                      ,
  answers_happens_again_move_away                     : "Move away from someone"                    ,
  answers_happens_again_dont_understand               : "Say I don't understand"                    ,
  answers_happens_again_something_else                : "Something else"                            ,
  answers_happens_again_hungry                        : "Say I am hungry"                           ,
  answers_happens_again_thirsty                       : "Say I am thirsty"                          ,

  answers_now_i_feel_good                             : "Good"                                      ,
  answers_now_i_feel_ready_to_make_a_card             : "Ready to make a card"                      ,
  answers_now_i_feel_ready_to_say_sorry               : "Ready to say sorry"                        ,
  answers_now_i_feel_ready_to_tidy_up                 : "Ready to tidy up"                          ,
  answers_now_i_feel_ready_to_write_a_letter          : "Ready to write a letter"                   ,
  answers_now_i_feel_ready_back_to_classroom          : "Ready to go to class"                      ,
  answers_now_i_feel_ready_to_play_the_game           : "Ready to play the game"                    ,
  answers_now_i_feel_ready_to_play_with_friends       : "Ready to play with friends"                ,
  answers_now_i_feel_calm                             : "Calm"                                      ,
  answers_now_i_feel_ready_for_the_activity           : "Ready for the activity"                    ,
  answers_now_i_feel_happy_to_have_spoken             : "Happy to have spoken"                      ,
  answers_now_i_feel_proud_i_have_fixed_it            : "Proud I have fixed it"                     ,
  answers_now_i_feel_relieved_to_know_what_to_do      : "Relieved to know what to do"               ,
  answers_now_i_feel_i_need_5_more_minutes            : "I need five more minutes"                  ,

  answers_how_has_my_day_been_good                    : "Good",
  answers_how_has_my_day_been_bad                     : "Bad",
  answers_how_has_my_day_been_so_so                   : "So so",
  answers_how_has_my_day_been_dont_know               : "I don't know",

  fixit_answers_what_happened_it_was_noisy                  : "It was noisy"                              ,
  fixit_answers_what_happened_i_needed_help                 : "I needed help"                             ,
  fixit_answers_what_happened_i_wasnt_feeling_well          : "I wasn't feeling well"                     ,
  fixit_answers_what_happened_i_wanted_to_choose            : "I wanted to choose"                        ,
  fixit_answers_what_happened_i_wanted_to_be_alone          : "I wanted to be alone"                      ,
  fixit_answers_what_happened_nobody_was_playing_with_me    : "Someone was mean"                          ,
  fixit_answers_what_happened_i_lost_a_game                 : "I lost a game"                             ,
  fixit_answers_what_happened_i_wanted_to_go_outside        : "I wanted to go outside"                    ,
  fixit_answers_what_happened_i_wanted_to_go_inside         : "I wanted to go inside"                     ,
  fixit_answers_what_happened_i_was_hot                     : "I was hot"                                 ,
  fixit_answers_what_happened_not_fair                      : "Something wasn't fair"                     ,
  fixit_answers_what_happened_i_didnt_want_to_work          : "I didn't want to work"                     ,
  fixit_answers_what_happened_someone_else_kicked_me        : "Someone kicked me"                         ,
  fixit_answers_what_happened_i_wanted_a_break              : "I wanted a break"                          ,
  fixit_answers_what_happened_someone_else_hurt_me          : "Someone hurt me"                           ,
  fixit_answers_what_happened_someone_else_bit_me           : "Someone bit me"                            ,
  fixit_answers_what_happened_someone_else_hit_me           : "Someone hit me"                            ,
  fixit_answers_what_happened_i_cant_remember               : "I can't remember"                          ,
  fixit_answers_what_happened_space_invaded                 : "My space was invaded"                      ,
  fixit_answers_what_happened_broke_rules                   : "Someone broke the rules"                   ,
  fixit_answers_what_happened_something_unsafe              : "Something Unsafe"                          ,
  fixit_answers_what_happened_something_else                : "Something Else"                            ,

  fixit_answers_how_did_it_feel_sore_tummy                  : "Sore tummy"                                ,
  fixit_answers_how_did_it_feel_sore_head                   : "Sore head"                                 ,
  fixit_answers_how_did_it_feel_it_hurt                     : "It hurt"                                   ,
  fixit_answers_how_did_it_feel_sore_ear                    : "Sore ear"                                  ,
  fixit_answers_how_did_it_feel_shaky                       : "Shaky"                                     ,
  fixit_answers_how_did_it_feel_sore_tooth                  : "Sore tooth"                                ,
  fixit_answers_how_did_it_feel_sore_body                   : "Sore body"                                 ,
  fixit_answers_how_did_it_feel_sore_eye                    : "Sore eye"                                  ,
  fixit_answers_how_did_it_feel_sore_throat                 : "Sore throat"                               ,
  fixit_answers_how_did_it_feel_i_dont_know                 : "I don't know"                              ,
  fixit_answers_how_did_it_feel_sore_leg                    : "Sore leg"                                  ,
  fixit_answers_how_did_it_feel_hungry                      : "Hungry"                                    ,
  fixit_answers_how_did_it_feel_thirsty                     : "Thirsty"                                   ,
  fixit_answers_how_did_it_something_else                   : "Something Else"                            ,
  fixit_answers_how_did_it_feel_fine                        : "It felt fine"                              ,

  fixit_answers_emotion_name_too_excited                    : "Too excited"                               ,
  fixit_answers_emotion_name_disappointed                   : "Disappointed"                              ,
  fixit_answers_emotion_name_sad                            : "Sad"                                       ,   
  fixit_answers_emotion_name_frustrated                     : "Frustrated"                                ,
  fixit_answers_emotion_name_scared                         : "Scared"                                    ,
  fixit_answers_emotion_name_upset                          : "Upset"                                     ,
  fixit_answers_emotion_name_tired                          : "Tired"                                     ,
  fixit_answers_emotion_name_bored                          : "Bored"                                     ,
  fixit_answers_emotion_name_angry                          : "Angry"                                     ,
  fixit_answers_emotion_name_dont_know                      : "I don't know"                              ,

  fixit_answers_show_it_i_didnt_listen                      : "I didn't listen"                           ,  
  fixit_answers_show_it_i_broke_something                   : "I broke something"                         ,
  fixit_answers_show_it_i_threw_things                      : "I threw things"                            ,
  fixit_answers_show_it_i_hit_someone                       : "I hit someone"                             ,
  fixit_answers_show_it_i_hurt_someone                      : "I hurt someone"                            ,
  fixit_answers_show_it_i_slammed_the_door                  : "I slammed the door"                        ,
  fixit_answers_show_it_i_scratched_someone                 : "I scratched someone"                       ,
  fixit_answers_show_it_i_bit_someone                       : "I bit someone"                             ,
  fixit_answers_show_it_i_spat_on_someone                   : "I spat on someone"                         ,
  fixit_answers_show_it_i_climbed_furniture                 : "I climbed furniture"                       ,
  fixit_answers_show_it_i_yelled                            : "I yelled"                                  ,
  fixit_answers_show_it_i_ran_away                          : "I ran away"                                ,
  fixit_answers_show_it_i_hit_myself                        : "I hit myself"                              ,
  fixit_answers_show_it_i_kicked_someone                    : "I kicked someone"                          ,
  fixit_answers_show_it_i_said_bad_words                    : "I said bad words"                          ,
  fixit_answers_show_it_i_cant_remember                     : "I can't remember"                          ,
  fixit_answers_show_it_i_stomped_feet                      : "I stomped my feet"                         ,
  fixit_answers_show_it_broke_rules                         : "I broke the rules"                         ,
  fixit_answers_show_it_nothing                             : "Nothing"                                   ,
  fixit_answers_show_it_i_cried                             : "I cried"                                   ,

  fixit_answers_who_i_hurt_my_teacher                       : "My teacher"                                ,
  fixit_answers_who_i_hurt_my_teacher_2                     : "My teacher"                                ,
  fixit_answers_who_i_hurt_a_teacher                        : "A teacher"                                 ,
  fixit_answers_who_i_hurt_my_brother                       : "My brother"                                ,
  fixit_answers_who_i_hurt_my_sister                        : "My sister"                                 ,
  fixit_answers_who_i_hurt_a_friend                         : "A friend"                                  ,
  fixit_answers_who_i_hurt_my_dad                           : "My dad"                                    ,
  fixit_answers_who_i_hurt_my_mum                           : "My mum"                                    ,
  fixit_answers_who_i_hurt_i_cant_remember                  : "I can't remember"                          ,
  fixit_answers_who_i_hurt_nobody                           : "Nobody"                                    ,
  fixit_answers_who_i_hurt_myself                           : "Myself"                                    ,
  fixit_answers_who_i_hurt_a_classmate                      : "Another child"                             ,
  fixit_answers_who_i_hurt_i_didnt_know_their_name          : "I don't know"                              ,
  fixit_answers_who_i_hurt_some_children                    : "Some children"                             ,

  fixit_answers_others_feel_upset                           : "Upset"                                     ,
  fixit_answers_others_feel_angry                           : "Angry"                                     ,
  fixit_answers_others_feel_i_dont_know                     : "I don't know"                              ,
  fixit_answers_others_feel_scared                          : "Scared"                                    ,
  fixit_answers_others_feel_sad                             : "Sad"                                       ,
  fixit_answers_others_feel_frustrated                      : "Frustrated"                                ,
  fixit_answers_others_feel_bored                           : "Bored"                                     ,
  fixit_answers_others_feel_disappointed                    : "Disappointed"                              ,
            
  fixit_answers_good_or_bad_choice_good                     : "Good choice"                               ,
  fixit_answers_good_or_bad_choice_bad                      : "Bad choice"                                ,
  fixit_answers_good_or_bad_choice_dont_know                : "I don't know"                              ,

  fixit_answers_better_again_say_sorry_01                   : "I can say sorry"                           ,
  fixit_answers_better_again_make_card                      : "Make them a card"                          ,
  fixit_answers_better_again_write_sorry                    : "Write them a letter"                       ,
  fixit_answers_better_again_help_tidy_up                   : "I can help tidy up"                        ,
  fixit_answers_better_again_something_else                 : "Something else"                            ,
  
  fixit_answers_happens_again_ask_an_adult                  : "Ask for help"                              ,
  fixit_answers_happens_again_5_point_scale                 : "Look at my five point scale"               ,
  fixit_answers_happens_again_theraputty                    : "Squeeze my theraputty"                     ,
  fixit_answers_happens_again_sensory_room                  : "Use the sensory room card"                 ,
  fixit_answers_happens_again_timeout_card                  : "Use the timeout card"                      ,
  fixit_answers_happens_again_breathing_card                : "Look at the breathing card"                ,
  fixit_answers_happens_again_deep_breath                   : "Take a deep breath"                        ,
  fixit_answers_happens_again_ear_defenders                 : "Use my ear defenders"                      ,
  fixit_answers_happens_again_quiet_desk                    : "Go to my quiet desk"                       ,
  fixit_answers_happens_again_zones_of_regulation           : "Look at zones of regulation"               ,
  fixit_answers_happens_again_quiet_corner                  : "Go to the quiet corner"                    ,
  fixit_answers_happens_again_move_back                     : "Ask someone to move back"                  ,
  fixit_answers_happens_again_safe_space                    : "Go to the safe space"                      ,
  fixit_answers_happens_again_move_away                     : "Move away from someone"                    ,
  fixit_answers_happens_again_dont_understand               : "Say I don't understand"                    ,
  fixit_answers_happens_again_something_else                : "Something else"                            ,
  fixit_answers_happens_again_hungry                        : "Say I am hungry"                           ,
  fixit_answers_happens_again_thirsty                       : "Say I am thirsty"                          ,

  fixit_answers_now_i_feel_good                             : "Good"                                      ,
  fixit_answers_now_i_feel_ready_to_make_a_card             : "Ready to make a card"                      ,
  fixit_answers_now_i_feel_ready_to_say_sorry               : "Ready to say sorry"                        ,
  fixit_answers_now_i_feel_ready_to_tidy_up                 : "Ready to tidy up"                          ,
  fixit_answers_now_i_feel_ready_to_write_a_letter          : "Ready to write a letter"                   ,
  fixit_answers_now_i_feel_ready_back_to_classroom          : "Ready to go to class"                      ,
  fixit_answers_now_i_feel_ready_to_play_the_game           : "Ready to play the game"                    ,
  fixit_answers_now_i_feel_ready_to_play_with_friends       : "Ready to play with friends"                ,
  fixit_answers_now_i_feel_calm                             : "Calm"                                      ,
  fixit_answers_now_i_feel_ready_for_the_activity           : "Ready for the activity"                    ,
  fixit_answers_now_i_feel_happy_to_have_spoken             : "Happy to have spoken"                      ,
  fixit_answers_now_i_feel_proud_i_have_fixed_it            : "Proud I have fixed it"                     ,
  fixit_answers_now_i_feel_relieved_to_know_what_to_do      : "Relieved to know what to do"               ,
  fixit_answers_now_i_feel_i_need_5_more_minutes            : "I need five more minutes"                  ,

  fixit_answers_how_has_my_day_been_good                    : "Good",
  fixit_answers_how_has_my_day_been_bad                     : "Bad",
  fixit_answers_how_has_my_day_been_so_so                   : "So so",
  fixit_answers_how_has_my_day_been_dont_know               : "I don't know",

  coa_answers_i_can_see_you_are_doing_this_asking_for_help: "Asking for help",
  coa_answers_i_can_see_you_are_doing_this_5_point_scale: "Looking at your five point scale",
  coa_answers_i_can_see_you_are_doing_this_theraputty: "Squeezing your theraputty",
  coa_answers_i_can_see_you_are_doing_this_sensory_room: "Using the sensory room card",
  coa_answers_i_can_see_you_are_doing_this_timeout_card: "Using the timeout card",
  coa_answers_i_can_see_you_are_doing_this_breathing_card: "Looking at the breathing card",
  coa_answers_i_can_see_you_are_doing_this_deep_breath: "Taking a deep breath",
  coa_answers_i_can_see_you_are_doing_this_ear_defenders: "Using the ear defenders",
  coa_answers_i_can_see_you_are_doing_this_quiet_desk: "Going to your quiet desk",
  coa_answers_i_can_see_you_are_doing_this_zones_of_regulation: "Looking at the zones of regulation",
  coa_answers_i_can_see_you_are_doing_this_quite_corner: "Going to the quiet corner",
  coa_answers_i_can_see_you_are_doing_this_ask_move_back: "Asking someone to move back",
  coa_answers_i_can_see_you_are_doing_this_safe_space: "Going to the safe space",
  coa_answers_i_can_see_you_are_doing_this_move_away: "Moving away from someone",
  coa_answers_i_can_see_you_are_doing_this_say_dont_understand: "Saying you don't understand",
  coa_answers_i_can_see_you_are_doing_this_something_else: "Something else",
  coa_answers_i_can_see_you_are_doing_this_say_hungry: "Saying that you're hungry",
  coa_answers_i_can_see_you_are_doing_this_say_thirsty: "Saying that you're thirsty",

  coa_answers_let_me_tell_you_how_i_feel_happy: "Happy",
  coa_answers_let_me_tell_you_how_i_feel_proud: "Proud",
  coa_answers_let_me_tell_you_how_i_feel_excited: "Excited",
  coa_answers_let_me_tell_you_how_i_feel_something_else: "Something else",

  coa_answers_happy: "Happy",
  coa_answers_proud: "Proud",
  coa_answers_excited: "Excited",
  coa_answers_impressed: "Impressed",
  coa_answers_calm_and_relaxed: "Calm and relaxed",
  coa_answers_dont_know: "I don't know",

  summary_title                                       : "{NAME} on {DATE}"                          ,
  summary_fixed_it                                    : "Fixed It!"                                 ,
  summary_done                                        : "Back to History"                           ,
  summary_incident_notes_placeholder                  : "Enter teacher notes here..."               ,
  summary_incident_notes_edit                         : "Edit notes"                                ,
  summary_incident_notes_save                         : "Save notes"                                ,
  summary_switch_to_summary                           : "Show Summary"                              ,
  summary_switch_to_cert                              : "Show Certificate"                          ,
  history_done                                        : "Back to Profiles"                          ,
  history_intro : "This calendar shows the days and times that the I Fix It App was used with {PROFILE_NAME}. You can click on the time to see the end report of each event.",
  print                                               : "Print"                                     ,
  coa_title_blurb: "You did it!",
  coa_summary_title: "Well Done {NAME}!",
  coa_summary_date_awarded: "Awarded On {DATE}!",

  EXCEED_MAX_PROFILE_LIMIT                            : "You have reached the limit on the number of profiles you can create.",
  ERROR_OK                                            : "OK",

  amend_in_settings                                   : "You can amend your subscription in Account Settings.",
  faq_title                                           : "FAQ",
  faq_why_use_ifixit_title : "Why use I Fix It?",
  faq_why_use_ifixit_content :
  "I Fix It is an app that facilitates restorative conversations to Primary and Secondary learners with Additional Support Needs. I Fix It aims to enhance their emotional understanding and regulation when faced with difficult and overwhelming situations. It helps them become more self-aware, recognising when the information coming in through their senses or through internal thoughts is a problem. It is a visual tool devise to help develop communication skills, allowing learners to participate actively expressing their views and feelings which ensures their emotional health and wellbeing are taken care of. I Fix It presents them with a selection of coping strategies to support with independence and success. It enables them to take control of their learning which has a positive impact on their self-esteem, minimising secondary behaviour, anger, and frustration.",
  faq_how_to_deliver_ifixit_title : "How to deliver I Fix It",
  faq_how_to_deliver_ifixit_content :
  "Before starting the restorative conversation with the learner, we recommend you watch the video tutorial to familiarise with it. This restorative approach may be delivered by parents, teaching staff, and other professionals once the learner is able to engage in a calm way after an incident. It needs to be carried out in a quiet environment and individually. \nThe restorative conversation consists of 10 questions to be answered by the learner with the support of visuals, with a wide range of options to choose from for each question. The learner should be allowed time to look at the visuals and to process the information, completing the sections at his/her own pace, only being presented with prompts when/if necessary. It is fundamental not to have an influence on the learner’s response, use minimal language and allow any answer to be recorded. This will help with setting up the next steps in their learning and the progress will be recorded as they continue to use I Fix It. \nGoing through the process will allow the learner to explain the chain of events that led to the incident, but also self-reflect on what happened, enhance self-awareness, learn about their own and other people’s emotions, take responsibility for their own actions, and most importantly, learn to make better choices when faced with the same challenging situations. At the end there is a summary of the conversation that should be read slowly by the learner (or the adult if required), giving special emphasis to ‘What can I do if it happens again?’. The learner should be supported with their final decision in ‘What can I do to help make it better’ (e.g. making a card, tidying up, saying sorry…), and be praised for all the efforts.The app can monitor the history, the progress and achievements made by a learner, saving all the data on their individual profile.",
  faq_more_apps_title : "Will there be more apps?",
  faq_more_apps_content :
  "Yes. ‘I Fix It’ is only the first app on our ‘I Fix It Apps’ page. There will be more resources added to it to support learners with special needs, their teachers, and families.",
  faq_pricing_title : "Pricing",
  faq_pricing_content :
  "All I Fix It Apps users have access to 3 free profiles. Use of the app in Businesses, Faculties, Schools and other Educational institutions is limited to one account with 3 free profiles.\nAdditional profiles can be subscribed to at the cost of £8 per profile, billed annually.\nIf the total amount of profiles you require is 20, you can subscribe to 17 as you have 3 free profiles.\nWe unfortunately cannot offer refunds for cancelled subscriptions, but your account will be credited for the time remaining on the subscription. To regain access to your learner profiles and their history you will need to re-subscribe.\nSubscriptions can be amended at any point to lower or increase the number of learner profiles you subscribe to. Where applicable, credit will be applied to your account and automatically be put towards future subscriptions. For example, if you subscribe to 30 profiles and six months later you only need 20 you can amend your subscription under account settings. The cost of 10 profiles for the remainder of the subscription will be reserved as credit, reducing the cost of your next annual subscription.\nIf you require more profiles, you can amend your subscription under account settings. If you already subscribe to 20 learner profiles but six months into your subscription you require 30, a one-off charge will be applied to your account for the cost of the 10 extra profiles for 6 months. Your next annual subscription will be billed at the price of 30 profiles for a year.",
  faq_accounts_title : "Accounts and Billing",
  faq_accounts_content :
  "Schools and Educational Institutions can open an account and then subscribe to I Fix It Apps by requesting an invoice for the amount of profiles they require. Once that invoice has been processed the school will have access to the app and the profiles they have or want to create.\nEducational accounts can be amended to increase or decrease the amount of profiles required. The billing for amended accounts will adjusted on the next occurring payment. Please use the Account Settings to amend your subscription.\nParents or guardians can open an account and then subscribe to I Fix It Apps by annual subscription payable by visa/debit or Paypal.\nSubscriptions can be cancelled at any time. Cancelled subscriptions will continue to run until the end of term that has been paid for. To cancel a subscription please go to the Account Settings page and choose 'terminate subscription'.",
  faq_technical_assistance_title : "Technical Assistance",
  faq_tech_app_broken_title : "The app looks broken and not all viewable",
  faq_tech_app_broken_content :
  "I Fix It Apps is designed to be viewed on a tablet in landscape mode. If you are holding your tablet vertically, turn it sideways.",
  faq_tech_not_visible_title : "Parts of the app are not visible",
  faq_tech_not_visible_content :
  "Please check that your browser is not 'zoomed in'. You can check this in the settings menu of your internet browser.",
  faq_tech_work_offline_title : "The app doesn't work when I'm offline",
  faq_tech_work_offline_content :
  "I Fix It Apps is primarily designed to be accessed with an internet connection.\nThe app can be used (with exception to some older devices) when there is no internet connection but the end results will not be saved to the profile's history.",
  faq_tech_mobile_phone_title : "The app doesn't work on my mobile phone",
  faq_tech_mobile_phone_content :
  "I Fix It Apps is currently designed on to work on tablets and desktop PC monitors or other large screens.\nA mobile compatible version of the app will be developed at some point in the future.",
  faq_tech_download_profile_options_title : "Why are there download and upload profile options?",
  faq_tech_download_profile_options_content :
  "Being able to download a profile means you can back up your pupil or child's history in the event that you need to cancel your subscription for a period of time or if you need to transfer the profile to another educational or family account. Once you have downloaded the profile it is as simple as clicking on the upload profile button to add it to an account.",
  faq_footer:
    "Please look through the FAQ for answers to your queries before contacting us. However, if you still can't find the help you're looking for in the FAQ you can contact us and we will endeavour to respond as soon as possible.",

  settings_header_regular: 'Account Owner Settings',
  settings_header_incomplete: 'Please Complete Account Owner Settings',
  settings_invalid_address: 'Please enter your address',
  settings_invalid_name: 'Please enter your name',
  settings_invalid_institution: 'Please enter your institution name',
  settings_invalid_email: 'Your email is not valid',
  settings_fill_in_account_details:
    'Complete the account owner settings to continue',
  settings_full_name: 'Full Name',
  settings_subscriptions_free_tier_text:
    'You have {free_profile_count} free profiles. To purchase additional profiles, amend your subscription.',
  settings_subscriptions_paid_tier_text:
    'You have {free_profile_count} free profiles and {paid_profile_count} additional profiles, costing £{cost} per year. This will be renewed on {renewal_date}',
  settings_profiles_used: 'Profiles Used – {profiles}',
  settings_edit_payment_details: 'Edit Payment Details',
  settings_amend_subscription: 'Amend Subscription',
  settings_resume_subscription: 'Resume Subscription',
  settings_amend_message:
    "Enter below the number of profiles you'd like to have: ",
  settings_amend_message_not_enough_profiles:
    'You are currently using {profiles} profiles, so you cannot amend your subscription to a number lower than that. Delete some profiles to continue.',
  settings_cancel_subscription: 'Terminate Subscription',
  settings_cancel_subscription_now: 'Terminate Now',
  settings_cancel_subscription_confirmation:
    'Are you sure you want to terminate your subscription?',
  settings_cancel_at_period_end_message:
    "Your subscription will remain active until the end of the billing period, but it won't be automatically renewed.",
  settings_subscription: 'Subscription Information',
  settings_checkout_message:
    "You have access to {FREE_TIER_AMOUNT} profiles. You can subscribe to additional profiles at the cost of £{PAID_TIER_PRICE} per profile billed anually. For more information on pricing, please <a href='/faq'>visit our FAQ</a>.",
  settings_checkout_profiles_label:
    'Please enter in the box below how many additional profiles you want.',
  settings_checkout_total: 'Total',
  settings_checkout_title: 'Checkout',
  settings_checkout_free_until: 'Free until {date}',
  settings_checkout_cost: 'Total',
  settings_checkout_unknown_error: 'An error has occurred. Please try again.',
  settings_checkout_item: 'Item',
  settings_checkout_quantity: 'Quantity',
  settings_checkout_amount: 'Amount',
  settings_checkout_debit: 'Account Debit',
  settings_checkout_credit: 'Account Credit',
  settings_checkout_balance_message:
    " After this transaction you'll have {amount} to spend on your next purchase.",
  settings_purchase_subscriptions: 'Purchase additional subscriptions',
  setting_pending_text: 'Your subscription is pending',
  setting_pending_message:
    'We are waiting for your payment to reach us, your subscription will start just after that.',
  settings_pending_profiles: 'Subscribed for {profiles} profiles.',
  settings_pending_unhandled_case:
    'Your subscription is incomplete! Please contact support.',
  settings_pending_canceling: 'Your subscription is being terminated.',
  settings_pending_processing:
    'We are processing your payment. Do not close this window.',
  settings_confirmation_required: 'Your payment requires confirmation.',
  settings_confirmation_required_help:
    'Please click the confirm payment button and complete the steps in the pop-up window.',
  settings_payment_required: 'Payment Failed',
  settings_payment_required_help:
    'We are sorry, there was an error processing your payment. Please try again with a different payment method to continue with the subscription.',
  settings_payment_overdue:
    'Your payment is overdue, make sure to pay as soon as possible or your subscription will be canceled. Please contact support if you are not sure how to proceed.',
  settings_payment_processing:
    'Hold tight, your payment is being processed. It might take several days for a BACS Debit payments to be processed.',
  settings_payment_canceled: 'Your payment has been canceled.',
  settings_capture_required:
    'Your payment requires capture. Please contact support.',
  settings_confirm_payment: 'Confirm Payment',
  settings_payment_methods_title: 'Payment Methods',
  settings_setup_payment_method: 'Setup Payment Method',
  settings_add_payment_method: 'Add Payment Method',
  settings_payment_methods_done: 'Done',
  settings_no_payment_method:
    "You need to add a payment method in order to purchase additional profiles.",
  settings_no_payment_methods_info:
    "You don't have any payment methods set up. Press the button below to add one.",
  settings_unknown_payment_method: 'Unknown Payment Method',
  settings_edit: 'edit',
  settings_go_to_settings: 'Go To Accounts',
  settings_go_to_profiles: 'Go To Profiles',
  settings_delete_account_google_confirmation:
    "Once you confirm account deletion you'll need to reauthenticate with Google to complete the operation.",
  settings_delete_account_email_confirmation:
    'You need to enter your password to delete your account.',
  settings_delete_account_confirmation:
    'Are you sure you want to delete your account? This will cancel your subscription, delete all profiles you have created and log you out, and cannot be undone.',
  settings_delete_account_now: 'Yes, delete it',

  // Stripe error codes https://stripe.com/docs/error-codes
  unknown_error:
    "An unknown error with code '{code}' has occurred. Try again or contact support.",
  card_declined: 'Your card was declined.',
  insufficient_funds: 'Your payment method has insufficient funds.',
  processing_error: 'Something went wrong, please try again.',
  expired_card: 'Your card is expired.',
  payment_intent_action_required:
    'Your payment method requires an additional action.',
  payment_intent_authentication_failure:
    'The payment was not authorized. Please try again with a different payment method.',
  payment_intent_incompatible_payment_method:
    'The provided payment method is not compatible with our system.',
  no_payment_methods:
    'Your account does not have a payment method. Please add one to continue with the subscription.',

  address_form_title_request: 'Please enter your billing address',
  address_form_title: 'Billing Address',
  address_form_line1: 'Line 1',
  address_form_line2: 'Line 2',
  address_form_city: 'City',
  address_form_country: 'Country',
  address_form_postal_code: 'Postal Code',

  text_answers_placeholder: 'Anything else I want to share I can add here...',
  textfield_char_limit: '{REMAINING} characters left',

  mobiles_not_supported: 'Mobile devices are not currently supported',
  mobile_access_guide: 'To access the I Fix It App, login on your desktop, laptop or tablet',

  twitter: 'Tweet',
  facebook: 'Find us on Facebook',
}

export default LocalisationEnGb

import React from 'react';
import { Link } from 'react-router-dom'

class ElementRenderer {
    renderLink(className, pathname, data, text, enabled=true){
        return <Link to={{pathname, data}}>
            <button className={className} disabled={!enabled}>{text}</button>
        </Link>
    }

    renderImageButton(onClick, iconUrl, imageClassName, altText){
        return <button className='img-button' onClick={onClick}>
            <img className={imageClassName} src={iconUrl} alt={altText}/>
        </button>
    }
}

export default new ElementRenderer();
import React, { useState } from "react";
import { useString } from "providers/LocalisationProvider";
import { unsubscribe } from "services/payments";
import { PaymentStatus } from "./PaymentStatus";

export const WhilePending = ({ user }) => {
  const [isCanceling, setIsCanceling] = useState(false);

  const onCancel = async () => {
    setIsCanceling(true);
    await unsubscribe();
  };

  const profiles = user.paidProfiles;

  return (
    <>
      <div className="text-center title">
        {useString("setting_pending_text")}
      </div>
      <p>{useString("settings_pending_profiles", { profiles })}</p>
      <PaymentStatus
        subscription={user.subscription}
        isCanceling={isCanceling}
      />
      <button
        className="button danger full-width outlined margin-y"
        disabled={isCanceling}
        onClick={onCancel}
      >
        {useString("settings_cancel_subscription")}
      </button>
    </>
  );
};

import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print';
import Incident from 'models/SessionReport'
import { SummaryPresentation } from './SummaryPresentation'
import { useLocalisationService, useString } from 'providers/LocalisationProvider';
import { useCachedDoc } from 'hooks/useCache';
import { ProfileKeys, ReportKeys, ReportTypes } from 'fixit-shared/FirestoreKeys';
import FadeLoader from "react-spinners/FadeLoader";
import { FixitGreen } from "fixit-shared/Consts";

export const HistoricalIncidentSummary = props => {
    const {telemetryService} = props;
    const localisationService = useLocalisationService();
    const printComponentRef = useRef();
  
    const [pupilProfile, setPupilProfile] = useState(undefined); 
    const [incident, setIncident] = useState(undefined);
    const [incidentSummary, setIncidentSummary] = useState(undefined);
    const [editNotes, setEditNotes] = useState(false);

    useEffect(() => {

      const defaultTeacherProfile = {
        raceIcons: "NotSpecified",
        genderIcons: "NonGendered",
      }
      
      if(!pupilProfile || !incidentSummary) return;
  
      const historicalIncident = Incident.createHistorical(pupilProfile, defaultTeacherProfile, incidentSummary, localisationService);
      setIncident(historicalIncident);
      const openedTime = Date.now();

      const telemetryProps = {
        profile_uuid: historicalIncident.profile().id,
        incident_id: historicalIncident.id(),
        // school_id,
      };
      telemetryService.reportEvent('history.opened_incident', telemetryProps);

      return () => {
        const telemetryProps = {
          profile_uuid: historicalIncident.profile().id,
          incident_id: historicalIncident.id(),
          time_on_page: Date.now() - openedTime,
          // school_id,
        };
        telemetryService.reportEvent('history.closed_incident', telemetryProps);
      }
    }, [pupilProfile, incidentSummary, telemetryService, localisationService]);
  
    const handlePrint = useReactToPrint({
      onBeforePrint: () => _sendPrintTelemetry,
      content: () => printComponentRef.current,
    });

    useCachedDoc(
      `${ProfileKeys.tableName}/${props.match.params.profileId}`, 
      setPupilProfile, 
      ProfileKeys.decompress
    );
  
    useCachedDoc(
      `${ProfileKeys.tableName}/${props.match.params.profileId}/${ReportTypes.Incident}/${props.match.params.incidentId}`,
      setIncidentSummary, 
      ReportKeys.decompress
    );

    const _finishButton = () => {
      const userHistoryUrl=`/profile/${incident.profile().id}`
      return ( 
        <Link to={userHistoryUrl} className='summary-done'>
          <button className='primary-button summary-button summary-text rounded flexbox'>{summaryDoneString}</button>
        </Link>
      )
    }
  
    const loadingSpinner = () => {
      return <div className='spinner flexbox'>
          <FadeLoader
              color={FixitGreen}
              loading={!pupilProfile || !incidentSummary}
          />
      </div>
    }

    const _sendPrintTelemetry = () => {
      if(incident.isHistorical()){
        const telemetryProps = {
          profile_uuid: incident.profile().id,
          incident_id: incident.id(),
          language: localisationService.getCurrentLanguage(),
          // school_id,
        };
        telemetryService.reportEvent('history.print_incident', telemetryProps);
      }
    }

    const _toggleNotesMode = () => {
      if(editNotes){
        incident.saveUpdatedNotes(ReportTypes.Incident);
      }
      setEditNotes(!editNotes);      
    }

    const printString = useString('print');
    const summaryDoneString = useString('summary_done')
    const editNotesString = useString(editNotes ? 'summary_incident_notes_save': 'summary_incident_notes_edit');

    if(!incident) return loadingSpinner();

    return <div className = 'fixit'> 
    <div className='noselect'>    
        <SummaryPresentation
          type={ReportTypes.Incident}
          ref={printComponentRef}
          incident = {incident}
          telemetryService = {telemetryService}
          editNotes = {editNotes}
        />
        <div className='summary-options centered flexbox'> 
          {_finishButton()}
          <button onClick={handlePrint} className='secondary-button summary-button summary-text rounded flexbox'>{printString}</button>
          <button onClick={_toggleNotesMode} className='secondary-button summary-button summary-text rounded flexbox'>{editNotesString}</button>
        </div>
      </div>
    </div>
  }
  
import React, { useEffect, useState } from "react";
import { TextField } from "./TextField";
import { EditableForm } from "./EditableForm";
import { useString } from "providers/LocalisationProvider";
import { isNameValid } from "helpers/is-account-valid";

export const NameForm = ({ onChange, defaultName }) => {
  const [name, setName] = useState(defaultName);
  const [isValid, setIsValid] = useState(true);
  const title = useString("settings_full_name");
  const enterNameString = useString("settings_invalid_name");

  const _onChange = (value) => {
    setName(value);
    setIsValid(isNameValid(value));
  };

  const onCancel = () => {
    setName(defaultName);
  };

  const onSave = () => {
    onChange && onChange(name);
  };

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  const style = name ? '' : 'text-danger';

  return (
    <EditableForm
      titleCollapsed={name || enterNameString}
      onSave={onSave}
      onCancel={onCancel}
      isValid={isValid}
      style={style}
      whileEditing={
        <TextField
          name="name"
          title={title}
          placeholder={title}
          defaultValue={name}
          onChange={_onChange}
        />
      }
    />
  );
};

import { isAddressValid } from "./is-address-valid";

export const isAccountValid = (user) => {
  if (!user) return { valid: false, error: "settings_invalid_account" };

  if (!isNameValid(user.displayName)) {
    return { valid: false };
  }

  if (!isEmailValid(user.email)) {
    return { valid: false };
  }

  if (!isAddressValid(user.address)) {
    return { valid: false };
  }

  return { valid: true, error: null };
};

export const isNameValid = (name) => {
  // TODO: properly validate field
  return !!name;
};

export const isEmailValid = (email) => {
  // stolen from https://stackoverflow.com/a/46181
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

import React, { useState, useEffect } from 'react'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import { Homepage } from './Homepage'
import { Profiles } from './Profiles'
import Faqs from './Faqs'
import About from './About'
import Technical from './TechnicalAssistance'
import { ProfileHistory } from './ProfileHistory'
import { FixitApp } from './FixitApp'
import { CertificateOfAchievementApp } from './CertificateOfAchievementApp'
import { PostIncidentSummary } from './Summary/PostIncidentSummary'
import { HistoricalIncidentSummary } from './Summary/HistoricalIncidentSummary'
import { HistoricalAwardSummary } from './Summary/HistoricalAwardSummary'
import TermsOfService from './TOS'
import Privacy from './Privacy'
import { UserSettings } from './UserSettings'
import {
  useLocalisationService,
  DefaultLanguage
} from 'providers/LocalisationProvider'
import FadeLoader from 'react-spinners/FadeLoader'
import { FixitGreen } from 'fixit-shared/Consts'
import { deleteCache } from 'hooks/useCache'
import { CertificateOfAchievementSummary } from './Summary/CertificateOfAchievementSummary'

const HOMEPAGE = '/'
const ABOUT = '/about'
const FAQ = '/faq'
const TOS = '/tos'
const PRIVACY = '/privacy'
const TECHNICAL = '/technical-assistance'

export const App = ({
  telemetryService,
  authenticationService,
  cloudFunctions
}) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [localisationService] = useState(useLocalisationService())

  useEffect(() => {
    const SupportedLanguages = ['en-GB']

    const _validateLanguage = language => {
      if (SupportedLanguages.includes(language)) {
        return language
      }

      return DefaultLanguage
    }

    const url = new URL(window.location)
    const queryParams = new URLSearchParams(url.search)
    const LanguageKey = 'l'
    const language = queryParams.has(LanguageKey)
      ? _validateLanguage(queryParams.get(LanguageKey))
      : DefaultLanguage
    localisationService.setLanguage(language)

    const _onNewUser = user_uuid => {
      const params = {
        user_uuid,
        language: localisationService.getCurrentLanguage()
        // school_id
      }
      telemetryService.reportEvent('account.created_user', params)
    }
    authenticationService.addNewUserCallback(_onNewUser)

    return () => {
      authenticationService.removeNewUserCallback(_onNewUser)
    }
  }, [authenticationService, localisationService, telemetryService])

  useEffect(() => {
    return deleteCache
  }, [])

  useEffect(() => {
    const onLoggedIn = () => {
      setIsAuthenticated(true)
      setIsAuthenticating(false)
    }

    const onLoggedOut = () => {
      deleteCache()
      setIsAuthenticated(false)
      setIsAuthenticating(false)
    }

    authenticationService.addAuthCallbacks(onLoggedIn, onLoggedOut)

    return () => authenticationService.removeAuthCallbacks(onLoggedIn, onLoggedOut)

  }, [authenticationService])

  let _currentPage = '/'
  let _lastPageTransition = Date.now()

  const _renderHomepage = props => {
    _updatePage(HOMEPAGE)
    return (
      <Homepage
        {...props}
        authService={authenticationService}
        telemetryService={telemetryService}
      />
    )
  }

  const _authenticatedComponent = (path, component) => {
    return (
      <AuthenticatedRoute
        updatePage={_updatePage}
        path={path}
        authService={authenticationService}
        telemetryService={telemetryService}
        cloudFunctions={cloudFunctions}
        component={component}
      />
    )
  }

  const _renderAbout = () => {
    _updatePage(ABOUT)
    return (
      <About
        authService={authenticationService}
        telemetryService={telemetryService}
      />
    )
  }

  const _renderTOS = () => {
    _updatePage(TOS)
    return <TermsOfService telemetryService={telemetryService} />
  }

  const _renderPrivacy = () => {
    _updatePage(PRIVACY)
    return <Privacy telemetryService={telemetryService} />
  }

  const _renderFaqs = () => {
    _updatePage(FAQ)
    return (
      <Faqs
        authService={authenticationService}
        telemetryService={telemetryService}
      />
    )
  }

  const _renderTechnical = () => {
    _updatePage(TECHNICAL)
    return (
      <Technical
        authService={authenticationService}
        telemetryService={telemetryService}
      />
    )
  }

  const AuthenticatedRoute = ({
    component: Component,
    authService,
    ...rest
  }) => {
    return (
      <Route
        {...rest}
        render={props => {
          rest.updatePage(rest.path)
          return isAuthenticated ? (
            <Component authService={authService} {...rest} {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }}
      />
    )
  }

  const _updatePage = newPage => {
    if (newPage === _currentPage) {
      return
    }
    const telemetryProps = {
      from_page: _currentPage,
      to_page: newPage,
      time_on_page: Date.now() - _lastPageTransition
      // button_id
    }

    telemetryService.reportEvent('navigation.change_page', telemetryProps)
    _currentPage = newPage
    _lastPageTransition = Date.now()
  }

  if (isAuthenticating) {
    return (
      <div className='spinner flexbox'>
        <FadeLoader color={FixitGreen} loading={isAuthenticating} />
      </div>
    )
  }

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path={HOMEPAGE} render={_renderHomepage} />
        {_authenticatedComponent('/profiles', Profiles)}
        {_authenticatedComponent('/profile/:profileId/incident/:incidentId', HistoricalIncidentSummary)}
        {_authenticatedComponent('/profile/:profileId/award/:incidentId', HistoricalAwardSummary)}
        {_authenticatedComponent('/profile/:profileId', ProfileHistory)}
        {_authenticatedComponent('/fixit', FixitApp)}
        {_authenticatedComponent('/achievement', CertificateOfAchievementApp)}
        {_authenticatedComponent('/summary', PostIncidentSummary)}
        {_authenticatedComponent('/certificate_of_achivement', CertificateOfAchievementSummary)}
        {_authenticatedComponent('/settings', UserSettings)}
        <Route path={ABOUT} render={_renderAbout} />
        <Route path={FAQ} render={_renderFaqs} />
        <Route path={TECHNICAL} render={_renderTechnical} />
        <Route path={TOS} render={_renderTOS} />
        <Route path={PRIVACY} render={_renderPrivacy} />
      </Switch>
    </Router>
  )
}

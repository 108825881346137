import React from 'react'
import Modal from 'react-modal'
import { useModalScrollLock } from 'hooks/useModalScrollLock'
import 'css/video-modal.css'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .8)'
  },
  content: {
    inset: '30px',
    backgroundColor: 'transparent',
    border: 'none',
  }
}


export default function VideoModal({ isOpen, onClose }) {
  useModalScrollLock(isOpen)

  function closeModal() {
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
      <div className='close-video-container'>
        <button className='close-video' onClick={closeModal}><img src='/homepage/close_video_btn.svg' alt='close video' /></button>
      </div>
      <div className='video-container'>
        <iframe
          title='homepage-video'
          className='homepage-video'
          src='https://www.youtube.com/embed/otfLZpqoC7c'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  )
}

import { useEffect, useState } from "react";
import firebase from "firebase/app";
import { isAccountValid } from "helpers/is-account-valid";
import { UserKeys } from 'fixit-shared/FirestoreKeys';
import { useCachedDoc } from 'hooks/useCache';

const useUserSnapshot = (uid) => {
  const [user, setUser] = useState(undefined); 
  const userPath = uid ? `${UserKeys.tableName}/${uid}` : undefined;
  useCachedDoc(
    userPath, 
    setUser, 
    UserKeys.decompress
    );

    return user;
}

export const getUserRef = (uid) => {
  return firebase.firestore().collection("user").doc(uid);
};

export const deleteAccount = async (password) => {
  const user = firebase.auth().currentUser;
  let reauth = undefined;

  if(user.providerData[0].providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID){
    var provider = new firebase.auth.GoogleAuthProvider();
    reauth = user.reauthenticateWithPopup(provider);
  }
  else if(user.providerData[0].providerId === firebase.auth.EmailAuthProvider.PROVIDER_ID){
    const credential = firebase.auth.EmailAuthProvider.credential(
        user.email, 
        password
    );
    reauth = user.reauthenticateWithCredential(credential);
  }

  if(reauth){
    return reauth.then(() => {
      return firebase.auth().currentUser.delete();
    })
    .then(firebase.auth().signOut)
    .then(() => {
      return {success: true}
    }).catch(function(error) {
      return {success: false, error: error.message};
    });
  }

  return {success: false, error: 'Could not recognise identity provider. Contact support@ifixitapps.com to request deletion manually.'};
}

export const useCurrentUserSnapshot = () => {
  const [uid, setUid] = useState();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUid(user ? user.uid : null);
    });

    return () => unsubscribe();
  }, []);

  return useUserSnapshot(uid);
};

export const useIsCurrentUserValid = () => {
  const user = useCurrentUserSnapshot();
  return user && isAccountValid(user).valid;
};

export const useLoggedInState = (authService, onSignInCallback, onSignOutCallback) => {
  const isUserValid = useIsCurrentUserValid();
  const [isSignedIn, setIsSignedIn] = useState(isUserValid);  
  
  useEffect(() => {

    setIsSignedIn(isUserValid)

    const _onSignIn = (_user) => {
        setIsSignedIn(true);
        if(onSignInCallback) onSignInCallback();
    }

    const _onSignOut = () => {
        setIsSignedIn(false);
        if(onSignOutCallback) onSignOutCallback();
    }

    authService.addAuthCallbacks(_onSignIn, _onSignOut);
    return () => {
        authService.removeAuthCallbacks(_onSignIn, _onSignOut);
    }
  }, [authService, isUserValid, onSignInCallback, onSignOutCallback]);

  return isSignedIn;
}
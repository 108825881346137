import firebase from 'firebase/app';
import 'firebase/analytics'
import TelemetryService from '../TelemetryService';

export default class FirebaseTelemetryService extends TelemetryService {

    constructor(userProperties)
    {    
        super();

        this._analytics = firebase.analytics();
        this._analytics.setUserProperties(userProperties);
        this._enabled = true;
    }

    reportEvent(event, properties) {
        if(this._enabled){
            this._analytics.logEvent(event, properties);
        }
    }
}
import { Races, Genders } from 'fixit-shared/Consts';

export default class IconService {
    static _getIconVariant(race, gender, iconBasePath){
        let iconPath = iconBasePath.replace("{r}", race).replace("{g}", gender);
        return `/icons/${iconPath}`;
    }  

    static getIconPath(profile, basePath){
        return this._getIconVariant(Races[profile.raceIcons], Genders[profile.genderIcons], basePath);
    }
}
import React from 'react';
import { ImageContainer } from 'components/ImageContainer'

export const QuestionSummaryMultipleChoiceWithText = (props) => {

  const {question, onClick} = props;

  const borderStyle = {borderColor: question.colour()};

  const _renderChoices = () => {
    const choices = question.savedChoices();
    var choiceIcons = [];
    
    for (let i = 0; i < choices.length; i++) {
      const choice = choices[i];
      choiceIcons.push(_renderChoiceSummary(choice, borderStyle, question.colour()));
    }
    return choiceIcons;
  }

  const _renderChoiceSummary = (choice, style, spinnerColour) => {
    return (
    <div className="summary-answer"
    style={style}
    key={choice.index()}>
      <ImageContainer 
        className={"summary-icon summary-background-colour"}
        src={choice.icon()} 
        alt={choice.title()} 
        spinnerColour={spinnerColour}
        size={10}/>
      <div className="summary-answer-title flexbox">
        <span>{choice.title()}</span>
      </div>
    </div>);
  }

  const _renderTextAnswer = () => {
    const answerText = question.getTextAnswer();
    if (answerText.length === 0) return <></>;
    
    return <div className="summary-text-answer">{answerText}</div>;
  }

  const choices = _renderChoices();

  return (
    <div className="summary-print-border">
      <div className="summary-item summary-background-colour" onClick={onClick} style={borderStyle}>
        <div className="summary-question summary-background-colour flexbox">
          <p>{question.title()}</p>
        </div>
        <div className="summary-answers-container summary-background-colour">
          {choices}
          {_renderTextAnswer()}
        </div>
      </div>
    </div>
  );

}

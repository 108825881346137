const path = "fixit/answers/what_happened_answers/";

export const WhatHappenedAnswers = [
  {titleKey: "fixit_answers_what_happened_it_was_noisy"               , icon: path+"it_was_noisy_{r}{g}.svg"                        , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_i_needed_help"              , icon: path+"i_needed_help_{r}x.svg"                         , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_i_wasnt_feeling_well"       , icon: path+"i_wasnt_feeling_well_{r}{g}.svg"                , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_i_wanted_to_choose"         , icon: path+"i_wanted_to_choose_{r}x.svg"                    , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_i_wanted_to_be_alone"       , icon: path+"i_wanted_to_be_alone_{r}{g}.svg"                , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_nobody_was_playing_with_me" , icon: path+"nobody_was_playing_with_me_{r}{g}.svg"          , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_i_lost_a_game"              , icon: path+"i_lost_a_game_{r}{g}.svg"                       , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_i_wanted_to_go_outside"     , icon: path+"i_wanted_to_go_outside.svg"                     , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_i_wanted_to_go_inside"      , icon: path+"i_wanted_to_go_inside.svg"                      , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_i_was_hot"                  , icon: path+"i_was_hot_{r}{g}.svg"                           , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_not_fair"                   , icon: path+"something_happened_that_wasnt_fair_{r}{g}.svg"  , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_i_didnt_want_to_work"       , icon: path+"i_didnt_want_to_work_{r}{g}.svg"                , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_someone_else_kicked_me"     , icon: path+"someone_else_kicked_me_{r}{g}.svg"              , isSelectable: false  },
  {titleKey: "fixit_answers_what_happened_i_wanted_a_break"           , icon: path+"i_wanted_a_break_{r}{g}.svg"                    , isSelectable: false  },
  {titleKey: "fixit_answers_what_happened_someone_else_hurt_me"       , icon: path+"someone_else_hurt_me_{r}{g}.svg"                , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_someone_else_bit_me"        , icon: path+"someone_else_bit_me_{r}{g}.svg"                 , isSelectable: false  },
  {titleKey: "fixit_answers_what_happened_someone_else_hit_me"        , icon: path+"someone_else_hit_me_{r}{g}.svg"                 , isSelectable: false  },
  {titleKey: "fixit_answers_what_happened_i_cant_remember"            , icon: path+"i_cant_remember_{r}{g}.svg"                     , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_space_invaded"              , icon: path+"my_space_was_invaded_{r}{g}.svg"                , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_broke_rules"                , icon: path+"someone_broke_the_rules.svg"                    , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_something_unsafe"           , icon: path+"something_unsafe.svg"                           , isSelectable: true   },
  {titleKey: "fixit_answers_what_happened_something_else"             , icon: path+"something_else_{r}{g}.svg"                      , isSelectable: true   }
];

export default WhatHappenedAnswers;
const path = "fixit/answers/how_did_it_feel_in_my_body_answers/";

export const HowDidItFeelInMyBodyAnswers = [
  {titleKey: "fixit_answers_how_did_it_feel_sore_tummy"  , icon: path+"sore_tummy_{r}{g}.svg"          , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_sore_head"   , icon: path+"sore_head_{r}{g}.svg"           , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_it_hurt"     , icon: path+"it_hurt_{r}{g}.svg"             , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_sore_ear"    , icon: path+"sore_ear_{r}x.svg"              , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_shaky"       , icon: path+"shaky_{r}{g}.svg"               , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_sore_tooth"  , icon: path+"sore_tooth.svg"                 , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_sore_body"   , icon: path+"sore_body_{r}{g}.svg"           , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_sore_eye"    , icon: path+"sore_eye.svg"                   , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_sore_throat" , icon: path+"sore_throat_{r}{g}.svg"         , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_i_dont_know" , icon: path+"i_dont_know_{r}{g}.svg"         , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_sore_leg"    , icon: path+"sore_leg_{r}{g}.svg"            , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_fine"        , icon: path+"it_felt_fine_{r}{g}.svg"        , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_hungry"      , icon: path+"hungry.svg"                     , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_feel_thirsty"     , icon: path+"thirsty.svg"                    , isSelectable: true   },
  {titleKey: "fixit_answers_how_did_it_something_else"   , icon: path+"something_else_{r}{g}.svg"      , isSelectable: true   }
];

export default HowDidItFeelInMyBodyAnswers;
import React from "react";
import { useString } from "providers/LocalisationProvider";
import {OptionsModal} from "./OptionsModal";

export const ConfirmCancelSubscriptionModal = ({
  isOpen,
  onCancel,
  onClose,
}) => {
  return (
    <OptionsModal
      isOpen={isOpen}
      content={useString("settings_cancel_subscription_confirmation")}
      options={[
        {
          text: useString("cancel"),
          onClick: onClose,
          classNames: ["secondary"],
        },
        {
          text: useString("settings_cancel_subscription_now"),
          onClick: onCancel,
          classNames: ["danger"],
        },
      ]}
      useAltButtons={true}
      onClose={onClose}
    />
  );
};

import { useStripe } from "@stripe/react-stripe-js";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { FixitGreen } from "fixit-shared/Consts";
import {
  useLocalisationService,
  useString,
} from "providers/LocalisationProvider";

export const PaymentStatus = ({
  subscription,
  isCanceling,
  paymentMethodsButtonPresent,
}) => {
  const l = useLocalisationService();
  const payment = subscription.payment;

  if (isCanceling) {
    return <Loading message={l.getString("settings_pending_canceling")} />;
  }

  if (!payment) {
    return null;
  }

  if (payment.pending) {
    return <Loading message={l.getString("setting_pending_message")} />;
  }

  switch (payment.status) {
    case "requires_action":
      return <StatusRequiresAction payment={payment} />;
    case "requires_confirmation": // this case only happens with BACS payments, which can't actually be confirmed after set up. The only thing to do is to setup the payment method again. Cards go in the requires_action state, which is handled gracefully.
    case "requires_payment_method":
      return (
        <StatusRequiresPayment
          payment={payment}
          paymentMethodsButtonPresent={paymentMethodsButtonPresent}
        />
      );
    case "requires_capture":
      // This should never happen, and if it does, it needs human intervention.
      return <b>{l.getString("settings_capture_required")}</b>;
    case "processing":
      return <Loading message={l.getString("settings_payment_processing")} />;
    case "canceled":
      return l.getString("settings_payment_canceled");
    case "succeeded":
      return null;
    default:
      return <b>{l.getString("settings_pending_unhandled_case")}</b>;
  }
};

const StatusRequiresAction = ({ payment }) => {
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  const l = useLocalisationService();

  const onConfirmPayment = async () => {
    setIsLoading(true);
    const { error } = await stripe.confirmCardPayment(payment.client_secret);

    if (error) {
      setIsLoading(false);
      setConfirmationError(true);
      return;
    }
  };

  if (isLoading) {
    return <Loading message={l.getString("settings_pending_processing")} />;
  }

  return (
    <>
      <PaymentPastDue payment={payment} />
      <p className="text-bold">
        {l.getString("settings_confirmation_required")}
      </p>
      <p>{l.getString("settings_confirmation_required_help")}</p>
      <button
        className="button primary full-width margin-y"
        onClick={onConfirmPayment}
        disabled={confirmationError}
      >
        {l.getString("settings_confirm_payment")}
      </button>
    </>
  );
};

const StatusRequiresPayment = ({ payment, paymentMethodsButtonPresent }) => {
  const l = useLocalisationService();
  let errorMessage = null;

  if (payment.last_error) {
    errorMessage =
      l.getStringOrNull(payment.last_error) ||
      l.getString("unknown_error", { code: payment.last_error });
  }

  return (
    <>
      <PaymentPastDue payment={payment} />
      <div className="text-danger">
        <p className="text-bold">{useString("settings_payment_required")}</p>
        <p>{errorMessage}</p>
      </div>

      <p>{useString("settings_payment_required_help")}</p>

      {!paymentMethodsButtonPresent && (
        <Link
          to="/settings/payment-methods"
          className="button accent full-width margin-y"
        >
          {l.getString("settings_edit_payment_details")}
        </Link>
      )}
    </>
  );
};

const PaymentPastDue = ({ payment }) => {
  const message = useString("settings_payment_overdue");
  if (!payment.past_due) return null;

  return <p className="text-danger text-bold">{message}</p>;
};

const Loading = ({ message }) => {
  return (
    <>
      <p>{message}</p>
      <FadeLoader color={FixitGreen} loading={true} css="margin: 0 auto"/>
    </>
  );
};

import React from 'react';
import { useLocalisationService, useString } from 'providers/LocalisationProvider';
import { SummaryNotes } from "./SummaryNotes";
import IconService from 'services/IconService';

export const CertificateOfAchievement = React.forwardRef((props, ref) => {

  const {editNotes, certificate} = props;
  const localisationService = useLocalisationService();

  const date = new Intl.DateTimeFormat(localisationService.getLocales(), { weekday: 'long', day: 'numeric', month:'long', year:'numeric', hour:'numeric', minute:'numeric' }).format(certificate.date);

  const title = useString('coa_summary_title', {NAME: certificate.profile().displayName});
  const awardedOn = useString('coa_summary_date_awarded', {DATE: date});

  const iconUrl = IconService.getIconPath(certificate.profile(), 'coa/profile_achievement_{r}{g}.svg#main')

  return (
    <div className = 'certificate-of-achievement' ref={ref}> 
      <div className='noselect'>    
        <div className='summary-text summary-background-colour'>
          <span>{title}</span>
        </div>
        <svg className="summary-coa-award-background noselect">
            <use xlinkHref={'/icons/coa/stars_and_ribbon.svg#main'}/>              
            <g transform="matrix(0.4,0,0,0.4,240,65)">
              <use xlinkHref={iconUrl}/>
            </g>
        </svg>
        <div className='summary-text summary-background-colour'>
          <span>{awardedOn}</span>
        </div>
        <SummaryNotes incident = {certificate} editingEnabled = {editNotes}/>
      </div>
    </div>
    )
});
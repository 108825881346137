const path = "fixit/answers/who_did_i_hurt_answers/";

export const WhoDidIHurtAnswers = [
  {titleKey: "fixit_answers_who_i_hurt_my_teacher"              , icon: path+"my_teacher_ww.svg"                  , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_my_teacher_2"            , icon: path+"my_teacher_wm.svg"                  , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_a_teacher"               , icon: path+"a_teacher.svg"                      , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_my_brother"              , icon: path+"my_brother_{r}x.svg"                , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_my_sister"               , icon: path+"my_sister_{r}x.svg"                 , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_a_friend"                , icon: path+"a_friend_{r}{g}.svg"                , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_my_dad"                  , icon: path+"my_dad_{r}{g}.svg"                  , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_my_mum"                  , icon: path+"my_mum_{r}{g}.svg"                  , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_i_cant_remember"         , icon: path+"i_cant_remember_{r}{g}.svg"         , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_nobody"                  , icon: path+"nobody_{r}x.svg"                    , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_myself"                  , icon: path+"myself_{r}{g}.svg"                  , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_a_classmate"             , icon: path+"a_classmate.svg"                    , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_i_didnt_know_their_name" , icon: path+"i_dont_know_their_name_{r}{g}.svg"  , isSelectable: true   },
  {titleKey: "fixit_answers_who_i_hurt_some_children"           , icon: path+"some_children.svg"                  , isSelectable: true   }
];

export default WhoDidIHurtAnswers;
import React, {useState} from 'react';
import {BeatLoader} from "react-spinners";

export const ImageContainer = (props) => {

    const {className, src, alt, spinnerColour, size} = props;
    const [loading, setLoading] = useState(true);

    const onLoaded = () => {
        setLoading(false)
    }

    return <React.Fragment>
        <div className={className} style={loading?{}:{display: "none"}}>
            <div className='spinner'>
                <BeatLoader
                    color={spinnerColour}
                    size={size}
                    loading={loading}
                />
            </div>
        </div>
        <div style={loading?{display: "none"}:{}}>
            <img className={className} src={src} alt={alt}
            onLoad={onLoaded}/>
        </div>
    </React.Fragment>
}
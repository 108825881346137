import IconService from "services/IconService";

export default class MultipleChoiceAnswer {

    constructor(profile, index, answerInfo, localisationService) {
        this._index = index;
        this._answerInfo = answerInfo;
        this._localisationService = localisationService;
        this._iconUrl = IconService.getIconPath(profile, answerInfo.icon);
    }

    id(){
        return this._answerInfo.titleKey;
    }

    title(){
        return this._localisationService.getString(this._answerInfo.titleKey);
    }

    icon() {
        return this._iconUrl;
    }

    index() {
        return this._index;
    }

    toJson() {
        return {
            titleKey: this._answerInfo.titleKey,
            icon: this._answerInfo.icon
        }
    }

    isSelectable(){
        return this._answerInfo.isSelectable;
    }
}
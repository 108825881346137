import { useEffect } from "react";
import firebase from "firebase/app";

const cache = {};

const subscribeToDocument = (path, listener) => {
    const ref = firebase.firestore().doc(path);

    if (!ref) return;

    const unsubscribe = ref.onSnapshot((docRef) => {
        updateDocument(path, docRef);
    }, (error) => {
        console.error(`Cache document update error on ${path}:\n${error}`)
        updateDocument(path, undefined);
    });

    cache[path] = {
        value: undefined,
        listeners: [listener],
        unsubscribe: unsubscribe
    };
}

const updateDocument = (key, newDocRef) => {
    cache[key].value = newDocRef;
    for (const listener in cache[key].listeners){
        const data = extractData(newDocRef, cache[key].listeners[listener].parser);
        cache[key].listeners[listener].callback(data);
    }
}

const subscribeToCollection = (key, query, listener) => {

    const unsubscribe = query.onSnapshot((querySnapshot) => {
        updateCollection(key, querySnapshot);
    }, (error) => {
        console.error(`Cache collection update error on ${key}:\n${error}`)
        updateCollection(key, undefined);
    });

    cache[key] = {
        value: undefined,
        listeners: [listener],
        unsubscribe
    };
}

const updateCollection = (key, querySnapshot) => {
    cache[key].value = querySnapshot;
    for (const listener in cache[key].listeners){
        const collection = querySnapshot ? querySnapshot.docs.map(doc => extractData(doc, cache[key].listeners[listener].parser)) : [];
        cache[key].listeners[listener].callback(collection);
    }
}

const registerDocumentListener = (key, listener) => {
    cache[key].listeners.push(listener);
    const currentValue = get(key);
    const data = extractData(currentValue, listener.parser);
    listener.callback(data);
}

const registerCollectionListener = (key, listener) => {
    cache[key].listeners.push(listener);
    const currentValue = get(key);
    const collection = currentValue ? currentValue.docs.map(doc => extractData(doc, listener.parser)) : [];
    listener.callback(collection);
}

const unregisterListener = (key, removedListener) => {
    if(key && has(key))
    {
        cache[key].listeners = cache[key].listeners.filter(listener => listener !== removedListener);
    }
}

const has = (key) => {
    return cache.hasOwnProperty(key);
}

const get = (key) => {
    if(key && has(key)){
        return cache[key].value;
    }
    return undefined;
}

const extractData = (docRef, parser) => {
    if(!docRef || !docRef.exists){
        return undefined;
    }

     if(parser){
        return parser(docRef);
    }

    return docRef.data();
}

export const deleteCache = () => {
    Object.keys(cache).forEach(key => {
        cache[key].unsubscribe();
        delete cache[key];
    })
};

export const useCachedDoc = (path, callback, parser) => {
    useEffect(() => {

        if(!path) return;

        const listener = {callback, parser};
        if(has(path)){
            registerDocumentListener(path, listener);
        }
        else {
            subscribeToDocument(path, listener)
        }
  
        return () => unregisterListener(path, listener);
    }, [path, callback, parser]);
  
    return get(path);
};

// We need a queryMemo or else this just keeps looping as the query gets regenerated on each redraw.
// There might be a better way around this but I've not thought of it.
export const useCachedCollection = (key, queryMemo, callback, parser) => {

    useEffect(() => {

        if(!key || !queryMemo) return;

        const listener = {callback, parser};
        if(has(key)){
            registerCollectionListener(key, listener);
        }
        else {
            subscribeToCollection(key, queryMemo, listener)
        } 
  
        return () => unregisterListener(key, listener);
    }, [key, queryMemo, callback, parser]);
  
    return get(key);
};
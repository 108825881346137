import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import 'firebase/auth';

export default class FirebaseSignInUI extends React.Component {

    render() {
        return <StyledFirebaseAuth uiConfig={this._uiConfig()} firebaseAuth={firebase.auth()}/>
    }

    _uiConfig(){
        const tosUrl = "tos";
        const privacyUrl = "privacy";

        return {
            signInFlow: 'popup',
            signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID
            ],      
            tosUrl: tosUrl,
            privacyPolicyUrl: privacyUrl,
            callbacks: {
            signInSuccessWithAuthResult: () => false
            }
        };
    }
}
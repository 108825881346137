import React, { useState } from "react";
import Modal from 'react-modal';

import { createProfile } from "helpers/profiles";
import { useString } from "providers/LocalisationProvider";
import { useCurrentUserSnapshot } from "hooks/useAccount";
import { useModalScrollLock } from 'hooks/useModalScrollLock';
import { ProfileIcons } from 'fixit-shared/Consts';

Modal.setAppElement('#root');

export const CreateProfileModal = ({ isOpen, onClose, onCreatedProfile, cloudFunctions }) => {

    useModalScrollLock(isOpen);

    const [requestInFlight, setRequestInFlight] = useState(false);

    const [displayName, setDisplayName] = useState("");
    const [raceIcons, setRaceIcons] = useState("");
    const [genderIcons, setGenderIcons] = useState("");
    const [consentObtained, setConsentObtained] = useState(false);
    const [displayIconPulse, setDisplayIconPulse] = useState(false);
    const [displayNamePulse, setDisplayNamePulse] = useState(false);
    const [displayConsentPulse, setDisplayConsentPulse] = useState(false);
    const user = useCurrentUserSnapshot();
    
    const _isComplete = () => {
        return displayName && raceIcons && genderIcons && consentObtained;
    }

    const _renderCreateDialogue = () => {
        const confirmStyle = `create-profile-button ${_isComplete()?'primary':'disabled'}-button rounded`;

        return (
            <Modal
                className="modal create-profile-modal"
                overlayClassName="modal-overlay create-profile-modal-overlay"
                isOpen={isOpen}
                onRequestClose={_closeModal}
                contentLabel="Create Profile"
            >
    
            <form className="modal-flex launch-form" onSubmit={(e)=>e.preventDefault()}> 
                {_renderIcons()}
                {_renderNameEntry()}
                {_renderConsentConfirmation()}
            </form>
            <div className="modal-row">                 
                <button className="create-profile-button secondary-button rounded" onClick={_closeModal}>
                    {useString("cancel")}
                </button>                
                <button className={confirmStyle} onClick={_createProfile}>
                    {useString("confirm")}
                </button>
            </div>
          </Modal>);
    }

    const _renderInFlightDialogue = () => {
        return <Modal
        className="modal create-profile-modal"
        overlayClassName="modal-overlay create-profile-modal-overlay"
        isOpen={isOpen}
        onRequestClose={_closeModal}
        contentLabel="Create Profile">
            <div className="flexbox centered full-height">
                {useString('profile_create_in_flight')}
            </div>
        </Modal>;
    }
    
    const _renderNameEntry = () => {
        const style = displayNamePulse ? "rounded pulse-red-inset" : "rounded"
        return (
            <div className="modal-row">
                <div className="modal-flex">
                    {useString("profile_create_name_title")}
                    <div className="modal-flex centered">
                        <input className={style} type="text" value={displayName} placeholder={useString("profile_create_name_hint")} onChange={_handleNameChange} />
                    </div>
                </div>
            </div>
        );
    }

    const _renderConsentConfirmation = () => {
        const style = displayConsentPulse ? "rounded pulse-red-inset" : "rounded"
        return (
            <div className={"modal-row"}>
                <div className="flexbox">
                    <input className={style} type="checkbox" value={consentObtained} onChange={_handleConsentChange} />
                    <span className="consent-confirm" >{useString("profile_obtained_consent")}</span>
                </div>
            </div>
        );
    }

    const _renderIcons = () => {
        return (
        <div className="modal-row">
            <div className="modal-flex centered">
                <p> {useString("profile_create_icon_title")}</p>
                <div className="flexbox">
                    {_renderIcon(ProfileIcons.WhiteMan, "White", "Man")}
                    {_renderIcon(ProfileIcons.BlackMan, "Black", "Man")}
                    {_renderIcon(ProfileIcons.WhiteWoman, "White", "Woman")}
                    {_renderIcon(ProfileIcons.BlackWoman, "Black", "Woman")}
                </div>
            </div>
        </div>);
    }

    const _renderIcon = (icon, race, gender) => {
        const textKey = `${race.toLowerCase()}_${gender.toLowerCase()}_option`
        return (
            <img className={_iconStyle(race, gender)} src={icon} alt={useString(textKey)} onClick={() => _handleIconChange(race, gender)}/>
        )
    }

    const _resetProfile = () => {
        setDisplayName("");
        setRaceIcons("");
        setGenderIcons("");
        setConsentObtained(false);
        setDisplayIconPulse(false);
        setDisplayNamePulse(false);
        setDisplayConsentPulse(false);
    }

    const _buildProfileObject = () => {
        return {
            displayName, 
            raceIcons,
            genderIcons,
            consentObtained,
            incidents: [],
            version: 2
        }
    }

    const _createProfile = () => {        
        if(!_isComplete()){
            setPulses();
            return;
        }
        setRequestInFlight(true);
        
        createProfile(cloudFunctions, user, _buildProfileObject())
        .then(outcome => {
            _resetProfile();
            setRequestInFlight(false);
            onCreatedProfile(outcome);
        });
    }

    const setPulses = () => {
        if(!raceIcons && !genderIcons){
            setDisplayIconPulse(true);
        }
        if(!consentObtained){
            setDisplayConsentPulse(true);
        }
        if(!displayName){
            setDisplayNamePulse(true);
        }
        setTimeout( () => {
            setDisplayIconPulse(false);
            setDisplayConsentPulse(false);
            setDisplayNamePulse(false);
          }, 1000);
    }

    const _iconStyle = (selectedRace, selectedGender) => {
        if(displayIconPulse) return "icon-option pulse-red";
        return (selectedRace === raceIcons && selectedGender === genderIcons) ? "icon-option selected-icon" : "icon-option";
    }

    const _handleNameChange = (event) => {
        if(displayNamePulse){
            setDisplayNamePulse(false);
        }
        setDisplayName(event.target.value);
    }

    const _handleConsentChange = (event) => {
        if(displayConsentPulse){
            setDisplayConsentPulse(false);
        }
        setConsentObtained(event.target.checked);
    }

    const _handleIconChange = (raceIcons, genderIcons) => {
        if(displayIconPulse){
            setDisplayIconPulse(false);
        }
        setRaceIcons(raceIcons);
        setGenderIcons(genderIcons);
    }

    const _closeModal = () => {
        _resetProfile();
        setRequestInFlight(false);
        onClose();
    }

    if(requestInFlight){
        return _renderInFlightDialogue();
    }
    else{
        return _renderCreateDialogue();
    }
}
import React from 'react';

export default class QuestionHeader extends React.Component {

  render() {

    const {title, icon, borderColour} = this.props;

    const borderStyle = {borderColor: borderColour};

    return (
      <div className="section flexbox noselect">
        <div className="question-header-title flexbox" style={borderStyle}>
          <span>{title}</span>
        </div>
        <img className="question-icon" src={icon} alt={title}  style={borderStyle}/>
      </div>
    );
  }
}
  
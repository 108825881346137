import React from 'react';
import Modal from 'react-modal';
import { useString } from "providers/LocalisationProvider";
import { useModalScrollLock } from 'hooks/useModalScrollLock';

Modal.setAppElement('#root');

export const ErrorModal  = ({content, renderOk, isOpen, onClose}) => {
   
    useModalScrollLock(isOpen);

    const _renderContent = (content )=> {
        if(renderOk){
            return <div className='error-content flexbox centered'>
                {content}
            </div>
        }

        else return content;
    }

    const _renderOk = () => {

        const errorOkString = useString('ERROR_OK');

        if(!renderOk){
            return <div/>;
        }
 
        return <div className="flexbox">                     
            <button className="error-ok-button" onClick={onClose}>
                {errorOkString}
            </button>
        </div>
    }
    
    return (
        <Modal
            className="modal error-modal"
            overlayClassName="modal-overlay error-modal-overlay"
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Error Modal"
        >
            {_renderContent(content)}
            {_renderOk()}
        </Modal>
    );
}
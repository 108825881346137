import React from 'react';
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import { DragDropContext } from 'react-dnd'
import QuestionTypes from 'models/QuestionTypes';
import { QuestionPageMultipleChoice } from './QuestionPageMultipleChoice';
import { QuestionPageMultipleChoiceWithText } from './QuestionPageMulitpleChoiceWithText'

function isTouchDevice() {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

export const QuestionPage = (props) => {

  const {currentQuestion, goToQuestion} = props;

  switch (currentQuestion.type()){
    case QuestionTypes.MultipleChoice:
      return <QuestionPageMultipleChoice
        currentQuestion={currentQuestion}
        goToQuestion={goToQuestion}
        isTouchDevice={isTouchDevice()}
      />
    case QuestionTypes.MultipleChoiceWithText:
      return <QuestionPageMultipleChoiceWithText
        currentQuestion={currentQuestion}
        goToQuestion={goToQuestion}
        isTouchDevice={isTouchDevice()}
      />
    default:
      return <QuestionPageMultipleChoice
        currentQuestion={currentQuestion}
        goToQuestion={goToQuestion}
        isTouchDevice={isTouchDevice()}
      />
  }
}

const backend = isTouchDevice() ? TouchBackend : HTML5Backend;

export default DragDropContext(backend)(QuestionPage);
import firebase from "firebase/app";
import { ProfileKeys, ReportKeys, ReportTypes } from 'fixit-shared/FirestoreKeys';

export const downloadReports = (table, profileId) => {
  return firebase.firestore()
  .collection(`${ProfileKeys.tableName}/${profileId}/${table}`)
  .get()
  .then(querySnapshot => {
    return querySnapshot.docs.map(doc => ReportKeys.decompress(doc));
  });
}

export const saveReport = async (table, report) => {
    try {
        await firebase.firestore()
            .collection(ProfileKeys.tableName).doc(report.profileId)
            .collection(table).doc(report.id)
            .set(report);
        await firebase.firestore()
            .collection(ProfileKeys.tableName).doc(report.profileId)
            .update({
                [ReportTypes.profileKey(table)]: firebase.firestore.FieldValue.arrayUnion(report.id)
            });
        const success = true;
        return success;
    } catch (error) {
        console.error(`Error\n${error}\nwhile adding report:\n${JSON.stringify(report)}`);
        const success = false;
        return success;
    }
}

export const updateReportNotes = async (table, report) => {
    try {
        await firebase.firestore()
            .collection(ProfileKeys.tableName).doc(report.profileId)
            .collection(table).doc(report.id)
            .update({notes: report.notes});
        const success = true;
        return success;
    } catch (error) {
        console.error(`Error\n${error}\nwhile updating report:\n${JSON.stringify(report)}`);
        const success = false;
        return success;
    }
}
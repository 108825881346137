import React from "react";

export const PasswordField = (props) => {
  let { name, defaultValue, onChange, type, children } = props;
  if (!type) type = "text";

  const onTextChanged = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={`text-field text-field-${name}`}>
      {children ? (
        children
      ) : (
        <input
          {...props}
          type="password" 
          onChange={onTextChanged}
          placeholder={defaultValue}
          className={`text-field-input-${name}`}
        />
      )}
    </div>
  );
};

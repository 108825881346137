import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import { useString } from "providers/LocalisationProvider";
import {OptionsModal} from "./OptionsModal";
import {PasswordField} from "components/Forms/PasswordField";
import firebase from "firebase/app";

export const ConfirmDeleteAccountModal = ({
  isOpen,
  onDeleteAccount,
  onClose,
}) => {
  const [passwordValue, setPasswordValue] = useState("");
  const [didDeleteAccount, setDidDeleteAccount] = useState(false);
  const [accountDeletionError, setAccountDeletionError] = useState("");
  
  useEffect(() => {
    return () => {
      setPasswordValue("");
      setDidDeleteAccount(false);
      setAccountDeletionError("");
    }
  },[isOpen, onClose]);

  const emailAccountDeletionConfirmText = useString("settings_delete_account_email_confirmation");
  const googleAccountDeletionConfirmText = useString("settings_delete_account_google_confirmation");
  const accountDeletionConfirmText = useString("settings_delete_account_confirmation");

  const renderEmailAccountDeletionConfirmation = () => {
    return <>
      {emailAccountDeletionConfirmText}
      <form onSubmit={(e) => { e.preventDefault(); }}>
        <PasswordField
          name="password"
          onChange={(v) => setPasswordValue(v)}
        />
      </form>
      {accountDeletionConfirmText}
    </>
  }

  const renderGoogleAccountDeletionConfirmation = () => {
    return <>
      <p>{googleAccountDeletionConfirmText}</p>
      <p>{accountDeletionConfirmText}</p>
    </>
  }

  const tryDeleteAccount = () => {
    onDeleteAccount(passwordValue)
    .then(result => {
      setAccountDeletionError(result.error);
      setDidDeleteAccount(result.success);
    })
  }

  const okayString = useString("ok");
  const cancelString = useString("cancel");
  const deleteNowString = useString("settings_delete_account_now");

  const user = firebase.auth().currentUser;

  if(didDeleteAccount || !user){
    return <Redirect to = '/'/>
  }

  if(accountDeletionError){
    return <OptionsModal
      isOpen={isOpen}
      content={accountDeletionError}
      options={[
        {
          text: okayString,
          onClick: onClose,
          classNames: ["primary"],
        },
      ]}
      useAltButtons={true}
      onClose={onClose}
    />
  }

  const confirmMessage = (user.providerData[0].providerId === firebase.auth.EmailAuthProvider.PROVIDER_ID) ?
  renderEmailAccountDeletionConfirmation :
  renderGoogleAccountDeletionConfirmation;

  return (
    <OptionsModal
      isOpen={isOpen}
      content={confirmMessage()}
      options={[
        {
          text: cancelString,
          onClick: onClose,
          classNames: ["secondary"],
        },
        {
          text: deleteNowString,
          onClick: tryDeleteAccount,
          classNames: ["danger"],
        },
      ]}
      useAltButtons={true}
      onClose={onClose}
    />
  );
};

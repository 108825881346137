import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useModalScrollLock } from 'hooks/useModalScrollLock';

Modal.setAppElement('#root');

export const OptionsModal = ({content, options, isOpen, onClose, useAltButtons, primaryStyle, defaultStyleOverride}) => {

    useModalScrollLock(isOpen);

    const _renderOptions = () => {
        const optionButtons = []

        const defaultStyle = defaultStyleOverride ? defaultStyleOverride : 'option-button rounded';

        options.forEach(option => {
            const className = option.primary ? 
            `${defaultStyle} primary-button ${primaryStyle}` :
            `${defaultStyle} secondary-button`;

            optionButtons.push(<button 
                className={className}
                onClick={option.onClick}
                key={option.text}>
                    {option.text}
                </button>
            );
        });

        return optionButtons
    }

    const _renderOptionsAlt = () => {
        return options.map(option => {
            const classNames = ["option-button", "button", ...(option.classNames || ["secondary"])];
            const props = {className: classNames.join(" "), key: option.text}
            if (option.linkTo) {
                return <Link {...props} to={option.linkTo}>{option.text}</Link>;
            } else {
                return <button {...props} onClick={option.onClick}>{option.text}</button>;
            }
        });
    }

    return (
        <Modal
            className="modal options-modal"
            overlayClassName="modal-overlay options-modal-overlay"
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Options Modal"
        >
            <div className="centered"> 
                <div className="options-content flexbox"> {content} </div>
                <div className="options-list flexbox">{
                    useAltButtons
                        ? _renderOptionsAlt()
                        : _renderOptions()
                }</div>
            </div>
        </Modal>
    );
}
const prefix = "fixit_answers_happens_again_";
const path = "fixit/answers/what_can_i_do_if_it_happens_again_answers/";

export const WhatCanIDoIfItHappensAgainAnswers = [
  {titleKey: prefix+"ask_an_adult"           , icon: path+"i_can_ask_an_adult_for_help_{r}{g}.svg"      , isSelectable: true   },
  {titleKey: prefix+"5_point_scale"          , icon: path+"i_can_look_at_my_5_point_scale.svg"          , isSelectable: true   },
  {titleKey: prefix+"theraputty"             , icon: path+"i_can_squeeze_my_theraputty_{r}x.svg"        , isSelectable: true   },
  {titleKey: prefix+"sensory_room"           , icon: path+"i_can_use_my_sensory_room_card_{r}x.svg"     , isSelectable: true   },
  {titleKey: prefix+"timeout_card"           , icon: path+"i_can_use_my_time_out_card.svg"              , isSelectable: true   },
  {titleKey: prefix+"breathing_card"         , icon: path+"i_can_look_at_my_breathing_card_{r}{g}.svg"  , isSelectable: true   },
  {titleKey: prefix+"deep_breath"            , icon: path+"i_can_take_a_deep_breath_{r}{g}.svg"         , isSelectable: true   },
  {titleKey: prefix+"ear_defenders"          , icon: path+"i_can_use_my_ear_defenders.svg"              , isSelectable: true   },
  {titleKey: prefix+"quiet_desk"             , icon: path+"i_can_go_to_my_quiet_desk_{r}{g}.svg"        , isSelectable: true   },
  {titleKey: prefix+"zones_of_regulation"    , icon: path+"look_at_zones_of_regulation.svg"             , isSelectable: true   },
  {titleKey: prefix+"quiet_corner"           , icon: path+"i_can_go_to_the_quiet_corner_{r}{g}.svg"     , isSelectable: true   },
  {titleKey: prefix+"move_back"              , icon: path+"ask_someone_to_move_back_{r}{g}.svg"         , isSelectable: true   },
  {titleKey: prefix+"safe_space"             , icon: path+"go_to_the_safe_space_{r}{g}.svg"             , isSelectable: true   },
  {titleKey: prefix+"move_away"              , icon: path+"move_away_from_someone_{r}{g}.svg"           , isSelectable: true   },
  {titleKey: prefix+"dont_understand"        , icon: path+"say_i_dont_understand_{r}{g}.svg"            , isSelectable: true   },
  {titleKey: prefix+"something_else"         , icon: path+"something_else_{r}{g}.svg"                   , isSelectable: true   },
  {titleKey: prefix+"hungry"                 , icon: path+"i_can_say_if_i_am_hungry_{r}{g}.svg"         , isSelectable: true   },
  {titleKey: prefix+"thirsty"                , icon: path+"i_can_say_if_i_am_thirsty_{r}{g}.svg"        , isSelectable: true   }
];

export default WhatCanIDoIfItHappensAgainAnswers;
const path = "fixit/answers/now_i_feel_answers/";

export const NowIFeelAnswers = [
  {titleKey: "fixit_answers_now_i_feel_good"                          , icon: path+"good_{r}x.svg"                                , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_ready_to_make_a_card"          , icon: path+"ready_to_make_a_card_{r}{g}.svg"              , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_ready_to_say_sorry"            , icon: path+"ready_to_say_sorry_{r}{g}.svg"                , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_ready_to_tidy_up"              , icon: path+"ready_to_tidy_up_{r}{g}.svg"                  , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_ready_to_write_a_letter"       , icon: path+"ready_to_write_a_letter_{r}{g}.svg"           , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_ready_back_to_classroom"       , icon: path+"ready_to_go_back_to_the_classroom_{r}{g}.svg" , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_ready_to_play_the_game"        , icon: path+"ready_to_play_the_game_{r}{g}.svg"            , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_ready_to_play_with_friends"    , icon: path+"ready_to_play_with_friends_{r}{g}.svg"        , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_calm"                          , icon: path+"calm_{r}{g}.svg"                              , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_ready_for_the_activity"        , icon: path+"ready_for_the_activity_{r}{g}.svg"            , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_happy_to_have_spoken"          , icon: path+"happy_to_have_spoken_{r}{g}.svg"              , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_proud_i_have_fixed_it"         , icon: path+"proud_i_have_fixed_it_{r}{g}.svg"             , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_relieved_to_know_what_to_do"   , icon: path+"relieved_to_know_what_to_do.svg"              , isSelectable: true   },
  {titleKey: "fixit_answers_now_i_feel_i_need_5_more_minutes"         , icon: path+"i_need_5_more_minutes.svg"                    , isSelectable: true   }
];

export default NowIFeelAnswers;
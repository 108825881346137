const prefix = "coa_answers_";
const path = "coa/answers/";

export const HowDoYouFeelAnswers = [
  {titleKey: prefix+"happy"             , icon: path+"happy_{r}.svg"              , isSelectable: true },
  {titleKey: prefix+"proud"             , icon: path+"proud_{r}.svg"              , isSelectable: true },
  {titleKey: prefix+"excited"           , icon: path+"excited_{r}.svg"            , isSelectable: true },
  {titleKey: prefix+"calm_and_relaxed"  , icon: path+"calm_relaxed_{r}.svg"       , isSelectable: true },
  {titleKey: prefix+"dont_know"         , icon: path+"i_dont_know_{r}{g}.svg"     , isSelectable: true }
];

export default HowDoYouFeelAnswers;
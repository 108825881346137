import React, {useRef, useState} from 'react';
import { Redirect } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print';
import { useString } from 'providers/LocalisationProvider';
import { ReportTypes } from 'fixit-shared/FirestoreKeys';
import { CertificateOfAchievement } from './CertificateOfAchievement';

export const CertificateOfAchievementSummary = props => {
    const {location, telemetryService} = props;
    const [editNotes, setEditNotes] = useState(true);
    const [hasFinished, setHasFinished] = useState(false);
    const certificate = location.certificate;
    const printComponentRef = useRef();

    const _renderSave = () => {
      return (
      <div className='flexbox'>          
        <button className='primary-button summary-button summary-text rounded flexbox' onClick={_onSave}>
          {useString('save')}
        </button>
      </div>
      )
    }
  
    const _onSave = () => {
  
      certificate.save(ReportTypes.CertificateOfAchievement)
      .then(success => setHasFinished(success));
  
      const telemetryProps = {
        profile_uuid: certificate.profile().id,
        certificate_id: certificate.id(),
        summary: certificate.toJson().questions,
        duration: Date.now() - certificate.date,
        // schoool_id,
        // class_level,
      };
      telemetryService.reportEvent('certificate_of_achievement.completed', telemetryProps);
    }

    const _handlePrint = useReactToPrint({
      onBeforeGetContent: () => {setEditNotes(false); return Promise.resolve();},
      onAfterPrint: () => {setEditNotes(true); return Promise.resolve();},
      content: () => printComponentRef.current,
    });

    const printString = useString('print');

    if(!certificate){
      return <Redirect to="profiles"/>
    }

    if(hasFinished){
      return <Redirect to='/profiles' />;
    }

    return (
<div className = 'certificate-of-achievement'> 
    <div className='noselect'>    
        <CertificateOfAchievement
          ref={printComponentRef}
          certificate = {certificate}
          editNotes = {editNotes}
        />
        <div className='summary-options centered flexbox'> 
          {_renderSave()}
          <button onClick={_handlePrint} className='secondary-button summary-button summary-text rounded flexbox'>{printString}</button>
        </div>
      </div>
    </div>
    );
  }
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname, state } = useLocation()

  useEffect(() => {
    if (!pathname.startsWith('/about')) {
      window.scrollTo(0, 0)
    } else {
      if (state && state.fromFooter) {
        window.scrollTo(0, 0)
      } else return
    }
  }, [pathname, state])

  return null
}

import QuestionMultipleChoice from './QuestionMultipleChoice';
import QuestionMultipleChoiceWithText from './QuestionMultipleChoiceWithText';
import QuestionTypes from './QuestionTypes';
import { saveReport, updateReportNotes } from 'helpers/reports';

export default class SessionReport {

    constructor(id, profile, questions, isHistorical, notes = ""){
        this._id = id;
        this._profile = profile;
        this._questions = questions;
        this._isHistorical = isHistorical;
        this._currentQuestionIndex = 0;
        this._notes = notes;
        this.date = SessionReport.getDate(id);
    }

    static createNew(pupilProfile, teacherProfile, questionSet, localisationService){
        const isHistorical = false;
        const id = Date.now();

        const questions = this._createQuestions(pupilProfile, teacherProfile, questionSet, localisationService, isHistorical);

        const report = new SessionReport(id, pupilProfile, questions, isHistorical);

        report._isComplete = false;
        
        return report;
    }

    static createHistorical(pupilProfile, teacherProfile, data, localisationService){
        const isHistorical = true;
        const questions = this._createQuestions(pupilProfile, teacherProfile, data.questions, localisationService, isHistorical);
        const report = new SessionReport(data.id, pupilProfile, questions, isHistorical, data.notes);

        return report;
    }

    static _createQuestions(pupilProfile, teacherProfile, source, localisationService, isHistorical){
        const questions = [];
        for(let questionIndex = 0; questionIndex < source.length; ++questionIndex){
            questions.push(this._createQuestion(pupilProfile, teacherProfile, questionIndex, source[questionIndex], localisationService, isHistorical));
        }
        return questions;
    }

    static _createQuestion(pupilProfile, teacherProfile, questionIndex, questionData, localisationService, isHistorical)
    {
        const askingProfile = this._determineAskerProfile(teacherProfile, pupilProfile, questionData);
        const answeringProfile = this._determineAnswererProfile(teacherProfile, pupilProfile, questionData);

        switch (questionData.type){
        case QuestionTypes.MultipleChoice: 
            return new QuestionMultipleChoice(askingProfile, answeringProfile, questionIndex, questionData, localisationService, questionData.maxAnswers, isHistorical);
        case QuestionTypes.MultipleChoiceWithText: 
            return new QuestionMultipleChoiceWithText(askingProfile, answeringProfile, questionIndex, questionData, localisationService, questionData.maxAnswers, isHistorical);
        default: 
            return new QuestionMultipleChoice(askingProfile, answeringProfile, questionIndex, questionData, localisationService, questionData.maxAnswers, isHistorical);
        }
    }

    static _determineAskerProfile(teacherProfile, pupilProfile, questionData){
        const teacherQuestions = [
            "questions_i_can_see_you_are_doing_this",
            "questions_let_me_tell_you_how_i_feel",
        ];
        return teacherQuestions.includes(questionData.titleKey) ? teacherProfile : pupilProfile;
    }
    
    static _determineAnswererProfile(teacherProfile, pupilProfile, questionData){
        const teacherAnswering = [
            "questions_let_me_tell_you_how_i_feel",
        ];
        return teacherAnswering.includes(questionData.titleKey) ? teacherProfile : pupilProfile;
    }
    
    static getDate(id){
        return new Date(parseInt(id));
    }

    id() {
        return this._id;
    }

    profile() {
        return this._profile;
    }

    save(table){
        if(this._isHistorical){
            throw new Error("MODIFY_HISTORICAL_REPORT");
        }
        return saveReport(table, this.toJson())
        .then(success => {
            this._isSaved = success;
            return success;
        });
    }

    saveUpdatedNotes(table)
    {
        return updateReportNotes(table, this.toJson())
        .then(success => {
            this._isSaved = success;
            return success;
        });
    }

    isHistorical(){
        return this._isHistorical;
    }
    
    isComplete(){
        if(this._isHistorical){
            throw new Error("MODIFY_HISTORICAL_INCIDENT");
        }
        return this._currentQuestionIndex >= this.numQuestions();
    }

    questions() {
        return this._questions;
    }

    question(index) {
        if(index < this.numQuestions()){
            return this._questions[index];
        }
        return undefined;
    }

    currentQuestion() {
        return this._questions[this._currentQuestionIndex];
    }

    numQuestions(){
        return this._questions.length;
    }

    goToQuestion(questionIndex) {
        if(this._currentQuestionIndex < this._questions.length){
            this.currentQuestion().save();
        }
        this._currentQuestionIndex = questionIndex;
    }

    getNotes() {
        return this._notes;
    }

    setNotes(newNotes) {
        this._notes = newNotes;
    }

    toJson(){
        return {
            id: this._id.toString(),
            profileId: this._profile.id,
            questions: this._questions.map(question => question.toJson()),
            notes: this._notes,
        }
    }
}
import React from 'react'
import { Link } from 'react-router-dom'
import 'css/information-pages.css'
import { BsArrowLeft } from 'react-icons/bs'

export default class Privacy extends React.Component {
  render() {
    const url = window.location.origin
    return (
      <div className='info-content'>
        <Link className='info-home-link' to='/'>
          <BsArrowLeft />
          <span>Home</span>
        </Link>
        <h2 className='info-main-heading'>Privacy Policy</h2>
        <p>
          Your privacy is important to us. It is IFixit Apps' policy to respect
          your privacy regarding any information we may collect from you across
          our website, <Link to={'/'}>{url}</Link>, and other sites we own and
          operate.
        </p>
        <p>
          We only ask for personal information when we truly need it to provide
          a service to you. We collect it by fair and lawful means, with your
          knowledge and consent. We also let you know why we’re collecting it
          and how it will be used.
        </p>
        <p style={{ marginTop: '40px', fontWeight: '600' }}>
          The personal information that we store includes, but is not limited
          to:{' '}
        </p>
        <ul>
          <li>
            Your email address, to allow you to login and contact you with
            information about your account
          </li>
        </ul>
        <p style={{ marginTop: '40px', fontWeight: '600' }}>
          For each profile we store:{' '}
        </p>
        <ul>
          <li>A display name, to allow you to identify the account</li>
          <li>
            The gender and race icons to use with the profile when running
            sessions
          </li>
          <li>
            Who created the profile, so that they can modify and delete it
          </li>
          <li>Which users are permitted to run sessions with the profile</li>
          <li>
            The times that sessions were ran and the answers chosen, so that we
            can display this historical data to you
          </li>
        </ul>
        <p style={{ marginTop: '40px' }}>
          We also store non-identifying information related to the level of your
          account and the number of profiles you can have at once.
        </p>
        <p>
          We only retain collected information for as long as necessary to
          provide you with your requested service. What data we store, we’ll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorised access, disclosure, copying, use or
          modification.
        </p>
        <p>
          We don’t share any personally identifying information publicly or with
          third-parties, except when required to by law.
        </p>
        <p>
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and practices
          of these sites, and cannot accept responsibility or liability for
          their respective privacy policies.
        </p>
        <p>
          You are free to refuse our request for your personal information, with
          the understanding that we may be unable to provide you with some of
          your desired services.
        </p>
        <p>
          Your continued use of our website will be regarded as acceptance of
          our practices around privacy and personal information. If you have any
          questions about how we handle user data and personal information, feel
          free to contact us.
        </p>

        <h2 className='info-main-heading'>
          How do we store and share your information?
        </h2>

        <p>
          We use Google Cloud Firestore to store student profile information, as
          well as the name and email of the account holder. All customer data is
          stored in a data centre in London, England.
        </p>

        <p>
          We use Google analytics to collect, analyse, and store the data
          gathered from usage of the app. Google Analytics records when an
          account is created, when a profile is created, deleted or modified,
          when a session is started or completed and how often an answer is
          picked, and when a page is opened, and other events related to how the
          site is used. The events are sent without identifying information and
          no personal details are passed on e.g. your email address. This may
          mean that the data is transferred outside of the country you are based
          in. In all cases Google analytics comply with legal frameworks
          relating to the transfer of data, such as the EU-US and Swiss-US
          Privacy Shield Frameworks. The data processed by Google Analytics will
          not include any personally identifying information but will include
          statistical data for establishing patterns.
        </p>

        <h2 className='info-main-heading'>Your Rights</h2>

        <p>
          If you are based in the European Union, you have a number of rights
          under the GDPR. They include the right to be informed of how and why
          we collect your data, the right to access the data we collect for you,
          the right to correct inaccurate or incomplete data we hold on you, and
          the right to have us delete any data we hold on you. You can see a
          full list of your rights and more detail about what they mean here:
        </p>
        <p>
          <a href='https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights'>
            https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights
          </a>
        </p>

        <p>This policy is effective as of 21 November 2020.</p>
        <p>
          Generated by{' '}
          <a
            title='Privacy Policy Template Generator'
            href='https://getterms.io/'
          >
            GetTerms.io
          </a>
        </p>
      </div>
    )
  }
}

const path = "fixit/answers/what_i_did_to_show_it_answers/";

export const WhatIDidToShowItAnswers = [
  {titleKey: "fixit_answers_show_it_i_didnt_listen"      , icon: path+"i_didnt_listen_{r}{g}.svg"      , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_broke_something"   , icon: path+"i_broke_something_{r}x.svg"     , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_threw_things"      , icon: path+"i_threw_things_{r}{g}.svg"      , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_hit_someone"       , icon: path+"i_hit_someone_{r}{g}.svg"       , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_hurt_someone"      , icon: path+"i_hurt_someone_{r}{g}.svg"      , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_slammed_the_door"  , icon: path+"i_slammed_the_door.svg"         , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_scratched_someone" , icon: path+"i_scratched_someone_{r}x.svg"   , isSelectable: false  },
  {titleKey: "fixit_answers_show_it_i_bit_someone"       , icon: path+"i_bit_someone_{r}{g}.svg"       , isSelectable: false  },
  {titleKey: "fixit_answers_show_it_i_spat_on_someone"   , icon: path+"i_spat_on_someone_{r}{g}.svg"   , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_climbed_furniture" , icon: path+"i_climbed_furniture_{r}{g}.svg" , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_yelled"            , icon: path+"i_yelled_{r}{g}.svg"            , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_ran_away"          , icon: path+"i_ran_away_{r}{g}.svg"          , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_hit_myself"        , icon: path+"i_hit_myself_{r}{g}.svg"        , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_kicked_someone"    , icon: path+"i_kicked_someone_{r}{g}.svg"    , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_said_bad_words"    , icon: path+"i_said_bad_words_{r}{g}.svg"    , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_cant_remember"     , icon: path+"i_cant_remember_{r}{g}.svg"     , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_stomped_feet"      , icon: path+"i_stomped_feet.svg"             , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_broke_rules"         , icon: path+"i_broke_the_rules.svg"          , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_nothing"             , icon: path+"nothing.svg"                    , isSelectable: true   },
  {titleKey: "fixit_answers_show_it_i_cried"             , icon: path+"i_cried_{r}{g}.svg"             , isSelectable: true   }
];

export default WhatIDidToShowItAnswers;
import QuestionTypes from 'models/QuestionTypes';
import WhatHappenedAnswers from './01.WhatHappened';
import HowDidItFeelInMyBodyAnswers from './02.HowDidItFeelInMyBody';
import EmotionNameAnswers from './03.EmotionName';
import WhatIDidToShowItAnswers from './04.WhatIDidToShowIt';
import WhoDidIHurtAnswers from './05.WhoDidIHurt';
import HowDoIThinkIMadeOthersFeelAnswers from './06.HowDoIThinkIMadeOthersFeel';
import HowHasMyDayBeenAnswers from './07.HowHasMyDayBeen';
import WhatCanIDoToHelpMakeItBetterAnswers from './08.WhatCanIDoToHelpMakeItBetter';
import WhatCanIDoIfItHappensAgainAnswers from './09.WhatCanIDoIfItHappensAgain';
import NowIFeelAnswers from './10.NowIFeel';

const path = "fixit/questions";

export const Questions = [
  {
    titleKey:"questions_what_happened", 
    icon: path+"/what_happened.svg", 
    colour:"#9DB4ED", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: WhatHappenedAnswers,
  },
  {
    titleKey:"questions_how_did_it_feel", 
    icon: path+"/how_did_it_feel_in_my_body_{r}{g}.svg", 
    colour:"#5C7CCD", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: HowDidItFeelInMyBodyAnswers,
  },
  {
    titleKey:"questions_emotion_name", 
    icon: path+"/emotion_name_{r}x.svg", 
    colour:"#E25F4E", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: EmotionNameAnswers,
  },
  {
    titleKey:"questions_what_i_did", 
    icon: path+"/what_i_did_to_show_it_{r}{g}.svg", 
    colour:"#E0D062", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: WhatIDidToShowItAnswers,
  },
  {
    titleKey:"questions_who_i_hurt", 
    icon: path+"/who_did_i_hurt_{r}{g}.svg", 
    colour:"#E7AA5B", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: WhoDidIHurtAnswers,
  },
  {
    titleKey:"questions_how_others_feel", 
    icon: path+"/how_do_i_think_i_made_others_feel_{r}x.svg", 
    colour:"#E25F4E", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: HowDoIThinkIMadeOthersFeelAnswers,
  },
  {
    titleKey:"questions_make_it_better", 
    icon: path+"/what_can_i_do_to_help_make_it_better_{r}{g}.svg", 
    colour:"#86CD7F", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: WhatCanIDoToHelpMakeItBetterAnswers,
  },
  {
    titleKey:"questions_my_day", 
    icon: path+"/how_has_my_day_been.svg", 
    colour:"#D9B1E0", 
    type: QuestionTypes.MultipleChoiceWithText, maxAnswers: 1,
    multipleChoiceAnswers: HowHasMyDayBeenAnswers,
  },
  {
    titleKey:"questions_happens_again", 
    icon: path+"/what_can_i_do_if_it_happens_again_{r}{g}.svg", 
    colour:"#66A160", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: WhatCanIDoIfItHappensAgainAnswers,
  },
  {
    titleKey:"questions_now_i_feel", 
    icon: path+"/now_i_feel_{r}{g}.svg", 
    colour:"#E25F4E", 
    type: QuestionTypes.MultipleChoice, maxAnswers: 3,
    answers: NowIFeelAnswers,
  }
];

export default Questions;
import React from 'react'
import { Link } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'
import { NavSurround } from './NavSurround'
import Footer from './Footer'
import { LocalisationContext } from 'providers/LocalisationProvider'
import 'css/information-pages.css'

export default class Faqs extends React.Component {
  static contextType = LocalisationContext

  _localisationService() {
    return this.context
  }

  render() {
    const { authService, telemetryService } = this.props
    return (
      <NavSurround
        authService={authService}
        telemetryService={telemetryService}
        currentPage='faq'
      >
        <div className='info-content'>
          {this._renderHeader()}
          {this._renderQuestions()}
          {this._renderContact()}
        </div>
        <Footer />
      </NavSurround>
    )
  }

  _renderHeader() {
    return (
      <div>
        <Link className='info-home-link' to='/'>
          <BsArrowLeft />
          <span>Home</span>
        </Link>
        <h2 className='info-main-heading'>
          {this._localisationService().getString('faq_title')}
        </h2>
      </div>
    )
  }

  _renderQuestions() {
    return (
      <div>
        <div>{this._renderFaqContent()}</div>
      </div>
    )
  }

  _renderFaqContent() {
    return (
      <div>
        {this._renderTitledPair('faq_how_to_deliver_ifixit')}
        {this._renderTitledPair('faq_more_apps')}
        {this._renderTitledPair('faq_pricing')}
      </div>
    )
  }

  _renderTitledPair(key) {
    const splitContent = this._localisationService()
      .getString(`${key}_content`)
      .split('\n')
    const splitSpans = splitContent.map((text, i) => <p key={i}>{text}</p>)

    return (
      <div>
        <h3 className='info-sub-heading'>
          {this._localisationService().getString(`${key}_title`)}
        </h3>
        {splitSpans}
      </div>
    )
  }

  _renderSubheadedPair(key) {
    const splitContent = this._localisationService()
      .getString(`${key}_content`)
      .split('\n')
    const splitSpans = splitContent.map((text, i) => <p key={i}>{text}</p>)

    return (
      <div className='flexbox'>
        <h1 className='noselect rounded centered'>
          {this._localisationService().getString(`${key}_title`)}
        </h1>
        {splitSpans}
      </div>
    )
  }

  _renderContact() {
    return (
      <div className='noselect faq-footer'>
        <span className='noselect'>
          {this._localisationService().getString('faq_footer')}
        </span>
        <div className='faq-contact'>
          <a href='mailto:support@ifixitapps.com'>
            {this._localisationService().getString('contact_us')}
          </a>
        </div>
      </div>
    )
  }
}

import React, { createContext, useContext, useState } from "react";

import {LocalisationService} from "services/LocalisationService";
import LocalisationEnGb from "data/en-GB";

export const DefaultLanguage = "en-GB";

const defaultLocalisationService = new LocalisationService(
  DefaultLanguage,
  LocalisationEnGb
);

export const LocalisationContext = createContext();

export const LocalisationServiceConsumer = LocalisationContext.Consumer;
export const LocalisationServiceProvider = ({ children }) => {
  const [localisationService] = useState(defaultLocalisationService);
  return (
    <LocalisationContext.Provider
      value={localisationService}
      children={children}
    />
  );
};

/** @return {LocalisationService} Retrieves the active localisation **/
export const useLocalisationService = () => useContext(LocalisationContext);
/** @return {string} Retrieves a translated string from the localisation service **/
export const useString = (key, substitutions) =>
  useLocalisationService().getString(key, substitutions);
  /** @return {string | null} Retrieves a translated string from the localisation service, or `null` on failure. **/
export const useStringOrNull = (key, substitutions) =>
  useLocalisationService().getStringOrNull(key, substitutions);

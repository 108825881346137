import firebase from "firebase";

export default class FirebaseAuthenticationService {

    constructor(firebaseAuthService)
    {
        this._onSignInCallbacks = [];
        this._onSignOutCallbacks = [];
        this._onNewUserCallbacks = [];
        this._signInTime = undefined;
    
        firebase.auth().onAuthStateChanged(this._onStateChanged.bind(this));
    }

    addNewUserCallback(callback) {
        this._onNewUserCallbacks.push(callback);
    }

    removeNewUserCallback(callback) {
        const index = this._onNewUserCallbacks.indexOf(callback);
        if (index > -1) {
            this._onNewUserCallbacks.splice(index, 1);
        }
    }

    addAuthCallbacks(onSignIn, onSignOut) {
        if(onSignIn){
            this._onSignInCallbacks.push(onSignIn);
            if(this.isAuthenticated()){
                onSignIn(firebase.auth().currentUser);
            }
        }
        if(onSignOut){
            this._onSignOutCallbacks.push(onSignOut);
        }
    }

    removeAuthCallbacks(onSignIn, onSignOut) {
        if(onSignIn){
            const index = this._onSignInCallbacks.indexOf(onSignIn);
            if (index > -1) {
                this._onSignInCallbacks.splice(index, 1);
            }
        }
        if(onSignOut){
            const index = this._onSignOutCallbacks.indexOf(onSignOut);
            if (index > -1) {
                this._onSignOutCallbacks.splice(index, 1);
            }
        }
    }

    _onStateChanged(user) {
        if(user){
            this._notifySignIn(user);
        }
        else {
            this._notifySignOut();
        }
    }

    _notifySignIn(user) {
        if(user.metadata.creationTime === user.metadata.lastSignInTime){
            for(const callback of this._onNewUserCallbacks){
                callback(user.uid);
            }
        }
        this._signInTime = new Date(user.metadata.lastSignInTime);

        for(const callback of this._onSignInCallbacks){
            callback(user);
        }
    }

    _notifySignOut() {
        this._signInTime = undefined;

        for(const callback of this._onSignOutCallbacks){
            callback();
        }
    }

    /** @returns {Promise<firebase.auth().UserCredential | null>} **/
    async createAccount(email, password, passwordRepeat, onError) {
        if (password !== passwordRepeat) {
            onError("Passwords do not match");
            return null;
        };

        try {
            const result = await firebase.auth().createUserWithEmailAndPassword(email, password);
            return result;
        } catch (error) {
            var errorMessage = `${error.code}: ${error.message}`;
            onError(errorMessage);
            return null;
        }
    }

    signInTime(){
        return this._signInTime;
    }

    async signInWithEmailAndPassword(email, password, onError) {
        try {
            const result = await firebase.auth().signInWithEmailAndPassword(email, password);
            return result;
        } catch (error) {
            onError(error);
            return null;
        }
    }

    signOut() {
        this._notifySignOut();
        firebase.auth().signOut();
    }

    async resetPassword(email) {
        return firebase.auth().sendPasswordResetEmail(email);
    }

    onAuthenticated(callback) {
        if(!this._isAuthenticating)
            return callback();
        this._onAuthenticatedCallbacks.push(callback);
    }

    isAuthenticating(){
        return this._isAuthenticating;
    }

    isAuthenticated() {
        return !!firebase.auth().currentUser;
    }
}
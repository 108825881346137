import React, { useEffect, useState } from "react";
import { TextField } from "./TextField";

export const IntegerField = ({ name, title, defaultValue, onChange, ...others }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => onChange(value), [value, onChange]);

  const handleChange = (v) => {
    const filtered = v.replace(/[^0-9]/g, "");
    if (filtered !== value) setValue(filtered);
  };

  return (
    <TextField
      {...others}
      name={name}
      title={title}
      value={value}
      onChange={handleChange}
    />
  );
};

import React, {useState} from 'react';
import Modal from 'react-modal';
import FirebaseSignInUI from '../FirebaseSigninUI'
import { useString, useLocalisationService } from "providers/LocalisationProvider";
import { isEmailValid } from 'helpers/is-account-valid';
import { useModalScrollLock } from 'hooks/useModalScrollLock'
import HtmlParse from 'html-react-parser';

export const SignInModal  = ({authService, isOpen, onClose}) => {
    
  const [signupEmailValue,      setSignupEmailValue]      = useState("");
  const [signUpPasswordValue,   setSignUpPasswordValue]   = useState("");
  const [signUpPasswordRepeat,  setSignUpPasswordRepeat]  = useState("");
  const [signInEmailValue,      setSignInEmailValue]      = useState("");
  const [signInPasswordValue,   setSignInPasswordValue]   = useState("");
  const [showPasswordWarning,   setShowPasswordWarning]   = useState(false);
  const [showSignUpError,       setShowSignUpError]       = useState(false);
  const [signUpError,           setSignUpError]           = useState("");
  const [validEmail,            setValidEmail]            = useState(false);
  const [passwordsMatch,        setPasswordsMatch]        = useState(false);
  const [acceptTerms,           setAcceptTerms]           = useState(false);
  const [signInError,           setSignInError]           = useState(null);
  const [isLoading,             setIsLoading]             = useState(false);
  const [isResettingPassword,   setIsResettingPassword]   = useState(false);
  const [passwordResetError,    setPasswordResetError]    = useState(null);
  const [passwordResetSuccess,  setPasswordResetSuccess]  = useState(false);

  const ALREADY_REGISTERED_ERROR = 'auth/email-already-in-use: The email address is already in use by another account.';

  useModalScrollLock(isOpen);

  const _renderSignup = () => {
    const canProceed = validEmail && passwordsMatch && acceptTerms;
    return (
      <div className="sign-in-container vfill centered">
        <p>{useString('sign_in_sign_up')}</p>

          {_renderNewAccountDetailsForm()}
          {_renderTermsCheck()}
          <button className="sign-in-button primary-button rounded" disabled={!canProceed} onClick={_signUp}>
            {useString("sign_in_confirm")}
          </button>
          {_renderSignUpWarning()}
      </div>
    );
  }  

  const _confirmPasswordRepeat = () => {
    setShowPasswordWarning(!passwordsMatch)
  }
  
  const _renderNewAccountDetailsForm = () => {
    const emailInUseStyle = signUpError === ALREADY_REGISTERED_ERROR ? 'danger' : '';
    const passwordRepeatStyle = showPasswordWarning ? 'danger' : '';
    return <form onSubmit={_preventDefault}>
      <input type="email" className={emailInUseStyle} autoComplete='username' value={signupEmailValue} placeholder={useString("sign_in_email_hint")} onChange={_validateEmail} />
      <input type="password" autoComplete='new-password' value={signUpPasswordValue} placeholder={useString("sign_in_password_hint")} onChange={_validatePassword} />
      <input type="password" className={passwordRepeatStyle} autoComplete='new-password' value={signUpPasswordRepeat} placeholder={useString("sign_in_repeat_password_hint")} onChange={_validatePasswordRepeat} onBlur={_confirmPasswordRepeat} />
    </form>
  }

  const _renderSignUpWarning = () => {
    const passwordWarningText = useString("sign_up_password_mismatch_warning");
    const accountExistsWarningText = useString("sign_up_account_exists_warning");
    const supportWarningText = useString("sign_up_support_warning", {error: signUpError});
    const contactSupportText = useString("contact_support");

    if(showSignUpError){
      if(signUpError === ALREADY_REGISTERED_ERROR){
        return <>
          <p className="text-danger">{accountExistsWarningText}</p>
        </>
      }
      else{
        return <>
          <p className="text-danger">{supportWarningText} {HtmlParse(contactSupportText)}</p>
        </>
      }
    }

    if(showPasswordWarning){
      return <>
        <p className="text-danger">{passwordWarningText}</p>
      </>
    }
    else{
      return <></>
    }
  }

  const _preventDefault = (e) => {
    e.preventDefault();
  }

  const  _renderTermsCheck = () => {
    const tosLink = "tos";
    const termsText = useString("homepage_terms");
    return <div className="sign-in-terms flexbox">
      <span className="small-text">{useLocalisationService().getNewTabLink("sign_in_accept_terms", tosLink, termsText)}</span>
      <input className="rounded" type="checkbox" value={acceptTerms} onChange={_handleConsentChange} />
    </div>
  }

  const _renderDivider = () => {
    return (
      <div className="sign-in-divider centered flexbox">
        <span>{useString("sign_in_divider_text")}</span>
      </div>
    );
  }
  
  const _renderSignIn = () => {
    const isValidSignInEmail = signInEmailValue.length > 0 && isEmailValid(signInEmailValue)
    const canProceed = isValidSignInEmail && signInPasswordValue.length > 0 && !isLoading;

    const signInResetString = useString("sign_in_reset");
    const emailHintString = useString("sign_in_email_hint");
    const cancelString = useString("cancel");
    const signInResetInstructionsString = useString("sign_in_reset_instructions", { email: signInEmailValue });
    const okString = useString("ok");

    const signInHaveAccountString = useString("sign_in_have_account");
    const signInEmailHintString = useString("sign_in_email_hint");
    const signInPasswordHintString = useString("sign_in_password_hint");
    const signInString = useString("sign_in");
    const signInForgotString = useString("sign_in_forgot");
    const pleaseWaitString = useString("please_wait");

    if (isResettingPassword && !passwordResetSuccess) {
      return (
        <form className="sign-in-container vfill centered reset" onSubmit={_resetPassword}>
          <p>{signInResetString}</p>
          <input type="email" autoComplete='username' value={signInEmailValue} placeholder={emailHintString} onChange={_updateSignInEmail} />
          <p className="text-danger">{passwordResetError}</p>
          <button className="sign-in-button primary-button rounded reset" disabled={!isValidSignInEmail} type="submit">{signInResetString}</button>
          <button className="sign-in-button secondary-button rounded cancel" onClick={_cancelPasswordReset}>{cancelString}</button>
        </form>
      )
    }

    if (isResettingPassword && passwordResetSuccess) {
      return (
        <form className="sign-in-container centered reset">
          <p>{signInResetInstructionsString}</p>
          <button className="sign-in-button primary-button rounded" disabled={!canProceed} onClick={_cancelPasswordReset}>
            {okString}
          </button>
        </form>
      )
    }

    return (
      <form className="sign-in-container centered" onSubmit={_preventDefault}>
      <p>{signInHaveAccountString}</p>
          <input type="email" autoComplete='username' value={signInEmailValue} placeholder={signInEmailHintString} onChange={_updateSignInEmail} />
          <input type="password" autoComplete='current-password' value={signInPasswordValue} placeholder={signInPasswordHintString} onChange={_updateSignInPassword} />
          <button className="sign-in-button primary-button rounded" disabled={!canProceed} onClick={_signIn}>
            {signInString}
        </button>
        {isLoading && <p className="text-muted">{pleaseWaitString}</p>}
        {signInError && <p className="text-danger">{signInError}</p>}
        <button onClick={() => {setIsResettingPassword(true)}} className="link-button">{signInForgotString}</button>
        <FirebaseSignInUI/>
      </form>
    );
  }

  const _validateEmail = (event) => {
    const newEmail = event.target.value;
    setSignupEmailValue(newEmail);
    setValidEmail(newEmail.length > 0);
  }

  const _validatePassword = (event) => {
    const newPassword = event.target.value;
    setSignUpPasswordValue(newPassword);
    const passwordsMatch = newPassword === signUpPasswordRepeat;
    setPasswordsMatch(passwordsMatch);
    if(showPasswordWarning && passwordsMatch){
      setShowPasswordWarning(false);
    }
  }

  const _validatePasswordRepeat = (event) => {
    const newPasswordRepeat = event.target.value;
    setSignUpPasswordRepeat(newPasswordRepeat);
    const passwordsMatch = signUpPasswordValue === newPasswordRepeat;
    setPasswordsMatch(passwordsMatch);
    if(showPasswordWarning && passwordsMatch){
      setShowPasswordWarning(false);
    }
  }

  const _updateSignInEmail = (event) => {
    setSignInEmailValue(event.target.value);
  }

  const _updateSignInPassword = (event) => {
    setSignInPasswordValue(event.target.value);
  }

  const _signUp = () => {
    authService.createAccount(signupEmailValue, signUpPasswordValue, signUpPasswordRepeat, _onFailedSignUp);
  }

  const _onFailedSignUp = (error) => {
    setSignUpError(error);
    setShowSignUpError(true);
    if(error === ALREADY_REGISTERED_ERROR){
      setSignInEmailValue(signupEmailValue);
      setIsResettingPassword(true);
    }
  }

  const _signIn = async () => {
    setSignInError(null);
    setIsLoading(true);
    await authService.signInWithEmailAndPassword(signInEmailValue, signInPasswordValue, _onFailedSignIn);
  }

  const _cancelPasswordReset = () => {
    setPasswordResetSuccess(false);
    setIsResettingPassword(false);
  }

  const _resetPassword = async (e) => {
    e.preventDefault();
    setPasswordResetSuccess(false);
    setPasswordResetError(false);
    try {
      await authService.resetPassword(signInEmailValue);
      setPasswordResetSuccess(true);
    } catch (error) {
      setPasswordResetError(error.message);
    }
  }

  const _onFailedSignIn = (error) => {
    const errorMessage = `${error.code}: ${error.message}`;
    setSignInError(error.message);
    setIsLoading(false);
    console.error(`Sign in error: ${errorMessage}`);
  }

  const _handleConsentChange = (event) => {
      setAcceptTerms(event.target.checked);
  }

  return (<Modal
    className="modal signin-modal"
    overlayClassName="modal-overlay signin-modal-overlay"
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Create Profile">
      <div className='signin-modal-content vfill'>
        {_renderSignup()}
        {_renderDivider()}
        {_renderSignIn()}
      </div>
  </Modal>);
}
import React, { useEffect, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { isAddressValid } from "helpers/is-address-valid";
import { useString } from "providers/LocalisationProvider";
import { TextField } from "./TextField";
import { EditableForm } from "./EditableForm";

export const AddressForm = ({
  onChange,
  defaultAddress,
}) => {
  const [address, setAddress] = useState(defaultAddress);
  const [isValid, setIsValid] = useState(isAddressValid(defaultAddress));
  const title = useString("address_form_title");
  const enterAddressString = useString("address_form_title_request");

  const setAddressValue = (key, value) => {
    const _address = { ...address, [key]: value };
    setAddress(_address);
    setIsValid(isAddressValid(_address));
  };

  const onCancel = () => {
    setAddress(defaultAddress);
  };

  const onSave = () => {
    onChange && onChange(address);
  };

  useEffect(() => {
    setAddress(defaultAddress);
  }, [defaultAddress]);

  const style = isValid ? '' : 'text-danger';

  return (
    <EditableForm
      title={title}
      titleCollapsed={isValid ? title : enterAddressString}
      onSave={onSave}
      onCancel={onCancel}
      isValid={isValid}
      whileViewing={<WhileViewing address={address} />}
      style={style}
      whileEditing={
        <WhileEditing address={address} setAddressValue={setAddressValue} />
      }
    />
  );
};

const WhileViewing = ({ address }) => (
  <>
    <div>{address.line1}</div>
    <div>{address.line2}</div>
    <div>{address.city}</div>
    <div>{address.country}</div>
    <div>{address.postal_code}</div>
  </>
);

const WhileEditing = ({ address, setAddressValue }) => (
  <>
    <TextField
      name="line1"
      title={useString("address_form_line1")}
      defaultValue={address.line1}
      onChange={(v) => setAddressValue("line1", v)}
    />
    <TextField
      name="line2"
      title={useString("address_form_line2")}
      defaultValue={address.line2}
      onChange={(v) => setAddressValue("line2", v)}
    />
    <TextField
      name="city"
      title={useString("address_form_city")}
      defaultValue={address.city}
      onChange={(v) => setAddressValue("city", v)}
    />
    <TextField name="country" title={useString("address_form_country")}>
      <CountryDropdown
        valueType="short"
        value={address.country}
        onChange={(v) => setAddressValue("country", v)}
      />
    </TextField>
    <TextField
      name="postal_code"
      title={useString("address_form_postal_code")}
      defaultValue={address.postal_code}
      onChange={(v) => setAddressValue("postal_code", v)}
    />
  </>
);

import React from "react";
import { isAccountValid } from "helpers/is-account-valid";
import {
  useString,
  useStringOrNull,
} from "providers/LocalisationProvider";

export const Header = ({ user }) => {
  const { valid: isUserValid, error } = isAccountValid(user);
  const text = useString(
    isUserValid ? "settings_header_regular" : "settings_header_incomplete"
  );
  const context = isUserValid ? "regular" : "danger";

  return (
    <header className="header-pill-container">
      <div className={`header-pill ${context}`} title={useStringOrNull(error)}>
        {text}
      </div>
    </header>
  );
};

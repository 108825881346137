import React from "react";
import NavHeader from './NavHeader'
import InDevNotification from './InDevNotification'

export const NavSurround = ({children, authService, telemetryService, currentPage, toggleAuthAction}) => {
    return (
    <div className="surround full-width flexbox">
        <InDevNotification />
        <NavHeader  authService={authService}
                    toggleAuthAction={toggleAuthAction} 
                    telemetryService={telemetryService}
                    currentPage={currentPage}/>
        {children}
        </div>
    )
}